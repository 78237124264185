import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit  {
  // props that select takes
  @Input() labelName: string;
  @Input() inputWidth = 8;
  @Input() id: string;
  @Input() name: string;
  @Input() style: any;
  @Input() defaultValue: string;
  @Input() disabled = false;
  @Input() options: [{value, title}];
  option = {value: ''};
  optionValue;
  constructor() {}

  ngOnInit() {
    this.option.value = this.defaultValue;
  }
}
