import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { StatsService } from './stats.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MockStats } from './mock';

@Component({
    selector: 'app-admin-stats',
    templateUrl: './admin-stats.component.html',
    styleUrls: ['./admin-stats.component.css'],
    encapsulation: ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminStatsComponent implements OnInit, AfterViewInit {
    stats: Array<object>;
    buttonsData: Array<object>;
    durationData = {
      monthly: [],
      daily: []
    };
    duration = 'daily';
    loadingComplete: boolean;
    currentDuration: Array<object>;
    table = '<table class="table table-borderless table-responsive mb-0">';

    constructor(private elRef: ElementRef, private statsService: StatsService, private sanitizer: DomSanitizer, private router: Router) {
    }

    ngOnInit() {
      this.getStats();
    }

    ngAfterViewInit(): void {
      /*
      Adding click event: seems not to work on innerHML
      If necessary this is how to add it
       */
      // const buttons = this.elRef.nativeElement.querySelectorAll('button');
      // buttons.forEach((button: HTMLButtonElement) => {
      //   button.addEventListener('click', (event) => this.handleClick(event));
      // });
    }

  goTo( url, params = {}) {
      this.router.navigate([url, {...params}]);
    }

    getStats() {
      // const res = MockStats;
      //
      // this.stats = res.stat;
      // this.populateStats(this.stats);
      // this.durationData.daily = res.daily;
      // this.durationData.monthly = res.monthly;
      // this.loadingComplete = true;
        this.statsService.getStats({}).subscribe((res: any) => {
          this.stats = res.stat;
          this.buttonsData = res.buttons;
          this.populateStats(this.stats);
          this.durationData.daily = res.daily;
          this.durationData.monthly = res.monthly;
          this.loadingComplete = true;
        });
    }

    handleClick(event: any) {
      console.log('click event', event);
    }

    populateStats(stats) {
      for (const stat of stats) {
        // TABLE SECTION
        if (stat.type === 'TABLE-SECTION') {
          const tableSection = `
          <thead  style="background-color: black" class="text-left headers-text table-section"> <!-- remove bg-color -->
            <h6 class="font-weight-bold smart-text" style="color: ${stat.color}">{{stat.text}}</h6>
          </thead>`;
          this.table += tableSection;
        }
        // BIG HEAD
        if (stat.type === 'BIG-HEAD') {
          const bigHead = `<thead class="text-left headers-text smart-text big-head">
          <tr>
            <th style="color: ${stat.color}"><h3>${stat.text}</h3></th>
          </tr>
          </thead>`;
          this.table += bigHead;
        }

        // TABLE HEAD
        if (stat.type === 'HEAD') {
          this.table += `
              <thead style="background-color: black" class="text-left headers-text smart-text head">
                <tr>
                    <th style="color: ${stat.color}"><h4>${stat.text}</h4></th>
                </tr>
              </thead>
             `;
        }

        // TABLE HEAD
        if (stat.type === 'TABLE-HEAD') {
          let tHead = '<thead>';
          let tRow = '<tr>';
          for (const [index, tableRow] of stat.text.entries()) {
            const tData = `
                        <th
                        scope="col"
                        style="color: ${stat.color}; textOverflow: 'unset'"
                        class="smart-text text-${stat.align[index]}">${tableRow}</th>
                     `;
            tRow += tData;
          }
          tHead += tRow;
          this.table += tHead;
        }

        // BUTTONS
        if (stat.type === 'BUTTONS') {
          let div = '<thead class="text-left col d-flex px-1">';
          for (const  [index, button] of stat.text.entries()) {
            div += `
                <tr>
                  <td>
                  <a href="/graph;type=${button.toLowerCase()}">
                     <button
                        id="btn-${index}"
                        class="stats-button btn-${index} mr-3"
                        style="background: ${stat.color}">${button}</button>
                  </a>
                  </td>
                </tr>
          `;
          }
          this.table += div;
        }

        // TABLE LINE
        if (stat.type === 'TABLE-LINE') {
          let tBody = '<tbody>';
          let tRow = '<tr>';
          for (const [index, tableRow] of stat.text.entries()) {
            const tData = `
                        <td
                        style="color: stat.color, textOverflow: 'unset'"
                        class="smart-text text-${stat.align[index]}">${tableRow}</td>
                     `;
            tRow += tData;
          }
          tBody += tRow;
          this.table += tBody;
        }

        // TABLE SPACE
        if (stat.type === 'SPACER') {
          this.table += `
        <thead class="spacer">
        <tr>
        <td>
            <br>
        </td>
        </tr>
        </thead>
        `;
        }
      }
    }

    sanitizeHtml(dom) {
      return this.sanitizer.bypassSecurityTrustHtml(dom);
    }

}
