import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CreateAdvancedBarComponent } from './create-advanced-bar/create-advanced-bar.component';
import { BarComponent } from './bar/bar.component';
import { BarAddComponent } from './baradd/bar-add.component';
import { BarNearMeComponent } from './bar-near-me/bar-near-me.component';
import { ToplistComponent } from './toplist/toplist.component';

import { RatingComponent } from './bar/rating/rating.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SupportComponent } from './support/support.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './auth/auth.guard';
import { ListAdminComponent } from './list-admin/list-admin.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ListBarsComponent } from './list-bars/list-bars.component';
import { SimpleMapComponent } from './bar/components/simple-map/simple-map.component';
import { ConfirmBarAdditionComponent } from './create-advanced-bar/confirm-bar-addition/confirm-bar-addition.component';
import { ContactComponent } from './contact/contact.component';
import { FeedbackComponent } from './feeback/feedback.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { LogoutComponent } from './admin/logout/logout.component';
import { CreateOrderComponent } from './ad-system/create-order/create-order.component';
import { ListOrdersComponent } from './ad-system/list-orders/list-orders.component';
import { EditAdComponent } from './ad-system/edit-ad/edit-ad.component';
import { AdminStatsComponent } from './admin-stats/admin-stats.component';
import { OrderStatusComponent } from './ad-system/order-status/order-status.component';
import { GraphComponent } from './admin-stats/graph/graph.component';
import { SignUpComponent } from './rank-system/sign-up/sign-up.component';
import { UserRankComponent } from './rank-system/user-rank/user-rank.component';
import { AboutComponent } from './rank-system/about/about.component';
import { ListComponent } from './rank-system/list/list.component';
import { urlMatcher } from './utils/urlMatcher';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'createAdvancedBar', component: CreateAdvancedBarComponent, canActivate: [AuthGuard] },
  { path: 'bar/:barName', component: BarComponent },
  { path: 'map/:bar', component: SimpleMapComponent },
  { path: 'confirm-bar/:barName', component: ConfirmBarAdditionComponent },
  { path: 'rate-bar/:barName', component: RatingComponent },
  { path: 'home',   redirectTo: '', pathMatch: 'full' },
  { path: 'addbar', component: BarAddComponent},
  { path: 'top10/:toplist', component: ToplistComponent},
  { path: 'near', component: BarNearMeComponent },
  { path: 'search-results', component: SearchResultsComponent },
  { path: 'search', component: SearchBarComponent },
  { path: 'support', component: SupportComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'createAdmin', component: AddAdminComponent, canActivate: [AuthGuard] },
  { path: 'listAdmins', component: ListAdminComponent, canActivate: [AuthGuard] },
  { path: 'listbar', component: ListBarsComponent, canActivate: [AuthGuard] },
  { path: 'admin/login', component: AdminComponent},
  { path: 'logout', component: LogoutComponent},
  { path: 'dashboard', component: AdminDashboardComponent, canActivate: [AuthGuard] },
  { path: 'contact', component: ContactComponent },
  { path: 'listFeedback', component: FeedbackComponent },
  { path: 'termsAndConditions', component: TermsAndConditionsComponent},
  { path: 'createOrder', component: CreateOrderComponent, canActivate: [AuthGuard]},
  { path: 'listOrder', component: ListOrdersComponent, canActivate: [AuthGuard]},
  { path: 'editAd', component: EditAdComponent, canActivate: [AuthGuard]},
  { path: 'stats', component: AdminStatsComponent},
  { path: 'order-status', component: OrderStatusComponent},
  { path: 'graph', component: GraphComponent},
  { path: 'user/signup', component: SignUpComponent},
  { path: 'rank', component: UserRankComponent},
  { path: 'settings', component: SignUpComponent},
  { path: 'rank/about', component: AboutComponent},
  { path: 'rank/list', component: ListComponent},
  { matcher: urlMatcher, component: ToplistComponent},
  { path: ':barName', component: BarComponent},
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
