import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Bar } from '../../../models/Bar';
import { BarService } from '../../bar.service';
import { ModalPopupComponent } from '../../../common/modal-popup/modal-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import {playPointsAudio, userRankUp} from '../../../utils/rankUtils';
import { Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  styleUrls: ['./comment-section.component.css']
})
export class CommentSectionComponent implements OnInit {

  @Input() commentWithNumOfICons: Array<object>;
  @Input() bar: Bar;
  @Input() user;
  @Input() comments: Array<object>;
  @Input() goToRatingPage;
  @Input() goBack;
  @Input() newBar;
  subscription: Subscription;

  constructor(private barService: BarService, private modalService: NgbModal, private router: Router,
              private toastr: ToastrService) {
    this.subscription = this.barService.getComments().subscribe(comments => {
      this.setCommentItems(comments);
    });
  }

  ngOnInit() {
    this.setCommentItems(this.comments);
  }

  setCommentItems = (comments) => {
    this.commentWithNumOfICons = comments.map((comment) => {
      // calculate the number of icons to be displayed
      const items = Array(Math.floor(comment.totalRating)).fill(1);

      // calculate remaining(empty) icons of the total 5(five)
      const remainingIcons = Array(Math.floor(5 - comment.totalRating)).fill(1);
      // format date to desired string
      const date = comment._id && new Date(comment._id * 1000).toDateString();
      return {
        ...comment,
        userId: this.user,
        dateString: date,
        numOfCommentIcons: items,
        numOfRemainingIcons: remainingIcons,
      };
    });
  }

  // Vote a comment up or down
  reactToComment({id = '', positive = false, negative = false } ) {
    // if thumbs up
    this.barService.reactToComment(id, positive, negative).subscribe((res: any) => {
      const { user } = res;
      if (userRankUp(user)) {
        this.router.navigate(['/rank']);
      }
      if (res.pointsTxt) {
        playPointsAudio();
        this.toastr.success(`${res.pointsTxt}`);
      }
      if (res.comments && res.comments.length) {
        this.setCommentItems(res.comments);
      }
    }, ({error}) => {
      {
        const modalRef = this.modalService.open(ModalPopupComponent);
        modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <h2 class="text-center mb-2 font-weight-bold">Sorry...</h2>
            <h5>${error.errMsg}</h5>
        </div>`;
        modalRef.componentInstance.my_modal_button1 = 'OK';
        modalRef.result.then(() => {}, () => {});
      }
    });
  }

}
