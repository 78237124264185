const changeAllWordsCase = (sentence, type= 'lower') => {
  if (typeof sentence !== 'string') { return sentence; }
  return sentence.split(' ')
    .map(word => type === 'lower' ? word.charAt(0).toLowerCase() + word.slice(1) :
      word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const withHyphen = (str) => {
  return str.replace(/\s+/g, '-');
};

export default changeAllWordsCase;
