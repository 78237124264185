import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardService {
  // default params
  url = `${environment.server_url}admin/dashboard`;
  barSaveUrl = `${environment.server_url}admin/save`;
  constructor(private http: HttpClient) { }
  getDashboard = (deviceID, userID) => {
    return this.http.post(this.url, {deviceID, userID}, httpOptions);
  }
  logout = (deviceID, userID) => {
    return this.http.post(this.url, {
      deviceID,
      userID,
      method: 'LOGOUT'
    }, httpOptions);
  }
  logoutAdmin = (deviceID, userID) => {
    return this.http.post(this.barSaveUrl, {
      deviceID,
      userID,
      method: 'LOGOUT'
    }, httpOptions);
  }
}
