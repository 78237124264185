import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class CreateAdvacedBarService {

  searchGetURL = `${environment.server_url}search/get`;
  url = `${environment.server_url}bar/barEdit`;

  deviceID = localStorage.getItem('deviceID');

  constructor(private http: HttpClient) { }

  getOptions = () => {
    const body = { deviceID: this.deviceID };
    return this.http.post(this.searchGetURL, body, httpOptions);
  }

  resetStats = (data) => {
    const body = {...data};
    return this.http.post(this.url, body, httpOptions);
  }
}
