import { Component, OnInit, ViewChild } from '@angular/core';
import BarFormUtils from '../../utils/BarFormUtils';
import AdminUtils from '../../utils/adminUtils';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RankService } from '../rank.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { LeftService } from '../../common/left/left.service';
import { GlobalService } from '../../common/service/global.service';
import FetchGlobalConstants from '../../utils/global';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../app.service';
@Component({
  selector: 'app-login',
  templateUrl: './signup.component.html',
  styleUrls: ['./sign-up.component.css'],
  providers: [
    NgbActiveModal
  ]
})
export class SignUpComponent implements OnInit {
  @ViewChild('email', { static: false }) email;
  @ViewChild('password', { static: false }) password;
  @ViewChild('nickname', { static: false }) nickname;
  @ViewChild('language', { static: false }) language;
  censorship = false;
  isValueNull: boolean;
  isLoading = true;
  id: string;
  languages: Array<object>;
  user: object;
  editMode = false;
  BarFormUtils = new BarFormUtils(this.modalService);
  AdminUtils = new AdminUtils(this.modalService);
  subscription: Subscription;
  globalUtil = new FetchGlobalConstants(this.globalService, this.appService,
    this.leftSideService, this.rankService, this.translate, this.modalService
  );
  defaultLanguage: string;
  constructor(private modalService: NgbModal, private rankService: RankService,
              private route: ActivatedRoute, private location: Location, private router: Router,
              private leftSideService: LeftService, private globalService: GlobalService,
              private translate: TranslateService, private appService: AppService) {
  }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.getUserData();
    this.route.params.forEach(params => {
      this.defaultLanguage = localStorage.getItem('locale') || 'gb';
      // close login modal if was open
      this.dismissModal();
    });
  }

  dismissModal() {
    this.modalService.dismissAll('No modal should be open on this page initialization');
  }

  onEnter(event) {
    if (event.keyCode == 13) {
      if (this.email && this.email.value && this.password && this.password.value && this.nickname.value) {
        this.signUp();
      }
    }
  }

  getUserData() {
    const deviceID = localStorage.getItem('deviceID');
    this.rankService.getUserData({deviceID, status: 'edit'}).subscribe((res: any) => {
     // @ts-ignore
     const {language} = this.globalService.GetGlobalConstants();
     if (!language) {
       setTimeout(() => {
         // @ts-ignore
         const {language: availableLangs} = this.globalService.GetGlobalConstants();
         this.populateLanguages(availableLangs);
       }, 1000);
     } else {
       this.populateLanguages(language);
     }
     if (res.user) {
         this.user = res.user;
         setTimeout(() => {
           this.populateUserDetails(res.user);
         }, 300);
       }
   });
  }

  populateUserDetails(user) {
    if (user.nickName) {
      this.editMode = true;
      this.email.value = user.email;
      this.password.value = user.password;
      this.nickname.value = user.nickName;
      this.language.option.value = user.language;
      this.censorship = user.censorFlag;
      this.id = user._id;
    }
  }

  populateLanguages(languages) {
    this.languages = languages.map((item: any) => {
      return { title: item.name, value: item._id };
    });
  }

  signUp(censorship = false) {
    // validate
    const data = {
      email: this.email.value,
      password: this.password.value,
      nickName: this.nickname.value,
      language: this.language.option.value,
      censorFlag: this.censorship,
      deviceID: localStorage.getItem('deviceID'),
      _id: this.id || null
    };
    console.log(data)
    if (!this.editMode) { // if there is an id in params edit user
    delete data._id;
    }
    const isValid = !censorship && this.validateData(data);
    if (censorship || isValid) {
      this.rankService.registerUser(data).subscribe((res: any) => {
        // update user language updated language in localstorage
        if (res.language) {
          localStorage.setItem('locale', res.language);
        }
        console.log(res);
        if (!censorship) {
          this.getLeftSide(res);
          this.goTo('/rank');
        } else {
          this.censorship = res.censorFlag;
        }
      }, ({error}) => {
        this.BarFormUtils.showModal(error.errMsg, '');
      });
    }
  }

  validateData(data) {
    if (!data.nickName || data.nickName.length < 2 || data.nickName.length > 12) {
      this.BarFormUtils.showModal('Nick Name must be 2 - 12 characters', 'Validation Error');
      return false;
    }
    if (!data.password  || data.password.length < 2 || data.password.length > 20) {
      this.BarFormUtils.showModal('Password must be 2 - 20 characters', 'Validation Error');
      return false;
    }
    if (data.email) {
      const isValid = this.AdminUtils.isEmail(data.email);
      if (!isValid) {
        this.BarFormUtils.showModal('Invalid email', 'Validation Error');
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  updateCensorship() {
    this.censorship = !this.censorship;
    this.signUp(true);
  }

  goBack() {
    this.router.navigate(['home']);
  }

  goTo(url, params= {}) {
    this.router.navigate([url, {...params}]);
  }

 // update the left side
  getLeftSide(user) {
    this.globalUtil.fetchGlobalConstants().then((data: any) => {
      const {menuLeft} = data;
      this.leftSideService.storeUser(user);
      this.leftSideService.sendLeftSide({content: menuLeft});
    });
  }


}
