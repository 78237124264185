import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RankService } from '../rank.service';
import { Rank } from '../../models/Rank';
import { User } from '../../models/user';
import {GlobalService} from '../../common/service/global.service';
import {LeftService} from '../../common/left/left.service';
import { pad } from 'src/app/utils/global';

@Component({
  selector: 'app-user-rank',
  templateUrl: './user-rank.component.html',
  styleUrls: ['./user-rank.component.css'],
})
export class UserRankComponent implements OnInit {
  pad = pad;
  rank: Rank;
  user: User;
  pct = -5; // initial pct
  isLoading = true;
  zoomOut: boolean;
  hidePinkBox: boolean;
  zoomOutAnimationDone: boolean;
  isLevelUpEvent: boolean;
  zoomInAnimation: boolean;
  zoomInAnimationDone: boolean;
  showDingText: boolean;
  showNewAvatar: boolean;
  levelUp: boolean;
  constructor(private router: Router, private rankService: RankService, private globalService: GlobalService,
              private leftSideService: LeftService) {}

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.getUserData();
    this.getRankPage();
  }

  goTo(url) {
    this.router.navigate([url]);
  }

  goHome() {
    this.router.navigate(['home']);
  }

  getRankPage() {
    const deviceID = localStorage.getItem('deviceID');
    this.rankService.getRankPage({deviceID}).subscribe((res: Rank) => {
      this.isLoading = false;
      this.rank = res;
      // this is a new user
      this.levelUp = res.levelUp;
      if (res.levelUp) {
        // animate from top to bottom
        this.pct = 100;
      }
      this.updatePct(res).then((levelUp: any) => {
        setTimeout(() => {
          this.zoomOutAnimationDone = true;
          this.showDingText = levelUp;
          if (this.showDingText) {
            this.playAudio();
          }
          setTimeout(() => {
            // this.zoomOutAnimationDone = false;
            // this.zoomInAnimation = true;
            setTimeout(() => {
              this.zoomInAnimation = false;
              this.zoomInAnimationDone = true;
              this.updateLeftMenu(res);
            }, 2000);
          }, 2000);
        }, 2000);
      });
    });
  }

  updatePct(resp) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (resp.levelUp) {
          this.isLevelUpEvent = true;
          this.zoomOut = true; // zoom out avatar + arrow + title + nickname
          this.hidePinkBox = true;
        }
        if (resp.pct) {
          this.pct = resp.pct;
        } else {
          this.pct = 0;
        }
        resolve(resp.levelUp);
      }, 1000);
    });
  }

  updateLeftMenu(data) {
    const { user, menuLeft } = this.globalService.GetGlobalConstants();
    user.rank = data.Rank.level;
    user.rankName.title = data.Rank.title;
    this.leftSideService.storeUser(user);
    this.leftSideService.sendLeftSide({content: menuLeft});
  }

  playAudio() {
    const audio = new Audio();
    audio.src = '../../../assets/images/avatar/levelup.mp3';
    audio.load();
    audio.play();
    setTimeout(() => {
      this.showDingText = false;
      this.showNewAvatar = true;
    }, 2000);
  }

  getUserData() {
    const deviceID = localStorage.getItem('deviceID');
    this.rankService.getUserData({deviceID}).subscribe((data: any) => {
      this.user = data.user;
    });
  }

  abs(num) {
    return Math.abs(num);
  }
}
