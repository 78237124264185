import { Component, OnInit } from '@angular/core';
import { ListOrdersService } from '../list-orders/list-orders.service';
import { ActivatedRoute, Router } from '@angular/router';
import { dateDiff, formatRuntime } from '../../utils/adsUtils';
import { CreateOrderService } from '../create-order/create-order.service';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.css', '../create-order/create-order.component.css'],
})
export class OrderStatusComponent implements OnInit {
  order: any;
  ads: Array<object>;
  id: string;
  isLoading = true;

  constructor(private activatedRoute: ActivatedRoute,
              private orderService: ListOrdersService,
              private createOrderService: CreateOrderService, private router: Router) {
    this.activatedRoute.params.forEach(params => {
      this.id = params.id;
      this.getInfo();
    });
  }

  ngOnInit() {}

  getInfo() {
    return new Promise(resolve => {
      this.orderService.getOrder({_id: Number(this.id)}).subscribe((res: any) => {
        this.order = res.order;
        resolve(true)
      });
    }).then(() => {
      this.createOrderService.getAds({order: this.id}).subscribe(({ad}: any) => {
        this.ads = ad;
        this.isLoading = false;
      });
    });
  }

  today() {
    const date = new Date().getTime()
    return new Date(date).toDateString().slice(4);
  }

  goTo(link, params = {}) {
    this.router.navigate([link, {...params}])
  }

  time(dateFrom , to) {
    const runtime = dateDiff(new Date(dateFrom * 1000), new Date(to * 1000));
    return formatRuntime(runtime);
  }
}
