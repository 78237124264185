import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class ToplistServiceService {
  toplistUrl = environment.server_url + 'list/topList/';

  constructor(private http: HttpClient) { }
  getTopList(list) {
    const body = {
      list,
      deviceID : localStorage.getItem('deviceID'),
    };
    return this.http.post(this.toplistUrl, body, httpOptions);
  }
}
