import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-price-modal',
  templateUrl: './price-modal.component.html',
  styleUrls: ['./price-modal.component.css']
})
export class PriceModalComponent implements OnInit {
  @Input() price;
  @Input() size;

  constructor() { }

  ngOnInit() {
  }

}
