import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { httpOptions } from '../utils/config/api';
import {UUID} from 'angular2-uuid';

@Injectable({
  providedIn: 'root'
})
export class RankService {
  serverUrl = environment.server_url;
  deviceID = localStorage.getItem('deviceID');

  constructor(private http: HttpClient) {}
  registerUser(params = {}) {
    return this.request('user/regUser', params);
  }

  getUserData(params = {}) {
    return this.request('user/getUserData', params);
  }

  loginUser(params = {}) {
    return this.request('user/loginUser', params);
  }

  aboutRank(params = {}) {
    return this.request('user/rankWhatIs', params);
  }

  rankList(params = {}) {
    return this.request('user/rankList', params);
  }

  getRankPage(params = {}) {
    return this.request('user/rankPage', params);
  }

  // sends request to the specified endpoint
  request(url, params) {
    if (!params.deviceID) {
      // if no device id present generate one
      if (!this.deviceID) {
        this.deviceID = UUID.UUID();
        localStorage.setItem('deviceID', this.deviceID);
      }
      params.deviceID = this.deviceID;
    }
    return this.http.post(this.serverUrl + url, {...params }, httpOptions);
  }
}
