export const ordinal = (rank) => {
  if (rank > 3 && rank < 21) { return 'th'; }
  switch (rank % 10) {
    case 1:  return 'st';
    case 2:  return 'nd';
    case 3:  return 'rd';
    default: return 'th';
  }
};

export const userRankUp = (user) => {
  return user.rankUp;
};

/**
 * Play points sound when user gains nightlife points
 */

export const playPointsAudio = () => {
  const audio = new Audio();
  audio.src = '../../assets/media/nightPoints.mp3';
  audio.load();
  audio.play();
};
