import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-btn',
  templateUrl: './custom-btn.component.html',
  styleUrls: ['./custom-btn.component.css']
})
export class CustomBtnComponent implements OnInit {
  // props that button input takes
  @Input() labelColWidth = 4;
  @Input() btnColWidth = 8;
  @Input() showLabel = true;
  @Input() clickHandler: any;
  @Input() link = '';
  @Input() created: string;
  @Input() lastLogin: string;
  @Input() disabled = false;
  @Input() name: string;
  @Input() isEnabled: boolean;
  @Input() handlerParams: any;
  @Input() style: any;
  @Input() containerStyle: any;
  @Input() btnWrapperStyle: any;
  @Input() btnStyleClass = false;
  @Input() btnSearchClass = false;
  @Input() btnAddClass = false;
  constructor(private router: Router) { }
  ngOnInit() {}
  // when the button is clicked, saving action starts
  handleClick = () => {
    if (this.link) {
      this.clickHandler(this.link);
    } else {
      this.clickHandler({save: true});
    }
  }

}
