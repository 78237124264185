import { Component, AfterViewInit, OnInit, ViewChild, ElementRef, SimpleChanges, Input, OnChanges, } from '@angular/core';
import {BarsNearMeService} from '../bars-near-me.service';
import getDistanceFromLatLonInKm from '../../utils/calculateDistance';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import { AppService } from '../../app.service';
import MapUtils from '../../utils/mapUtils';
import { LocationService } from '../../common/service/location.service';
import { GlobalService } from '../../common/service/global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bar-near-me-map',
  templateUrl: './bar-near-me-map.component.html',
  styleUrls: ['./bar-near-me-map.component.css'],
})
export class BarNearMeMapComponent implements AfterViewInit, OnChanges, OnInit {
  // Constructor will get called very first when the page loads
  @Input() distance: any;
  @Input() area: string;
  @Input() type: string;
  prevSearchParams: {
    area: 'ALL',
    distance: 'ALL',
    type: 'ALL',
  };
  clickedBar = {
    priceColored: undefined,
    openHours: undefined, imgUrl: undefined, girls: undefined, distance: undefined,
    _id() {}
  };
  mapUtils = new MapUtils(new LocationService(this.modalService, this.globalService, this.locationService));
  openBarDetails = false;
  serverURL: string = environment.server_url;
  constructor(private barService: BarsNearMeService, private appService: AppService, private router: Router,
              private locationService: LocationService, private modalService: NgbModal,
              private globalService: GlobalService) {

  }
  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;
  // @ts-ignore
  map: google.maps.Map;
  // initial center position for the map
  latitude = this.globalService.getUserCurrentLocation() ? this.globalService.getUserCurrentLocation().coords.latitude : 12.931906;
  longitude = this.globalService.getUserCurrentLocation() ? this.globalService.getUserCurrentLocation().coords.longitude : 100.879656;
  prevBarsMarkers = [];
// initializes default values
  coordinates = new google.maps.LatLng(this.latitude, this.longitude);
  // initializes marker instance
  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
    draggable: false,
    visible: false,
  });

  // current location marker
  currentLocationMarker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
    draggable: false,
    visible: true,
    icon: '../../../assets/images/VpVF8.png'
  });
  // @ts-ignore set map options
  mapOptions: google.maps.MapOptions = {
    center: new google.maps.LatLng(this.latitude, this.longitude),
    zoom: 16,
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  };
  // lifecycle hook that is called first
  ngOnInit() {
    // const locationCoords: any = this.globalService.getUserCurrentLocation();
    this.locationService.getLocation().subscribe(locationCoords => {
      this.longitude = locationCoords.coords.longitude;
      this.latitude = locationCoords.coords.latitude;
    })
    // old code
    // this.appService.GetGlobalConstants(localStorage.getItem('deviceID')).subscribe((data: any) => {
    //   this.mapUtils.populateMap(this.mapUtils.refactorCoords(data.area), this.map);
    // });
    this.getBars({
      mylocation: {latitude: this.latitude, longitude: this.longitude},
      map: true
    });
    // old code
    // this.barService.fetchBarArea().subscribe((res: any) => {
    //   this.mapUtils.populateMap(this.mapUtils.refactorCoords(res.area), this.map);
    // });

  }
  // set prev markers to an empty array
  resetPrevMarkers = () => this.prevBarsMarkers = [];
  // updates previous markers array
  setPrevMarkers = (prevMarkersArray) => this.prevBarsMarkers = prevMarkersArray;
  getBars = (searchParams: {}) => {
    this.appService.storeSearchParams(searchParams);
    // @ts-ignore
    return this.barService.getBar(searchParams).subscribe(bars => {
      this.mapUtils.removeBarMarkers(this.prevBarsMarkers, this.resetPrevMarkers);
      this.mapUtils.addBarMarkers(bars, this.serverURL, this.latitude, this.latitude, this.map,
        this.showDetails, this.setPrevMarkers, true);
      this.appService.setBars(bars);
    });
  }
  // shows bar details
  showDetails = (bar, distance, imgUrl, priceColored) => {
    this.clickedBar = {...this.clickedBar, ...bar, distance, imgUrl, priceColored};
    this.openBarDetails = true;
  }
// initializes the map on the DOM
  mapInitializer() {
    const data: any = this.globalService.GetGlobalConstants();
    const newCoords = new google.maps.LatLng(this.latitude, this.longitude);
    this.marker.setPosition(newCoords);
    this.mapOptions.center = newCoords;
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.mapUtils.populateMap(this.mapUtils.refactorCoords(data.areas), this.map);
    this.marker.setMap(this.map);
    this.currentLocationMarker.setMap(this.map);
    this.map.addListener('drag', () => {
      this.latitude = this.marker.getPosition().lat();
      this.longitude = this.marker.getPosition().lng();
      this.marker.setPosition({lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng()});
    });
    // loads bars that are 500 meters from the user
    this.map.addListener('dragend', () => {
      const latitude = this.marker.getPosition().lat();
      const longitude = this.marker.getPosition().lng();
      const mylocation = {latitude, longitude};
      const area = (this.prevSearchParams ? this.prevSearchParams.area : 'ALL');
      const type = (this.prevSearchParams ? this.prevSearchParams.type : 'ALL');
      this.getBars({
        area,
        type,
        mylocation,
        map: true
      });
    });
    this.map.addListener( 'zoom_changed', () => {
      if (this.map.getZoom() < 16) {
        const latitude = this.marker.getPosition().lat();
        const longitude = this.marker.getPosition().lng();
        const mylocation = {latitude, longitude};
        const area = (this.prevSearchParams ? this.prevSearchParams.area : 'ALL');
        const type = (this.prevSearchParams ? this.prevSearchParams.type : 'ALL');
        this.getBars({
          area,
          type,
          mylocation,
          distance: 1500,
          map: true
        });
      }
    });
  }
  // this lifecycle hook is called after component is fully initialized.
  ngAfterViewInit() {
    this.mapInitializer();
  }
  // close bar details
  closeDetails = () => this.openBarDetails = false;
// updates bars on the map after a search has been made
  ngOnChanges(changes: SimpleChanges) {
    const {distance, type, area} = changes;
    if (distance && distance.previousValue !== distance.currentValue && !distance.firstChange) {
      this.prevSearchParams = {...this.prevSearchParams,  distance: distance.currentValue};
      this.getBars({
        distance: this.mapUtils.parseDistance(distance.currentValue, this.distance),
        area: this.prevSearchParams.area,
        type: this.prevSearchParams.type,
        mylocation: {latitude: this.latitude, longitude: this.longitude},
        map: true
      });
    }
    if (type && type.previousValue !== type.currentValue && !type.firstChange) {
      let typeOfBar;
      if (type.currentValue === 'All types of Bars') {
        typeOfBar = 'ALL';
      } else {
        typeOfBar = type.currentValue;
      }
      this.prevSearchParams = {...this.prevSearchParams,  type: typeOfBar};
      this.getBars({
        distance: this.mapUtils.parseDistance(this.prevSearchParams.distance, this.distance),
        area: this.prevSearchParams.area,
        mylocation: {latitude: this.latitude, longitude: this.longitude},
        type: typeOfBar,
        map: true
      });
    }
    if (area && area.previousValue !== area.currentValue && !area.firstChange) {
      let barArea;
      if (area.currentValue === 'All Area') {
        barArea = 'ALL';
      } else {
        barArea = area.currentValue;
      }
      this.prevSearchParams = {...this.prevSearchParams,  area: barArea};
      this.getBars({
        type: this.prevSearchParams.type,
        distance: this.mapUtils.parseDistance(this.prevSearchParams.distance, this.distance),
        mylocation: {latitude: this.latitude, longitude: this.longitude},
        area: barArea,
        map: true
      });
    }
  }
}
