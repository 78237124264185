import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.css']
})
export class ListTableComponent implements OnInit {
  @Input() isTableHead: boolean;
  @Input() isTableBody: boolean;
  @Input() tableData = [];
  @Input() tableHead = [];
  @Input() editModeFn: any;
  constructor(private router: Router) { }
  ngOnInit() {}
}
