export default [
     {lat:  12.998465339811778,  lng:  100.92488929095613},
     {lat:  12.907792413893349, lng: 101.03209182086336},
     {lat:  12.790039983656579,  lng:  100.95989227294922},
     {lat:  12.80662486560965,  lng:  100.91338797870027},
     {lat:  12.817065668064647,  lng:  100.91199323001253},
     {lat:  12.821867454962808,  lng:  100.9111241942917},
     {lat:  12.826669150272812,  lng:  100.90922519030916},
     {lat:  12.846460791727843,  lng:  100.90205832782136},
     {lat:  12.863280369412095,  lng:  100.89441939654695},
     {lat:  12.886656454338885,  lng:  100.87724253001558},
     {lat:  12.906851216674108,  lng:  100.85903569522249},
     {lat:  12.909737543584658,  lng:  100.85621401133882},
     {lat:  12.9097270859386,    lng:  100.85582240882265},
     {lat:  12.909925781138652,  lng:  100.85564538302766},
     {lat:  12.912868608494424,  lng:  100.8567142433201},
     {lat:  12.913134294589131,  lng:  100.85660293164598},
     {lat:  12.913456516214229,  lng:  100.85668071570741},
     {lat:  12.913925142055312,  lng:  100.85729494157182},
     {lat:  12.914435596818063,  lng:  100.85724397960054},
     {lat:  12.916728382045836,  lng:  100.8559028750931},
     {lat:  12.917431638064388,  lng:  100.85642858806001},
     {lat:  12.9186368417305,  lng:  100.85583850207674},
     {lat:  12.91892233330078,  lng:  100.8559697626804},
     {lat:  12.919056449685224,  lng:  100.85600794224935},
     {lat:  12.919190565997658,  lng:  100.85608903716253},
     {lat:  12.919375140480593,  lng:  100.85609029444801},
     {lat:  12.919603116432478,  lng:  100.8560284360026},
     {lat:  12.919831092176295,  lng:  100.85610605242597},
     {lat:  12.919916874072248,  lng:  100.85631995859491},
     {lat:  12.919576033831625,  lng:  100.85718765321123},
     {lat:  12.920316535621911,  lng:  100.8580647355591},
     {lat:  12.921015206524931,  lng:  100.85872724118578},
     {lat:  12.921755704048186,  lng:  100.85908933940279},
     {lat:  12.92348962302101,  lng:  100.85891499581682},
     {lat:  12.92463793740202,  lng:  100.85869773688661},
     {lat:  12.925094124574782,  lng:  100.85903569522249},
     {lat:  12.924814399587753,  lng:  100.85986181559907},
     {lat:  12.925287579055372,  lng:  100.86051627459871},
     {lat:  12.926636527753042,  lng:  100.86103125872957},
     {lat:  12.927744960762146,  lng:  100.86137458148347},
     {lat:  12.929041611997595,  lng:  100.86154624286043},
     {lat:  12.93020754667103,  lng:  100.86210950675355},
     {lat:  12.929867700803996,  lng:  100.86275860133516},
     {lat:  12.926678355503121,  lng:  100.86474343600618},
     {lat:  12.925569917758033,  lng:  100.8676831370865},
     {lat:  12.925344119252529,  lng:  100.86835468790981},
     {lat:  12.9252019765447,  lng:  100.86936956148708},
     {lat:  12.925837906104926,  lng:  100.87122764726455},
     {lat:  12.927779002257106,  lng:  100.87408551193474},
     {lat:  12.9322048985321,  lng:  100.87967249524246},
     {lat:  12.94140662930143,  lng:  100.8843073524202},
     {lat:  12.949436953211722,  lng:  100.88417860638748},
     {lat:  12.94989701601597,  lng:  100.88295551907669},
     {lat:  12.955961400843847,  lng:  100.88233324658523},
     {lat:  12.9586380478686,  lng:  100.88452192914139},
     {lat:  12.961983816186754,  lng:  100.88482233655105},
     {lat:  12.96859157649501,  lng:  100.88276240002762},
     {lat:  12.97164544286397,  lng:  100.88327638558889},
     {lat:  12.972022797457244,  lng:  100.88705193731222},
     {lat:  12.97265107178487,  lng:  100.88996918215085},
     {lat:  12.974450299651997,  lng:  100.89374473387423},
     {lat:  12.97047933999195,  lng:  100.89975088492841},
     {lat:  12.976545169508295,  lng:  100.90919026352165},
     {lat:  12.98490111955846,  lng:  100.91709467541824},
     {lat:  12.991516054927477,  lng:  100.92150696731804}
]
