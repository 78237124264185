/*
Return status actions
 */
export function positiveStatus(status) {
  if (status === 'WAITING') {
     return { positive: 'PAID', negative: 'STOP' };
  }
  if (status === 'RUNNING') {
    return  {positive: 'PAUSE', negative: 'STOP'};
  }
  if (status === 'PAID') {
    return  {positive: 'RUN', negative: 'STOP'};
  }
  if (status === 'ACTIVATED') {
    return  {positive: 'PAUSE', negative: 'STOP'};
  }
  if (status === 'PAUSED') {
    return  {positive: 'RUN', negative: 'STOP'};
  }
  if (status === 'STOPPED') {
    return  {positive: 'RUN', negative: 'END'};
  }
  if (status === 'ABORTED') {
    return  {positive: 'WAIT', negative: 'END'};
  }
}

/*
Un-editable date: Displayed on fields that are not editable
 */
export function formatDate(date) {
  const newDate = new Date(date * 1000).toDateString();
  return newDate.slice(4, newDate.length)
}

/*
Html readable date
 */
export function formatEditableDate(date) {
  return new Date(date * 1000).toISOString().substr(0, 10)
}

/*
Convert date to standard UNIX format
 */
export function stdUnixDate(date) {
  return Date.parse(date) / 1000
}


/*
Evaluate field visibility depending on the type of ad and the selected slot
 */
export function isFieldVisible(data) {
  const { adForm, type, slot, field } = data;
  const fieldObject = () => adForm.find(item => item.type === type && item.slot === slot);
  if (fieldObject()) {
    const visibleFields = fieldObject().field;
    return visibleFields.includes(field);
  } else {
    return slot;
  }
}

/*
 * Function to calculate the absolute difference in days, months and years between 2 days taking
 * into account variable month lengths and leap years
 * It ignores any time component (ie hours, minutes and seconds)
 *
 */
export function dateDiff(dt1, dt2) {
  /*
   * setup 'empty' return object
   */
  const ret = {days: 0, months: 0, years: 0, hours: 0};

  /*
   * If the dates are equal, return the 'empty' object
   */
  if (dt1 === dt2) return ret;

  /*
   * ensure dt2 > dt1
   */
  if (dt1 > dt2) {
    const dtmp = dt2;
    dt2 = dt1;
    dt1 = dtmp;
  }

  /*
   * First get the number of full years
   */

  const year1 = dt1.getFullYear();
  const year2 = dt2.getFullYear();

  const month1 = dt1.getMonth();
  const month2 = dt2.getMonth();

  const day1 = dt1.getDate();
  const day2 = dt2.getDate();

  const hour1 = dt1.getHours();
  const hour2 = dt2.getHours();

  /*
   * Set initial values bearing in mind the months or days may be negative
   */

  ret.years = year2 - year1;
  ret.months = month2 - month1;
  ret.days = day2 - day1;
  ret.hours = hour2 - hour1;

  /*
   * Now we deal with the negatives
   */

  /*
   * First if the day difference is negative
   * eg dt2 = 13 oct, dt1 = 25 sept
   */
  if (ret.days < 0) {
    /*
     * Use temporary dates to get the number of days remaining in the month
     */
    const dtmp1 = new Date(dt1.getFullYear(), dt1.getMonth() + 1, 1, 0, 0, -1);

    const numDays = dtmp1.getDate();

    ret.months -= 1;
    ret.days += numDays;

  }

  /*
   * Now if the month difference is negative
   */
  if (ret.months < 0) {
    ret.months += 12;
    ret.years -= 1;
  }


  if (ret.days < 0) {
    /*
     * Use temporary dates to get the number of days remaining in the month
     */
    const dtmp1 = new Date(dt1.getFullYear(), dt1.getMonth() + 1, 1, 0, 0, -1);

    const numDays = dtmp1.getDate();

    ret.months -= 1;
    ret.days += numDays;

  }

  return ret;
}

/*
Will return the number of days, month and or years the advert will run
 */
export function formatRuntime(runningTime) {
  const runtimeText = {days: '', months: '', years: '', hours: ''};
  if (runningTime.years) {
    if (runningTime.years === 1) {
      // @ts-ignore
      runtimeText.years = runningTime.years + ' years';
    } else if (runningTime.years > 1) {
      // @ts-ignore
      runtimeText.years = runningTime.years + ' years';
    }
  }
  if (runningTime.months) {
    if (runningTime.months === 1) {
      // @ts-ignore
      runtimeText.months = runningTime.months + ' month';
    } else if (runningTime.months > 1) {
      // @ts-ignore
      runtimeText.months = runningTime.months + ' months';
    }
  }
  if (runningTime.days) {
    if (runningTime.days === 1) {
      // @ts-ignore
      runtimeText.days = runningTime.days + ' day';
    } else if (runningTime.days > 1) {
      // @ts-ignore
      runtimeText.days = runningTime.days + ' days';
    }
  }
  if (runningTime.hours) {
    if (runningTime.hours === 1) {
      // @ts-ignore
      runtimeText.hours = runningTime.hours + ' hour';
    } else if (runningTime.hours > 1) {
      // @ts-ignore
      runtimeText.hours = runningTime.hours + ' hours';
    }
  }
  return runtimeText;
}

export function slots(data) {
  const events = data.filter(item => item.type === 'EVENT');
  const banners = data.filter(item => item.type === 'BANNER');
  const lists = data.filter(item => item.type === 'LIST');
  const eventSlots = slotsFnc(events);
  const bannerSlots = slotsFnc(banners);
  const listSlots = slotsFnc(lists);
  return {BANNER: bannerSlots, EVENT: eventSlots, LIST: listSlots};
}

export function unitDetails(data= [], slot = '', type = '') {
  return data.find(item => item.type === type && item.slot === slot);
}

const slotsFnc = (data) => {
  const slotsArray = [];
  data.map(item => {
    slotsArray.push({title: item.slot, value: item.slot})
  });
  return slotsArray;
}

export function getDatePct(end, start, today) {
  const total = Math.abs(end - start);
  const run = Math.abs(today - start);
  const left = Math.abs(end - today);
  const runPct = Math.round((run / total * 100))
  const leftPct = Math.round((left / total * 100))
  if (start > today) {
    return { runPct: 'Ad time not started yet (0%)', leftPct: '100%' };
  }
  if (today > end) {
    return { runPct: '100%', leftPct: leftPct + 'Ad has finished running (100%)' };
  }
  return { runPct: '(' + runPct + '%)', leftPct: '(' + leftPct + '%)', absRunPct: runPct };
}

export function updateStatus(status) {
  if (status) {
    if (status === 'WAITING') {
      return 'WAIT';
    }
    if (status === 'PAUSED') {
      return 'PAUSE';
    }
    if (status === 'RUNNING') {
      return 'RUN';
    }
    if (status === 'ENDED') {
      return 'ENDED';
    }
    if (status === 'PAID') {
      return 'PAYING';
    } else {
      return status;
    }
  }
}
