import { Component, OnInit } from '@angular/core';
import { FeedbackService } from './feedback.service';
import { Feedlist } from './types';
import transformDate from '../utils/formatDate';
import { FeedDetail } from './types';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  // default variables
  feedlist = [];
  showDetails = false;
  loading = false;
  intialLoad = false;
  feedDetail: FeedDetail;
  selected: number | null;
  // fetches all data when component loads
  constructor(private feedbackService: FeedbackService, private router: Router) {}
  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.intialLoad = true;
    this.feedbackService.getFeedList().subscribe((res: Feedlist[]) => {
        res.forEach(feedback => {
          feedback.date = transformDate(feedback._id);
        });
        this.intialLoad = false;
        return this.feedlist = res
      },
      (e) => {
        this.intialLoad = false;
        console.log('something went wrong', e)
      })
  }
  // fetches single  report
  viewFeedDetail = (feedDetail: Feedlist, index: number) => {
    this.selected = index;
    this.feedbackService.getSingleFeed(feedDetail._id).subscribe((res: FeedDetail) => {
      this.feedDetail = res;
      this.showDetails = true;
      this.loading = false;
    },
      (err) => {
        this.loading = false;
        console.log(err)
      })
}
// closes detail component
 closeShowDetail = ({showDetail}) => {
    this.showDetails = showDetail;
    this.selected = null;
 }
  // called when fix button is clicked
  onFixed = ({runFix, id}) => {
    if (runFix) {
      this.loading = true;
      this.feedbackService.fixFeed(id).subscribe(res => {
       this.feedbackService.getFeedList().subscribe((response: Feedlist[]) => {
         response.forEach(feedback => {
           feedback.date = transformDate(feedback._id);
         });
         this.feedlist = response;
         this.loading = false;
         this.selected = null;
         return this.showDetails = false
       }, (err) => {
         this.loading = false;
         this.selected = null;
         console.log(err)
       })
     }, (err) => {
        this.loading = false;
        this.selected = null;
        console.log(err)
     })
    }
  }
  adminDashboard = () => {
    this.router.navigate(['dashboard'])
}
}
