import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalPopupComponent implements OnInit {

    @Input() my_modal_title;
    @Input() my_modal_content;
    my_modal_button1: any;
    my_modal_button2: any;
    @Input() form;
    sanitizedModalContent;

    constructor(public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {}

    ngOnInit() {
      if (typeof this.my_modal_content === 'string') {
        this.sanitizedModalContent = this.sanitizer.bypassSecurityTrustHtml(this.my_modal_content);
      }
    }


}
