import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BarAddService } from './bar-add.service';
import { ModalContentComponent } from './modal';
import { ModalPopupComponent } from '../common/modal-popup/modal-popup.component';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import parseString from '../utils/parseString';
import { AddAdminServiceService } from '../add-admin/add-admin-service.service';
import { GlobalService } from '../common/service/global.service';
import {ToastrService} from "ngx-toastr";
import {playPointsAudio} from "../utils/rankUtils";
import { TranslatedPipe } from '../../app/pipes/translated.pipe';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-add-bar',
  templateUrl: './bar-add.component.html',
  styleUrls: ['./bar-add.component.css'],
  providers: [BarAddService]
})
export class BarAddComponent implements OnInit{
  // sets default values for new form
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  openMap = false;
  locationSet = false;
  defaultSelect = 'Beer Bars';
  nameNotValid: boolean;
  nameOfBar: string;
  loading = false;
  saving = false;
  title: string;
  areas;

  // access the DOM element with reference #nameRef="ngModel"
  @ViewChild('nameRef', {static: true}) name: ElementRef;
  translate = new TranslatedPipe(this.translateService);

  // Constructor will get called very first when the page loads
  // with the BarAddService, modalService and router
  constructor(private addBarService: BarAddService, private modalService: NgbModal,
              private  router: Router, private location: Location, private globalService: GlobalService,
              private addAdminService: AddAdminServiceService, private toastr: ToastrService, private translateService: TranslateService) {
    // @ts-ignore
    const {areas} = this.globalService.GetGlobalConstants();
    this.areas = this.addBarService.setAreas(this.transformData(areas));
  }
  ngOnInit(): void {
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  // take use back
  goBack = () => {
    this.location.back();
  }

  // check if the form is valid before saving bar
  validateForm = () => {
    if (!this.nameOfBar) {
      return this.showModal('Please enter the bar name', 'Invalid data');
    }
    if (!this.locationSet) {
      return this.showModal('Please choose the location from map!', 'Invalid data');
    }
    return true;
  }

  // transforms coordinates array in the area object
  transformData = data => {
    const  parsedRes = [];
    data.map(({coords, _id, city, color }) => {
      let transformedAreas;
      transformedAreas = {_id, city, color, coords: []};
      coords.map(({latitude, longitude}) => {
        let newCoords;
        newCoords = {lat: latitude, lng: longitude};
        transformedAreas.coords.push(newCoords);
      });
      parsedRes.push(transformedAreas);
    });
    return parsedRes;
  }

  // saves submitted bar
  addBar({value: {nameOfBar, typeOfBar, email, contact, phone}}) {
    if (typeOfBar === 'Beer Bars') {
      typeOfBar = 'BEER';
    }
    if (this.validateForm()) {
      const bar = {
        _id: parseString(nameOfBar),
        type: typeOfBar,
        area: this.title,
        staffArray: undefined

      };
      const admin = {
        email: undefined,
        name: undefined,
        phone: undefined
      };
      if ( email || contact || phone) {
        admin.email = parseString(email);
        admin.name = parseString(contact);
        admin.phone = parseString(phone);
      }
      const location = {
        _id: parseString(nameOfBar),
        type: typeOfBar,
        area: this.title,
        coords: this.addBarService.getLocationCoords()
      };
      this.saving = true;
      this.loading = true;
      if (admin.email || admin.name || admin.phone) {
        this.addAdminService.addAdmin(admin.email, '', admin.name, admin.phone, '', '', true).subscribe((res: any) => {
          bar.staffArray = [res._id];
          this.persistBar(bar, location);
        }, error => {
        });
      } else {
        this.persistBar(bar, location);
      }
    }
  }

  persistBar(bar, location) {
    this.addBarService.saveBar(bar, location, {}, 'simple').subscribe((data: any) => {
        this.loading = false;
        this.saving = false;
        this.onBarCreation(this.nameOfBar, data.user, data.pointsTxt);
      },
      (error) => {
        this.loading = false;
        this.saving = false;
        return this.showModal('Please choose another bar name', 'Bar name already exist');
      });
  }

  // updates state when name of bar is valid
  changeNotValidState = () => {
    this.nameNotValid = true;
  }

// sets the location of the bar by updating the coordinates of the area
  setLocation() {
    this.openMap = !this.openMap;
    const isCoordsSet = Object.entries(this.addBarService.getLocationCoords()).length;
    if (isCoordsSet === 0) {
      this.addBarService.setLocationCoords({
        longitude: 100.879656,
        latitude: 12.931906
      }, 'Pattaya');
    }
    this.locationSet = true;
    this.title = this.addBarService.getBarArea();
  }

  // opens map so as to select the location
  chooseLocation = () => this.openMap = true;

  // opens the modal to show errors
  showModal(body, title) {
    const modalRef = this.modalService.open(ModalPopupComponent, {centered: true});
    modalRef.componentInstance.my_modal_title = `<h5>${title}</h5>`;
    modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <p class="text-center mb-0">${body}</p>
        </div>`;
    modalRef.componentInstance.my_modal_button1 = this.translate.transform('transOkBtn');
  }
  // Open popup
  onBarCreation(title, user = {rankUp: false}, pointsTxt= '') {
    const modalRef = this.modalService.open(ModalContentComponent, {centered: true});
    modalRef.componentInstance.barName = title;
    modalRef.result.then(() => console.log('When user closes'),
      () => {
      if (pointsTxt) {
        playPointsAudio();
        this.toastr.success(`${pointsTxt} `);
      }
      this.router.navigate(['./bar', this.nameOfBar]);
      if (user.rankUp) {
        this.router.navigate(['user/rank']);
      }
    });
  }
}
