import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class ListAdminService {
  // default params
  url = `${environment.server_url}admin/search`;
  saveURl = `${environment.server_url}admin/save`;
  deviceID = localStorage.getItem('deviceID');
  userID = localStorage.getItem('userID');

  constructor(private http: HttpClient) {
  }
  getList = (type) => {
    return this.http.post(this.url, {list: type, deviceID: this.deviceID, userID: this.userID}, httpOptions);
  }
  searchList = (search, type) => {
    const body = {deviceID: this.deviceID, userID: this.userID, search, list: type};
    return this.http.post(this.url, body, httpOptions);
  }
  // tslint:disable-next-line:variable-name
  getSingleItem = (_id) => {
    const body = {deviceID: this.deviceID, userID: this.userID, _id, method: 'FIND'};
    return this.http.post(this.saveURl, body, httpOptions);
  }

  getStaffs = (staffs) => {
    const body = {deviceID: this.deviceID, userID: this.userID, staffArray: staffs, list: 'STAFF'};
    return this.http.post(this.url, body, httpOptions);
  }
}

