import { Injectable } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOServiceService {

  constructor(private meta: Meta, private title: Title) {}

  updateMeta(metaData) {
    for (const data of metaData) {
      this.meta.updateTag({name: data.name, content: data.content});
    }
  }
}
