import { LocationService } from '../common/service/location.service';
import getDistanceFromLatLonInKm from './calculateDistance';
import setPriceWithColors from './setBarPrice';
import barIcon from './addBarMarker';
class MapUtils {
  constructor(private locationService: LocationService) {
  }
  // tslint:disable-next-line:new-parens
  infoWindow = new google.maps.InfoWindow;
  // renames coords : {latitude: val, longitude: val} to {lat: val, lng: val}
refactorCoords  = data => {
    const  parsedRes = [];
    data.map(({coords, _id, city, color }) => {
      let transformedAreas;
      transformedAreas = {_id, city, color, coords: []};
      coords.map(({latitude, longitude}) => {
        let newCoords;
        newCoords = {lat: latitude, lng: longitude};
        transformedAreas.coords.push(newCoords);
      });
      parsedRes.push(transformedAreas);
    });
    return parsedRes;
  }
// populates with polygons
 populateMap = (bars, map) => {
    return bars.forEach(area => {
      if (area.color !== 'ffffff') {
        const pol = new google.maps.Polygon({
          // @ts-ignore
          paths: area.coords,
          strokeColor: '#4E744E',
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: '#' + area.color,
          fillOpacity: 0.35
        });
        pol.setMap(map);
      }
    });
  }
// gets user current location
  getCurrentLocation = (latitude , longitude) => {
    this.locationService.getLocation().subscribe(position => {
      const distance = getDistanceFromLatLonInKm(position.lat, position.lng, latitude, longitude);
      if (distance > 25) {
        return;
      } else {
        latitude = position.lat;
        longitude = position.lng;
      }
    });
  }
  // show info window
  showInfoWindow = (barName, marker, map) => {
    const content =  `<h4 style="font-size: 18px">${barName}</h4>`;
    this.infoWindow.setContent(content);
    return this.infoWindow.open(map, marker);
  }
  addBarMarkers(bars, serverURL, latitude, longitude, map, callback, setPrevMarkerCallback, setPrevMarkers = false) {
    const prevMarkers = [];
    bars.map(bar => {
      const imgUrl = `${serverURL}uploads/short/${bar.photo}.jpg`;
      const priceColored = setPriceWithColors(bar.priceFactor);
      let distance = null;
      if (bar.coords) {
        distance = getDistanceFromLatLonInKm(
          bar.coords.latitude, bar.coords.longitude,
          latitude, longitude);
      // if distance is less than a Kilometre convert to metres
        if (distance < 1) {
        distance = distance * 1000 + 'm';
      } else {
        distance = distance + 'Km'; // return distance in Km
      }
        const coordinates = new google.maps.LatLng(bar.coords.latitude, bar.coords.longitude);
        const icon = barIcon(bar.type);
        const marker = new google.maps.Marker({
        position: coordinates,
        map,
        draggable: false,
        icon,
        // @ts-ignore
        distance,
        bar,
        imgUrl,
        priceColored
      });
        marker.addListener('click', (e) => {
        callback(marker.bar, distance, imgUrl, priceColored);
        this.showInfoWindow(bar._id, marker, map);
      });
        if (setPrevMarkers) {
        prevMarkers.push(marker);
        setPrevMarkerCallback(prevMarkers);
      }
        return marker.setMap(map);
    }
    });
  }

  // parses string to integer
  parseDistance(distance, defaultDistance) {
    if (!distance || distance === 'ALL') {
      distance = 'ALL';
      return distance;
    } else {
      // tslint:disable-next-line:radix
      distance = parseInt(defaultDistance);
      return distance;
    }
  }
  removeBarMarkers(prevBarsMarkers, callback) {
    prevBarsMarkers.map(prevMarker => {
      prevMarker.setMap(null);
    });
    callback();
  }

}

export default MapUtils;
