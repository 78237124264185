import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { httpOptions } from '../../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class LeftService {

  leftSideUrl = `${environment.server_url}front/getLeft`;
  private subject = new Subject<any>();
  content: Array<object>;
  user: object;

  constructor(private http: HttpClient) { }


  sendLeftSide(content) {
      this.subject.next({ ...content, user: this.user });
  }

  getLeft(): Observable<any> {
      return this.subject.asObservable();
  }

  storeUser(user) {
    this.user = user;
  }

  // get left side
  getLeftSide({admin = false}) {
    const body = {
      deviceID : localStorage.getItem('deviceID'),
      userID : localStorage.getItem('userID'),
      censored: false,
      admin
    };
    this.http.post(this.leftSideUrl, body, httpOptions).subscribe(({ leftside, user, content }: any) => {
      if (content) {
        this.content = content;
        return this.subject.next({ content: this.content });
      }
      this.content = leftside;
      this.user = user;
      return this.subject.next({ ...this.content, user: this.user });
    });
  }
}
