import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewRef } from '@angular/core';
import { Bar } from '../../../models/Bar';
import { environment } from '../../../../environments/environment';
import { BarService } from '../../bar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from '../../../common/modal-popup/modal-popup.component';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.css']
})
export class CommentItemComponent implements OnInit {
  @Input() comment: Bar;
  @Input() reactToComment;
  @Input() setCommentItems;
  @ViewChild('reportReason', {static: false}) reportReason;
  reportSent = false;
  serverURL: string = environment.IMAGE_URL;
  IMAGE_URL: string = environment.IMAGE_URL;
  user = localStorage.getItem('deviceID');
  subscription: Subscription;
  reason: string;
  responseMessage: string;

  ratingsIconsUrl = './../../assets/images/icons/rating';

  constructor(private barService: BarService, private modalService: NgbModal,  private toastr: ToastrService) {
    this.subscription = this.barService.getReportReason().subscribe(res => {
      this.reason = res;
    });
  }

  ngOnInit() {
  }

  sendReport(id, bar) {
    const data = {
      deviceID: this.user,
      reason: this.reason,
      bar,
      _id: id,
    };
    this.barService.reportMedia(data, 'bar/reportComment').subscribe(res => {
      if (res) {
        this.responseMessage = 'Your Report has been sent to our Administrators';
        this.refreshComments();
        this.openSuccessModal();
      }
    }, ({error}) => {
      this.responseMessage = error.errMsg;
      this.openSuccessModal();
    });
  }


  openReportForm({id = '', bar = '', event}) {
    event.stopPropagation();
    event.preventDefault();
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.my_modal_title = `<h4 class="text-left">Report photo / Comment </h4>`;
    modalRef.componentInstance.form = true;
    modalRef.componentInstance.my_modal_button2 = 'Send Report';
    modalRef.result.then(() => this.sendReport(id, bar), () => {});
  }

  refreshComments() {
    this.barService.getBarInfo(this.comment.bar).subscribe((res: any) => {
      this.barService.sendComments(res.comment);
    });
  }

  /*
   * displayed when the report is successfully sent
   */
  openSuccessModal() {
    const modalRef = this.modalService.open(ModalPopupComponent);
    // modalRef.componentInstance.my_modal_title = `<h4 class="text-left">Report photo / Comment </h4>`;
    modalRef.componentInstance.my_modal_content = `
    <div class="text-center">
        <p>${this.responseMessage}</p>
    </div>
    `;
    modalRef.componentInstance.my_modal_button2 = 'Ok';
    modalRef.result.then(() => this.reportSent = false, () => {});
  }
}
