import { Component, OnInit } from '@angular/core';
import { AdminDashboardService } from './admin-dashboard.service';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { AddAdminServiceService } from '../add-admin/add-admin-service.service';
import { GlobalService } from '../common/service/global.service';
import { LeftService } from '../common/left/left.service';
import { RightService } from '../common/right/right.service';
import { Subscription } from 'rxjs';
interface stat {
    barsToday: string,
    barsYesterday: string,
    barsTotal: string
}
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})

export class AdminDashboardComponent implements OnInit {
  iconPath = '../assets/images/icons/frontpage/bar.png';
  loading = false;
  data = [];
  user: string;
  id: string;
  completed = false;
  editMode = false;
  userID = localStorage.getItem('userID');
  deviceID = localStorage.getItem('deviceID');
  stat: stat;
  subscription: Subscription;
  menu: Array<object>;
  constructor(
    private dashboardService: AdminDashboardService,
    private router: Router,
    private adminService: AddAdminServiceService,
    private globalService: GlobalService,
    private leftSideService: LeftService,
    private rightSideService: RightService
  ) {
    this.loading = true;
    // if user is not logged in take them to login page
    if (!localStorage.getItem('userID')) {
      this.router.navigate(['admin/login'])
    }
    this.dashboardService.getDashboard(this.deviceID, this.userID).subscribe((res: any[any]) => {
      this.id = res.admin._id;
      this.user = res.admin.name;
      this.data = res;
      this.stat = res.stats[0];
      this.loading = false;
      this.getSides();
    });
  }

  ngOnInit() {

  }
  listAdmin = () => this.router.navigate(['listAdmins']);
  addAdmin = () => this.router.navigate(['createAdmin']);
  addBar = () => this.router.navigate(['/createAdvancedBar']);
  goTo = (link) => this.router.navigate([link]);
  listBar = () => this.router.navigate(['listbar']);
  getFeedback = () => this.router.navigate(['listFeedback']);
  listEvents = () => {};
  addEvent = () => {};
  promotions = () => {};
  barOfDay = () => {};
  orders = () => {};
  statistics = () => this.router.navigate(['/stats']);
  logout = () => {
    this.dashboardService.logout(this.deviceID, this.userID).subscribe(res => {
      localStorage.clear();
      localStorage.setItem('deviceID', UUID.UUID());
      this.globalService.isLoggedIn = false;
      this.globalService.loggedInFlag.next(false);
      this.router.navigate(['/admin/login']);
    });
  }


  getSides() {
      // @ts-ignore
      const {adminMenuLeft} = this.globalService.GetGlobalConstants();
      const { menu } = adminMenuLeft.find(item => item.type === 'MENU');
      this.menu = menu.filter(item => item.text !== 'Dashboard');
      this.leftSideService.sendLeftSide({content: adminMenuLeft});
      this.rightSideService.sendShowStatus({show: false});
  }

  // enable edit mode
  editModeFn = () => this.editMode = true;
  // edit admin handler
  editAdmin = async (email, password, name, phone, job, id: string) => {
    this.loading = true;
    await this.adminService.addAdmin(email, password, name, phone, job, id).subscribe(() => {
        this.dashboardService.getDashboard(this.deviceID, this.userID).subscribe((res: any[any]) => {
          this.id = res.admin._id;
          this.user = res.admin.name;
          this.data = res;
          this.stat = res.stats[0];
        });
        this.editMode = false;
        this.loading = false;
      },
      (error) => {
        console.warn('error', error);
      });
  }
  // deletes selected admin
  deleteAdmin = (id) => {
    window.scrollTo(0, 0);
    this.loading = true;
    this.adminService.deleteAdmin(this.userID).subscribe(() => {
        this.loading = false;
        this.router.navigate(['admin/login']);
      },
      (error) => {
        console.warn('error', error);
      });
  }
}
