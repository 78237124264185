import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { LeftService } from './left.service';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RankService } from '../../rank-system/rank.service';
import BarFormUtils from '../../utils/BarFormUtils';
import FetchGlobalConstants from '../../utils/global';
import { GlobalService } from '../service/global.service';
import { TranslateService } from '@ngx-translate/core';
import { withHyphen } from '../../utils/changeAllWordsCase';
import {UUID} from "angular2-uuid";

@Component({
  selector: 'app-left',
  templateUrl: './left.component.html',
  styleUrls: ['./left.component.css'],
  providers: [
    NgbActiveModal
  ]
})
export class LeftComponent implements OnInit {
  BarFormUtils = new BarFormUtils(this.modalService);
  content: any;
  user: {
    nickName: string,
    rankName: any,
    rank: string
  };
  subscription: Subscription;
  linkIconUrl = '../../assets/images/icons/leftbar';
  frontPageIconUrl = '../../assets/images/icons/frontpage';
  IMAGE_URL = environment.IMAGE_URL;
  toplist = [];
  activeIndex: number;
  toplistActiveIndex: number;
  activeType = {
    activeIndex: false,
    toplistActiveIndex: false
  };
  @ViewChild('loginModal', {static: false}) loginModal;
  @Input() password;
  @Input() nickName;
  globalUtil = new FetchGlobalConstants(this.globalService, this.appService,
    this.leftSideService, this.rankService, this.translate, this.modalService
  );
  withHyphen = withHyphen;

  constructor(private leftSideService: LeftService, private appService: AppService, private router: Router,
              private modalService: NgbModal, private rankService: RankService,
              private globalService: GlobalService, private translate: TranslateService) {
      this.getLeftSide();
  }

  ngOnInit() {}

  getLeftSide() {
    this.subscription = this.leftSideService.getLeft().subscribe((res: any) => {
      this.content = res.content;
      this.user = res.user;
      }, error => {
      });
    }

  logOut($event) {
    localStorage.setItem('deviceID', UUID.UUID());
    this.globalUtil.fetchGlobalConstants().then((globalData: any) => {
      const { menuLeft } = globalData;
      this.leftSideService.sendLeftSide({content: menuLeft});

      // if user is on rank page take them home
      if (this.router.url === '/rank') {
        this.router.navigate(['']);
      }
    });
  }

  /*
* When user press the enter key
*/
  keyDown(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }

  // goTo(activeIndex, route, params) {
  //   // if we are going to a toplist
  //   this.activeIndex = activeIndex;
  //   this.activeType.activeIndex = true;
  //   this.activeType.toplistActiveIndex = false;
  //   if (route === 'list') {
  //     const parameters = Object.values(params);
  //     this.router.navigate([route, ...parameters]);
  //   } else { // this is just a normal link without params
  //     this.router.navigate([route]);
  //   }
  // }

  onRouteClick(activeIndex) {
      // if we are going to a toplist
      this.activeIndex = activeIndex;
      this.activeType.activeIndex = true;
      this.activeType.toplistActiveIndex = false;
  }
  onGoToBar(activeIndex) {
    this.activeType.toplistActiveIndex = true;
    this.activeType.activeIndex = false;
    this.toplistActiveIndex = activeIndex;
  }

  openLoginModal() {
    this.modalService.open(this.loginModal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  dismissModal() {
    this.modalService.dismissAll('Cross click');
  }

  checkBarType(text, barname?, area?) {
    let newText = text;
    if (newText.includes('#BARTYPE#')) {
          newText = newText.replace(/#BARTYPE#/g, 'bar');
        }
    if (newText.includes('#BARTYPES#')) {
          newText = newText.replace(/#BARTYPES#/g, 'bars');
        }
    if (newText.includes('#BARNAME#')) {
      newText = newText.replace(/#BARNAME#/g, barname || '#BARNAME#');
    }
    if (newText.includes('#AREA#')) {
        newText = newText.replace(/#AREA#/g, (area || 'Pattaya'));
      }
    return newText;
  }

  login() {
    const deviceID = localStorage.getItem('deviceID');
    const data = {
      nickName: this.nickName,
      password: this.password,
      deviceID,
      language: localStorage.getItem('locale') || 'gb'
    };
    this.rankService.loginUser(data).subscribe(res => {
      // update the left side
        this.globalUtil.fetchGlobalConstants().then((globalData: any) => {
          const {menuLeft} = globalData;
          this.leftSideService.storeUser(res);
          this.dismissModal();
          this.leftSideService.sendLeftSide({content: menuLeft});
        });
    }, ({error}) => {
      this.BarFormUtils.showModal(error.errMsg, '');
    });
  }

}
