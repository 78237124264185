import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchServiceService } from './search-service.service';
import {AppService} from '../app.service';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalService } from '../common/service/global.service';
import transformTypes, {getArea, getBarFlags} from '../utils/transform-types';
import { environment } from '../../environments/environment';
import parseString from '../utils/parseString';
import { LocationService } from '../common/service/location.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
  providers: [SearchServiceService]
})
export class SearchBarComponent implements OnInit {

  iconUrl = '../assets/images/icons/neonbartype';
  icons: any;
  loading = false;
  iconPath = '../assets/images/icons/frontpage/search.png';
  barTypes: any;
  openTypes: any;
  areaTypes: any;
  distanceTypes: any;
  barArea = 'ALL';
  typeOfBar = 'ALL';
  areaDistance = 'ALL';
  openTime = 'ALL';
  nameOfBar = '';
  bars: any;
  deviceId = localStorage.getItem('deviceID');
  @ViewChild('f', {static: false}) form;
  constructor(
    private searchServiceService: SearchServiceService,
    private appService: AppService,
    private  router: Router,
    private globalData: GlobalService,
    private locationService: LocationService
    ) {
  }

  ngOnInit() {
    this.loading = true;
    const prevSearchParams: any = this.globalData.getPrevParams();
    console.log(prevSearchParams)
    // @ts-ignore
    const {areas: area, type, distance, opentime, barFlags} = this.globalData.GetGlobalConstants();
    this.openTypes = transformTypes(opentime);
    this.distanceTypes = transformTypes(distance);
    this.barTypes = transformTypes(type, 'bar');
    this.areaTypes = getArea(area);
    if (prevSearchParams.icons.length !== 0) {
      this.icons = prevSearchParams.icons;
    }
    if (Object.keys(prevSearchParams.formData).length !== 0) {
      const { barArea, typeOfBar, areaDistance, openTime, nameOfBar} = prevSearchParams.formData;
      this.barArea =  barArea ;
      this.typeOfBar = typeOfBar;
      this.areaDistance = areaDistance;
      this.openTime = openTime;
      this.nameOfBar = nameOfBar;
    } else {
      this.icons = getBarFlags(barFlags);
    }
    this.loading = false;
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      });
    // request for location permissions
    this.locationService.getLocation().subscribe(res => {
    });
  }

  /*
    * When user press the enter key - search for bar
   */
  keyDown(event) {
    if (event.keyCode === 13) {
      this.searchBar()
    }
  }
  capitalize = ({id: word}) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  handleClick = (index, icon) => {
    icon.value = !icon.value;
    this.icons[index] = icon;
  }
  // parses string to integer
  parseDistance(distance) {
    if (!distance || distance === 'ALL') {
      distance = 'ALL';
      return distance;
    } else {
      // tslint:disable-next-line:radix
      distance = parseInt(distance);
      return distance;
    }
  }
  searchBar = () => {
    this.globalData.setPrevParams({icons: this.icons, formData: {
      barArea: this.barArea,
      typeOfBar: this.typeOfBar,
      areaDistance: this.areaDistance,
      openTime: this.openTime,
      nameOfBar: this.nameOfBar}
    });
    const {nameOfBar, typeOfBar, distance, openTime, barArea} = this.form.value;
    const searchParams = this.icons.filter( icon => {
      if (icon.value === true) { return  icon.title; }
    }).map(icon => icon.title);
    this.loading = true;
    this.searchServiceService.searchBar({
      bar: parseString(nameOfBar),
      map: false,
      type: typeOfBar,
      opentime: openTime,
      distance: this.parseDistance(distance),
      area: barArea,
      barFlags: searchParams
    }).subscribe(bars => {
      this.bars = bars;
      this.appService.setBars(bars);
      this.router.navigate(['search-results']);
      this.loading = false;
    });
  }
  fetchSearchConstants(deviceID: string) {
    this.appService.GetGlobalConstants(deviceID)
      .subscribe((result: any) => {

          if(result && Object.keys(result).length) {
            // Store search constants
            this.globalData.setGlobalConstants(result);
            console.log(this.globalData.GetGlobalConstants());
          }
        }, (err) => {
          console.log(`error occured for ${environment.server_url}search/get`, err);
        }
      );
  }
  resetSearch() {
    this.barArea = 'ALL';
    this.typeOfBar = 'ALL';
    this.areaDistance = 'ALL';
    this.openTime = 'ALL';
    this.nameOfBar = '';
    this.icons.map(icon => icon.value = false);
  }
}
