import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class AdService {
  url = environment.server_url;
  userID: string;

  constructor(private http: HttpClient) {
    this.userID = localStorage.getItem('userID')
  }

  geAd(params) {
    return this.http.post(this.url + 'order/getAd', {...params, userID: this.userID}, httpOptions);
  }
  getPackage(params= {}) {
    return this.http.post(this.url + 'order/package', {...params, userID: this.userID}, httpOptions);
  }

  saveAd(data) {
    return this.http.post(this.url + 'order/saveAd', {...data, userID: this.userID}, httpOptions);
  }

  copyAd(params) {
    return this.http.post(this.url + 'order/copyAd', {...params, userID: this.userID}, httpOptions);
  }
  deleteAd(params) {
    return this.http.post(this.url + 'order/deleteAd', {...params, userID: this.userID}, httpOptions);
  }
}
