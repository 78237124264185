import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../common/service/translate.service';

@Pipe({
  name: 'translated',
  pure: false
})
export class TranslatedPipe implements PipeTransform {
  translations: any;

  constructor(private translate: TranslateService) {
  }

  transform(value: any, property?: any, param?: any): any {
    this.translate.get('translations').subscribe((res: any) => {
      if (res) {
        this.translations = res;
      }
    });
    if (this.translations) {
      // @ts-ignore
      const translatedObject = this.translations;
      if (param && param.value) {
        // if value is array replace in order of the array
        if (Array.isArray(param.value)) {
          let text = translatedObject[value];
          param.value.forEach(customValue => {
            text = translatedObject && text &&
              // tslint:disable-next-line:max-line-length
              text.replace(/#([^#]+)#/, `${param.class ? `<span  class="${param.class ? param.class : ''}">${customValue}</span>` : customValue}`) || value;
          });
          return text;
        } else {
          // replace text between # with value provided
          return translatedObject && translatedObject[value] &&
            // tslint:disable-next-line:max-line-length
            translatedObject[value].replace(/#.*#/g, `${param.class ? `<span  class="${param.class ? param.class : ''}">${param.value}</span>` : param.value}`) || value;
        }
        }
      if (translatedObject[value] && translatedObject[value].includes('#BARTYPE#')) {
        return translatedObject[value].replace(/#.*#/g, 'bar');
      }
      if (translatedObject[value] && translatedObject[value].includes('#BARTYPES#')) {
        return translatedObject[value].replace(/#.*#/g, 'bars');
      }
      return translatedObject[value] || value;
    }
  }
}
