import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

    // Upload image to server URL
    imageUploadURL = `${environment.server_url}photo/uploadPhoto`;
    constructor(private http: HttpClient) {}

    //
    // Upload image to server
    // URL: 'photo/uploadPhoto'
    //
    UploadImage (formData) {
      console.log(formData)
        return this.http.post(this.imageUploadURL, formData);
    }
}
