import { Component, OnInit, ViewChild } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { LoginService } from './login.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalService } from '../common/service/global.service';
import { ToastrService } from 'ngx-toastr';
import { ViewEncapsulation } from '@angular/core';
import { AppService } from '../app.service';
import { LeftService } from '../common/left/left.service';
import { RightService } from '../common/right/right.service';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

    @ViewChild('email', { static: false }) email;
    @ViewChild('password', { static: false }) password;
    // default form param
    iconPath = '../assets/images/icons/frontpage/bar.png';
    constructor(
      private loginService: LoginService,
      private routes: Router,
      private activatedRoute: ActivatedRoute,
      private globalService: GlobalService,
      private toastr: ToastrService,
      private leftSideService: LeftService,
      private rightSideService: RightService
    ) {
      this.getSides();
      this.tryAutoLogin();
    }

    ngOnInit() {
      // scroll to top
      this.routes.events.subscribe((event: any) => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
    }

    onEnter(event) {
        if(event.keyCode == 13) {
            if(this.email && this.email.value && this.password && this.password.value) {
                this.login();
            }
          }
    }

  getSides() {
    // this.leftSideService.getLeftSide({admin: false});
    // this.leftSideService.sendLeftSide();
    this.rightSideService.sendShowStatus({show: true});
  }

  /**
   * Auto Login
   */
  tryAutoLogin() {
    if (!localStorage.getItem('userID')) {
      return;
    }
    const inputModelObj = {
      deviceID: localStorage.getItem('deviceID'),
      userID: localStorage.getItem('userID'),
    }
    this.loginService.isAuthenticated(inputModelObj)
      .subscribe((result: any) => {
        this.globalService.isLoggedIn = true;
        this.globalService.loggedInFlag.next(true);
        localStorage.setItem('deviceID', result.deviceID);
        localStorage.setItem('userID', result.userID);

        if (this.routes.url === '/admin/login') {
          this.routes.navigate(['dashboard']);
        }
        // tslint:disable-next-line:no-shadowed-variable
      }, ({error}: any) => {
        if (this.routes.url.indexOf('admin') !== -1) {
          this.toastr.error('Please login first!');
        }
        this.globalService.isLoggedIn = false;
        this.globalService.loggedInFlag.next(false);
        if (error.err) {
          localStorage.removeItem('userID');
        }
      })
  }

    /**
     * Login using login service
     */
    login() {


        if(!this.email.value || !this.password.value) {
            this.toastr.error("Please enter email and password.")
            return true;
        }

        let deviceId = localStorage.getItem('deviceID');
        if (!deviceId) {
            // If user's device id is not there in local storage then just find unique user id and assign to local storage
            localStorage.setItem('deviceID', UUID.UUID());
            deviceId = UUID.UUID();
        }
        const inputModelObj = {
            deviceID : deviceId,
            login: this.email ? this.email.value : '',
            userID: localStorage.getItem('userID'),
            password: this.password ? this.password.value : ''
        }
        this.loginService.isAuthenticated(inputModelObj)
            .subscribe((result: any) => {
                this.globalService.isLoggedIn = true;
                this.globalService.loggedInFlag.next(true);
                localStorage.setItem('deviceID', result.deviceID);
                localStorage.setItem('userID', result.userID);
                // show success message
                this.toastr.success(`${result.name} logged in successfully!`)
                this.routes.navigate(['dashboard']);
            },() => {
                this.globalService.isLoggedIn = false;
                this.globalService.loggedInFlag.next(false);
                this.toastr.error("Please enter valid email and password.");
                this.routes.navigate(['admin/login']);
            })
    }

}
