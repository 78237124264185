import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ListAdminService } from '../list-admin/list-admin.service';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-list-bars',
  templateUrl: './list-bars.component.html',
  styleUrls: ['./list-bars.component.css']
})
export class ListBarsComponent implements OnInit {
  tableHead = ['Bar Name', 'Type', 'Area'];
  bars = [];
  loading = false;

  @ViewChild('search', {static: false}) search;
  iconPath = '../assets/images/icons/frontpage/bar.png';
  constructor(private listBars: ListAdminService, private router: Router) {
    this.loading = true;
    this.listBars.getList('BAR').subscribe((bars: any) => {
      this.bars = bars;
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  // search the bar
  searchList = () => {
    this.loading = true;
    this.listBars.searchList(this.search.value, 'BAR').subscribe((data: any) => {
        this.bars = data;
        this.loading = false;
      },
      (error) => console.log(error));
  }

  goTo(bar?) {
    if (!bar._id) {
      this.router.navigate(['createAdvancedBar']);
    } else {
      this.router.navigate(['createAdvancedBar', {id: bar._id}]);
    }
  }

}
