import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import {BarsNearMeService} from './bars-near-me.service';
import {Location} from '@angular/common';
import transformTypes, { getArea } from '../utils/transform-types';
import { GlobalService } from '../common/service/global.service';
import { LocationService } from '../common/service/location.service';
@Component({
  selector: 'app-bar-near-me',
  templateUrl: './bar-near-me.component.html',
  styleUrls: ['./bar-near-me.component.css'],
  providers: [BarsNearMeService]
})
export class BarNearMeComponent implements OnInit {
  // initializes default values
  barTypes: any;
  distanceTypes: any;
  areaTypes: any;
  showSearch = false;
  loading = false;
  barArea = 'All Area';
  typeOfBar = 'All types of Bars';
  areaDistance = 'All Distances';
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  @Input() latitude: number;
  @Input() longitude: number;
  type = 'ALL';
  area = 'ALL';
  location = {
    latitude: 12.931906,
    longitude: 100.879656
  };
  loadComplete = false;
  // Constructor will get called very first when the page loads
  constructor(private router: Location, private route: Router, private globalData: GlobalService,
              private barService: BarsNearMeService, private locationService: LocationService,
              private globalService: GlobalService) {}
  ngOnInit(): void {
    const data: any = this.globalData.GetGlobalConstants();
    this.loading = true;
    this.locationService.getLocation().subscribe(res => {
      this.loadComplete = true;
      const distance = data.distance;
      const types = data.type;
      if ((distance && distance.length !== 0) || (types && types.length !== 0)) {
        distance.splice(0, 1);
        types.splice(0, 1);
        this.distanceTypes = transformTypes(distance);
        this.barTypes = transformTypes(types, 'bar');
        this.areaTypes = getArea(data.areas);
      }
      this.loading = false;
      //   // @ts-ignore
      this.route.events.subscribe((event: any) => {
          if (!(event instanceof NavigationEnd)) {
            return;
          }
          window.scrollTo(0, 0);
        });
    });
  }

  // opens search form
  showSearchForm = () => {
    this.showSearch = !this.showSearch;
  }
  // resets the form
  resetSearch = () => {
    this.barArea =  'All Area';
    this.typeOfBar = 'All types of Bars';
    this.areaDistance = 'All Distances';
  }
  // got to results
  gotToResults() {
    this.route.navigate(['/search-results']);
  }
  // takes the user to the previous route
  goBack() {
    this.router.back();
  }
}
