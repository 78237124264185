import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddAdminServiceService } from './add-admin-service.service';
import { ModalSuccessComponent } from '../common/admin-form/modal';
import AdminUtils from '../utils/adminUtils';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.css']
})
export class AddAdminComponent implements OnInit {
  // default values
  lastLogin = '';
  created = '';
  loading = false;
  success = false;
  adminUtils = new AdminUtils(this.modalService);
  constructor(private adminService: AddAdminServiceService, private modalService: NgbModal, private router: Router) { }
  ngOnInit(): void {
    // @ts-ignore
    // scrolls the page to the top
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  // add admin handler
  addAdmin = async (email, password, name, phone, job) => {
    this.loading = true;
    await this.adminService.addAdmin(email, password, name, phone, job).subscribe((res: object) => {
      this.success = true;
      this.loading = false;
      this.onAdminCreation(name);
    },
      (error) => {
      console.warn('error', error);
        // this.adminUtils.showModal()
      });
  }
  // delete admin handler
  deleteAdmin = () => {};
  onAdminCreation(adminName, editMode= true) {
    const modalRef = this.modalService.open(ModalSuccessComponent, {centered: true});
    modalRef.componentInstance.adminName = adminName;
    modalRef.componentInstance.editMode = editMode;
    modalRef.result.then(() => (console.log('modal closed')
      ),
      () => this.router.navigate(['listAdmins']));
  }
}
