import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    globalData: any; // common search controller data
    userLocation = null;            // User Location object
    prevSearchParams: object = { icons: [], formData: {} };
    isTeleportedAlertShown: boolean = false;
    savePhoto = new Subject<boolean>();
    userDistanceFromDefaultLocation = null;
    isLoggedIn = false;   // User is logged in
    loggedInFlag = new Subject<boolean>(); // User logged in subject



    constructor(private http: HttpClient) { }

    //
    // Setter method for search constants
    //
    setGlobalConstants(globalConstants: Array<Object>) {
        this.globalData = globalConstants;
    }
    //
    // Setter method for search params
    //
    setPrevParams({ icons, formData }) {
        this.prevSearchParams = { ...this.prevSearchParams, icons, formData };
    }
    //
    // Getter Method for search params
    //
    getPrevParams = () => this.prevSearchParams;
    //
    // Getter Method for search constants
    //
    GetGlobalConstants() { return this.globalData }

    //
    // Setter method for user's current location
    //
    setUserCurrentLocation(userLocationObj) {
        this.userLocation = userLocationObj;
    }

    //
    // Get user's current location
    //
    getUserCurrentLocation() { return this.userLocation }

    //
    // Calculate the lineal distance betweeen two gps coordinates
    // unit: default -> Mile
    //       K -> Kilometer
    //       N -> Nautical mile
    //
    static distance = (lat1, lon1, lat2, lon2, unit) => {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1 / 180;
            var radlat2 = Math.PI * lat2 / 180;
            var theta = lon1 - lon2;
            var radtheta = Math.PI * theta / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit == "K") { dist = dist * 1.609344 }
            if (unit == "N") { dist = dist * 0.8684 }
            return dist;
        }
    };

    setUserDistance(userDistance) {
        this.userDistanceFromDefaultLocation = userDistance;
    }

    getUserDistance() { return this.userDistanceFromDefaultLocation }



}
