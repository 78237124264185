import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { LocationService } from '../../../common/service/location.service';

@Component({
  selector: 'app-simple-map',
  templateUrl: './simple-map.component.html',
  styleUrls: ['./simple-map.component.css']
})
export class SimpleMapComponent implements OnInit {

  longitude: number;
  latitude: number;
  bar: string;
  iconPath = '../../../../../assets/images/bar.png';
  positionLatitude: number;
  positionLongitude: number;

  constructor(private router: ActivatedRoute, private location: Location, private appService: LocationService) {
    this.bar = this.router.snapshot.paramMap.get('bar');
    this.router.paramMap.subscribe((parameters: ParamMap) => {
      this.longitude = Number(parameters.get('longitude'));
      this.latitude = Number(parameters.get('latitude'));
    });
    this.appService.getPosition().then(res => {
      this.positionLatitude = res.latitude;
      this.positionLongitude = res.longitude;
    }).catch(err => {
    });
  }

  ngOnInit() {
  }

  requestDirections() {
    // tslint:disable-next-line:max-line-length
    window.open(`https://www.google.com/maps/dir/?api=1&origin=${this.positionLatitude},${this.positionLongitude}&destination=${this.latitude},${this.longitude}&travelmode=driving`, '_blank');
  }

  goBack() {
    this.location.back();
  }

}
