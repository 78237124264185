import { Component, OnInit, ViewChild} from '@angular/core';
import { ToplistServiceService } from './toplist-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../environments/environment';
import setPriceWithColors from '../utils/setBarPrice';
import { BarsNearMeService } from '../bar-near-me/bars-near-me.service';
import { LocationService } from '../common/service/location.service';
import checkDevice from '../utils/checkDevice';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';
import topListArray from '../common/data/topListArray';
import changeAllWordsCase, { withHyphen } from '../utils/changeAllWordsCase';

@Component({
  selector: 'app-toplist',
  templateUrl: './toplist.component.html',
  styleUrls: ['./toplist.component.css', '../controls/price-modal/price-modal.component.css']
})
export class ToplistComponent implements OnInit {
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faArrowAltCircleRight = faArrowAltCircleRight;
  icon: string;
  iconName: string;
  field: string;
  // tslint:disable-next-line:variable-name
  _id: string;
  list: Array<object>;
  explain: string;
  answer: string;
  headline: string;
  numtext: string;
  pageStructure: object;
  short: string;
  rateIcon: string;
  maxPages: number;
  currentPageIndex = '00';
  currentPage = 1;
  toplistData: Array<object>;

  imageUrl = environment.IMAGE_URL;
  size = 'small';
  toplist: string;
  banner: {
    photoLong: string;
    text: string;
    bar: string;
    slot: string;
    _id: string;
    link: string;
    parm: string;
    type: string;
  };
  topList: string;
  topListArray = topListArray;
  deviceType = new checkDevice(this.deviceService);
  IMAGE_URL = environment.IMAGE_URL;
  withHyphen = withHyphen;

  constructor(private toplistService: ToplistServiceService, private router: ActivatedRoute, private route: Router,
              private locationService: LocationService, private deviceService: DeviceDetectorService, private appService: AppService,
              private translate: TranslateService) {}

  ngOnInit() {
    this.router.params.forEach(params => {
      this.topList = this.route.url.replace(/\//g, '');
      if (params) {
        this.getToplist();
      }
    });
    this.locationService.getLocation().subscribe(res => {
    });
  }

  goToAd(link= '', parm = '', adID = '') {
    this.appService.trackAdClick({_id: adID, deviceID: localStorage.getItem('deviceID')}).subscribe(res => {});
    if (link) {
      if (this.deviceType.isDesktop()) {
        window.open(link, '_blank');
      } else {
        window.location.href = link;
      }
    } else if (parm) {
      this.route.navigate(['bar', this.withHyphen(parm)]);
    }
  }

  scrollToTop() {
    return window.scrollTo({top: 0, left: 0, behavior: 'auto'});
  }

  getToplist() {
    const toplist = this.router.snapshot.paramMap.get('toplist') || this.topList;
    this.toplist = toplist;
    this.toplistService.getTopList(toplist + '-' + this.currentPageIndex).subscribe((data: any) => {
      const { field, rateIcon, short, explain, icon, answer, headline, numtext, pageStructure } = data.listSetting;
      const { maxPages, list, banner } = data.listData;
      this.toplistData = list;
      this.banner = banner;
      this.maxPages = maxPages;
      this.pageStructure = pageStructure;
      this.field = field;
      this.rateIcon = rateIcon;
      this.short = short;
      this.explain = explain;
      this.answer = answer;
      this.headline = headline;
      this.numtext = numtext;
      this.iconName =  icon;
    }, error => {
      if (this.topList && !topListArray.includes(this.topList)) {
        this.goToHome();
      }
    });
  }

  nextPage() {
    this.scrollToTop();
    if (this.maxPages >= this.currentPage && (this.currentPage >= 10)) {
      this.currentPage = this.currentPage + 1;
      // @ts-ignore
      this.currentPageIndex = this.currentPage - 1;
    }
    if (this.maxPages > this.currentPage) {
      if (this.currentPage < 10) {
        // page index in form of '01' to '09'
        this.currentPage = this.currentPage + 1;
        this.currentPageIndex = (parseInt(String(this.currentPage - 2), 10) + 101).toString().substr(1);
      }
      this.getToplist();
    }
  }
  previousPage() {
    this.scrollToTop();
    // if current page index is 0, do nothing
    // below, if "00" == 0 will return true but "00" === 0 is false(so don't do that here please🙏)
    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    if (this.currentPageIndex == 0) {
      return;
    }
    // if current page index is >= 10
    // reduce index by 1
    if (this.maxPages >= this.currentPage && (this.currentPage >= 10)) {
      this.currentPage = this.currentPage - 1;
      // @ts-ignore
      this.currentPageIndex = this.currentPage - 1;
    }
    // if current page is < 10
    if (this.maxPages >= this.currentPage) {
      if (this.currentPage < 10) {
        // page index in form of '01' to '09'
        this.currentPage = this.currentPage - 1;
        this.currentPageIndex = (parseInt(String(this.currentPage - 2), 10) + 101).toString().substr(1);
      }
      this.getToplist();
    }
  }
  goToHome() {
    this.route.navigate(['/home']);
  }

  onClickGoToBar(adID= '') {
    this.appService.setCurrentAdId(adID);
  }

  round5(num) {
    return Math.round(num * 2) / 2;
  }

  setPrice(priceFactor) {
    return setPriceWithColors(priceFactor);
  }
}
