import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from '../common/modal-popup/modal-popup.component';

class AdminUtils {
  constructor(private  modalService: NgbModal) {}
  // modalService = NgbModal;
  isEmail = email => {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  // validates form data
  validForm = (email, password, name, phone, job) => {
    const isValid = this.isEmail(email);
    if (!email || !isValid) {
      return this.showModal( 'Email is invalid', 'Validation  Error');
    }
    if (!password) {
      return this.showModal( 'Password is invalid', 'Validation  Error');
    }
    if (password.length < 4) {
      return this.showModal( 'Password length should greater than 3', 'Validation  Error');
    }
    if (!name) {
      return this.showModal( 'Name is invalid', 'Validation  Error');
    }
    if (!phone) {
      return this.showModal( 'Phone is invalid', 'Validation  Error');
    }
    if (!job) {
      return this.showModal( 'Job is invalid', 'Validation  Error');
    }
    return true;
  }
  validateStaff = (email) => {
    const isValid = this.isEmail(email);
    if (email && !isValid) {
      return this.showModal( 'Email is invalid', 'Validation  Error');
    }
    return true;
  }
  validateName = (name) => {
    if (!name) {
      return this.showModal( 'Name is invalid', 'Validation  Error');
    }
    return true;
  }
  // opens the modal to show errors
  showModal(body, title) {
    const modalRef = this.modalService.open(ModalPopupComponent, {centered: true});
    modalRef.componentInstance.my_modal_title = `<h5>${title}</h5>`;
    modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <p class="text-center mb-0">${body}</p>
        </div>`;
    modalRef.componentInstance.my_modal_button1 = 'OK';
  }
}

export default AdminUtils;
