import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class BarAddService {
  url = `${environment.server_url}bar/barEdit`;
  barAreaUrl =  `${environment.server_url}search/get`;
  deviceId = localStorage.getItem('deviceID');
  userID = localStorage.getItem('userID');
  body = {
    status: 'SIMPLE',
    userID: this.userID,
    deviceID: this.deviceId,
    location: {},
    bar: {},
    admin: {},
    deletedPhotos: [],
    // adminEmail: '5df0e1a0b463fc1585e0c587'
  };

  constructor(private http: HttpClient) {
  }

  private locationCoors: object = {};
  private areas: [];
  private barArea: string;

  // return bar location coordinates
  getLocationCoords() {
    return this.locationCoors;
  }

  // return bar area name
  getBarArea() {
    return this.barArea;
  }
  // set bar all areas which are fetched from backend
  setAreas = areas => this.areas = areas;
  // return bar areas
  getAreas = () => this.areas;
  setLocationCoords(coords: object, barArea: string) {
    this.locationCoors = coords;
    this.barArea = barArea;
  }
// sands a request to save bar on the backend
  saveBar = (bar, location, admin= {}, status?, deletedPhotos?) => {
    if (status === 'ADVANCED') {
      this.body.status = status;
    }
    if (deletedPhotos) {
      this.body.deletedPhotos = deletedPhotos;
    }
    this.body.bar = bar;
    this.body.location = location;
    return this.http.post(this.url, this.body, httpOptions);
  }
  // fetches all bar areas
  fetchBarArea = () => {
    return this.http.post(this.barAreaUrl, {deviceID: this.body.deviceID}, httpOptions);
  }
}

