import { Component, Input, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, ParamMap, Router} from '@angular/router';
import { Location } from '@angular/common';
import { RatingService } from './rating.service';
import { ModalPopupComponent } from '../../common/modal-popup/modal-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/common/service/global.service';
import { AppService } from '../../app.service';
import { Observable } from 'rxjs';
import {playPointsAudio, userRankUp} from "../../utils/rankUtils";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {
  @Input() filename;
  // name of the bar
  barName: string;
  // ratings
  hotgirlsRating = 0;
  funRating = 0;
  serviceRating = 0;
  moneyValueRating = 0;
  showRating = 0;

  // photo upload status
  isUploadingPhoto = false;

  // user comment
  comment: string;
  // array of icons(full and empty)
  hotgirlsIcons = this.getFilledIcons(this.hotgirlsRating);
  funIcons = this.getFilledIcons(this.funRating);
  serviceIcons = this.getFilledIcons(this.serviceRating);
  moneyValueIcons = this.getFilledIcons(this.moneyValueRating);
  showRatingIcons = this.getFilledIcons(this.showRating);

  // icons paths
  fireIcons = this.getIconPath('fire');
  yellowSmileIcons = this.getIconPath('yellow-smile');
  greenSmileIcons = this.getIconPath('green-smile');
  heartIcons = this.getIconPath('heart');
  starIcons = this.getIconPath('star');

  // Header Icon
  iconPath = '../../../assets/images/icons/frontpage/ratings.png';

  selectedPhoto: File = null;
  barType: string;
  show: string;

  constructor(
    private route: ActivatedRoute,
    private ratingService: RatingService,
    private location: Location,
    private modalService: NgbModal,
    private globalService: GlobalService,
    private appService: AppService,
    private router: Router, private toastr: ToastrService
  ) {
    this.barName = this.route.snapshot.paramMap.get('barName');
    this.globalService.savePhoto.next(false);

  }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.barType = params.get('type');
      this.show = params.get('show');
    });
  }

  getFilledIcons(rating) {
    const full = Array(Math.floor(rating)).fill('fullIcon');
    const empty = Array(Math.floor(5 - rating)).fill('emptyIcon');
    return full.concat(empty);
  }

  getIconPath(iconName) {
    return {
      active : `./../../../assets/images/icons/rating/rate-${iconName}-active.png`,
      inactive : `./../../../assets/images/icons/rating/rate-${iconName}-inactive.png`
    };
  }

  updateRatings(index, type) {
    // on icon clicked, get the icon index and calculate new rating
    const rating =  index + 1;
    switch (type) {
      case 'hotgirls':
        this.hotgirlsRating = rating;
        this.hotgirlsIcons = this.getFilledIcons(this.hotgirlsRating);
        break;
      case 'fun':
        this.funRating = rating;
        this.funIcons = this.getFilledIcons(this.funRating);
        break;
      case 'service':
        this.serviceRating = rating;
        this.serviceIcons = this.getFilledIcons(this.serviceRating);
        break;
      case 'moneyValue':
        this.moneyValueRating = rating;
        this.moneyValueIcons = this.getFilledIcons(this.moneyValueRating);
        break;
      case 'show':
        this.showRating = rating;
        this.showRatingIcons = this.getFilledIcons(this.showRating);
        break;
      default:
        break;
    }
  }

  // get photo
  getPhoto($event) {
    this.filename = $event;
  }

  // on save
  onSubmit() {
    console.log('saving rating...');
    this.globalService.savePhoto.next(true);
    const ratings = [this.hotgirlsRating, this.funRating, this.serviceRating, this.moneyValueRating, this.showRating];
    const ratingData = {
      deviceID: localStorage.getItem('deviceID'),
      bar: this.barName,
      rate: ratings,
      text: this.comment,
      photo: this.selectedPhoto ? this.selectedPhoto.constructor.prototype.nameInDB : null,
      active: true
    };
    this.ratingService.saveRatings(ratingData).subscribe((res: any) => {
      const { user } = res;
      if (userRankUp(user)) {
        this.router.navigate(['rank']);
      }
      console.log(res);
      // show message
      if (res && this.filename) {
        this.appService.scrollToCommentsArea();
        playPointsAudio();
        this.toastr.success(`${res.pointTxt} `);
        this.goBack();
        // this.openRatingModal({});
      } else if (res && !this.filename) {
        this.appService.scrollToCommentsArea();
        playPointsAudio();
        this.toastr.success(`${res.pointTxt} `);
        this.goBack();
        // this.openRatingModal({points: 5});
      }
      // go back to bar page
      // this.goBack();
    }, ({error}) => {
      if (error.err) {
        this.openRatingModal({ error: error.errMsg });
      }
    });
  }


  // displays rating modal
  openRatingModal({points = 10, error = ''}) {
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.my_modal_title = '<h5>Ratings</h5>';
    modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <img height="150" width="120" src="${this.iconPath}" alt="rating-image">
            <p class="text-center mb-0">Your rating of</p>
            <p class="text-center font-weight-bold mb-0">${this.barName}</p>
            <p class="text-center mb-10">${error ? 'has not been saved' : 'has been saved'}</p>
            <h6 class="${error ? 'text-danger' : 'text-success' }">${error ? error : `You gained ${points} Nightlife Points`}</h6>
        </div>`;
    modalRef.componentInstance.my_modal_button1 = 'OK';
    modalRef.result.then(() => { this.goBack(); }, () => { this.goBack(); });
  }

  goBack() {
    this.location.back();
  }

  //
  // Check if user has selected the photo from file explore or drag and drop
  //
  isPhotoSelected(photo) {
    this.selectedPhoto = photo;
  }
}
