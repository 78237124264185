import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { GlobalService } from './common/service/global.service';
import { LocationService } from './common/service/location.service';
import { LoginService } from './admin/login.service';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LeftService } from './common/left/left.service';
import { Observable, of, Subscription } from 'rxjs';
import { RightService } from './common/right/right.service';
import { faPlus, faUser, faSquareFull, faSearch } from '@fortawesome/free-solid-svg-icons';
import parseString from './utils/parseString';
import { SearchServiceService } from './search-bar/search-service.service';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap, filter, pairwise } from 'rxjs/operators';
import BarUtils from './utils/BarFormUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import { TranslationService } from './common/service/translate.service';
import { RankService } from './rank-system/rank.service';
import { NgxSpinnerService } from 'ngx-spinner';
import FetchGlobalConstants from './utils/global';
import { withHyphen } from './utils/changeAllWordsCase';
import {FrontPageService} from './home/front-page.service';
import {Meta, Title} from '@angular/platform-browser';
import {SEOServiceService} from "./seoservice.service";

// declare ga as a function to set and sent the events
declare let ga: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit, AfterViewChecked {
    hasFullView = false;

    subscription: Subscription;
    showRightSide = true;
    message;
    isAdmin = false; // isAdmin
    bars: Array<string>;
    faPlus = faPlus;
    faUser = faUser;
    faSearch = faSearch;
    faSquare = faSquareFull;
    searching = false;
    searchFailed = false;
    @Input() searchQuery: string;
    availableLanguages: Array<object>;
    selectedLanguage = { _id: '', name: '' };
    barUtils = new BarUtils(this.modalService);
    user: object;
    isLoading = true;
    menuLeft = [];
    translations: object;
    // @ts-ignore
    globalUtil = new FetchGlobalConstants(this.globalService, this.appService,
      this.leftSideService, this.rankService, this.translate, this.modalService
      );
    withHyphen = withHyphen;
    showAdminButton: boolean;
    showCheatButton: boolean;
    constructor(private appService: AppService,
                private globalService: GlobalService,
                private locationService: LocationService,
                private loginService: LoginService,
                private routes: Router,
                private toastr: ToastrService,
                private leftSideService: LeftService,
                private rightSideService: RightService,
                private changeDetector: ChangeDetectorRef,
                private searchServiceService: SearchServiceService,
                private modalService: NgbModal,
                private activatedRoute: ActivatedRoute,
                private translate: TranslateService,
                private translationService: TranslationService,
                private rankService: RankService,
                private spinner: NgxSpinnerService, private frontPageService: FrontPageService,
                private seoService: SEOServiceService
    ) {
        // Set default language
        // translate.setDefaultLang('en');
        // show loader
        this.spinner.show();

        // generate device id
        let deviceId = localStorage.getItem('deviceID');
        if (!deviceId) {
          // If user's device id is not there in local storage then just find unique user id and assign to local storage
          localStorage.setItem('deviceID', UUID.UUID());
          deviceId = UUID.UUID();
        }
        // Get global constants
        this.globalUtil.fetchGlobalConstants().then(({bars, language, translations, front, showAdminButton,
                                                       showCheatButton}) => {
          const userLanguage = this.translate.getDefaultLang() || localStorage.getItem('locale') || 'gb';
          this.translations = translations;
          this.availableLanguages = language;
          this.bars = bars;
          this.showAdminButton = showAdminButton;
          this.showCheatButton = showCheatButton;
          this.updateLanguage(userLanguage);
          if (deviceId) {
            this.isLoading = false;
          }
        });

        // auto login
        // this.tryAutoLogin();

        // setTimeout(() => {
        //     this.updateLanguage('gb');
        // }, 1000)

        this.getRightSide();

        // if user refreshed window from admin side redirect to dashboard
        this.routes.events
        .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
        .subscribe(event => {
//console.log(location.href);
            // subscribe to router events and send page views to Google Analytics        
          ga('set', 'page', location.href);
          ga('send', 'pageview');

          this.previousRoute$.subscribe(url => {
            this.appService.updateRouteHistory({current: url[0].url, previous: url[0].url});
            const previous =  url[0].url;
            const current = url[1].url;
            // if from rating form/bar form go home
            this.appService.updateRouteHistory({previous, current});
          });
          if (event.url.search('hasFullView=true') !== -1) {
            this.hasFullView = true;
          }
          if (event.id === 2 && event.url === event.urlAfterRedirects) {
            // at this point the window has been refreshed
            // redirect the user to dashboard
            if (event.url === '/admin/login') {
              this.routes.navigate(['dashboard']);
            }
          }
          // if user refresh page when on public pages
          if (event.id === 1 && event.url !== '/' && event.url.search('hasFullView=true') === -1) {
            if (event.url.search('/graph;type') !== -1) {
              return;
            }
            // redirect the user to home
            if (event.url.search('/home') === -1) {
              // this.fetchWithPermission();
              this.rightSideService.getRightSide().subscribe((res: any) => {
                this.rightSideService.storeContent(res.content);
              });
            }
          }
        });

        this.globalService.loggedInFlag.subscribe((loggedIn) => {
            if (loggedIn) {
                // If user is logged in
                this.isAdmin = true;
            } else {
                this.isAdmin = false;
            }
        }, () => {
            this.isAdmin = false;
        });
    }

    ngOnInit() {
      this.updateMeta();
    }

  updateMeta() {
      const data = [
        {
          name: 'GOOGLE-SITE',
          content: 'pSqo03plnEQ929Yg-FJ0pRXqC0pT5SJjmcJJ3XtcR6A", (I will give you this in data/get)'
        },
        {
          name: 'BROWSER-LANGUAGE',
          content: localStorage.getItem('locale') || 'gb',
        },
        {
          name: 'PAGE-LINK',
          content: 'The canonical link of the page (you can set this one right?)',
        },
        {
          name: 'IMAGE-PATH',
          content: 'src/assets/images',
        },
        {name: 'CURRENT-TIME', content: new Date()},
        {name: 'PAGE-TITLE', content: 'The title of the current page (I can send with page controller?)'},
        {name: 'PAGE-DESCRIPTION', content: 'The description of the current page (I can send with page controller?)'},
        {name: 'PAGE-KEYWORDS', content: 'The page keywords (I can send with page controller?)'},
        {name: 'OG-IMAGE', content: 'The image used for Facebook and Twitter (I can send with page controller?)'}
      ];
      this.seoService.updateMeta(data);
  }

  /*
   * gets user's previous and current route
   */
  get previousRoute$(): Observable<Array<any>> {
    return this.routes.events.pipe(
      filter(e => e instanceof RoutesRecognized),
      pairwise(),
      map((e: [RoutesRecognized, RoutesRecognized]) => e)
    );
  }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

    // get right right side
  getRightSide() {
    this.subscription = this.rightSideService.shouldShowRightSide().subscribe((res: any) => {
      this.showRightSide = !!res.showRightSide;
    });
  }

  /**
   * Update language with translator
   */
  updateLanguage(newLang, isClick?) {
    // if it is click event show loader
    if (isClick) {
      this.spinner.show();
    }
    if (!isClick) {
      this.spinner.hide();
    }
    localStorage.setItem('locale', newLang);
    this.translate.setTranslation(newLang, {translations: this.translations});
    this.translate.setDefaultLang(newLang);
    if (this.availableLanguages) {
      // @ts-ignore
      this.selectedLanguage = this.availableLanguages.find((language: any) => language._id === newLang);
    }
    if (isClick) {
      this.updateUserLanguage(newLang, isClick);
    }
  }

  /*
  Update user language
   */
  updateUserLanguage(language, isClick?) {
    this.isLoading = true;
    const data = {...this.user, language};
    this.rankService.getUserData(data).subscribe((res: any) => {
        // Get new translations
        this.globalUtil.fetchGlobalConstants().then(({translations, front, user}) => {
          this.translate.setTranslation(language, {translations});
          this.spinner.hide();
          this.isLoading = false;
          if (user.rankUp) {
            this.routes.navigate(['/rank']);
          }
        }, (error) => {
          console.log('data/get', error);
        } );
    });
  }


  onLanguageClick($event) {
    $event.preventDefault();
  }

  /*
  * When user press the enter key - search for bar
 */
  keyDown(event) {
    if (event.keyCode === 13) {
      this.searchBar();
    }
  }

  // if user focus back to search field search again
  public trySearching(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  rankCheat() {
    this.appService.rankCheat({deviceID: localStorage.getItem('deviceID')}).subscribe(res => {});
  }

  // search bar
  searchBar() {
    this.searchServiceService.searchBar({
      bar: parseString(this.searchQuery || ''),
      type: 'ALL',
      opentime: 'ALL',
      distance: 'ALL',
      area: 'ALL',
      barFlags: []
    }).subscribe(bars => {
      this.appService.setBars(bars);
      this.appService.sendBars(bars);
      this.routes.navigate(['search-results']);
    });
  }

  // search
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => {
        if (term) {
          return this.barUtils.filterBar(this.bars, term).pipe(
            tap(() => this.searchFailed = false),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }));
        } else {
          return  of([]);
        }
      }),
      map((res: any) => {
        const items = [];
        res.forEach(item => {
          items.push(item);
        });
        if (items.length === 0) {
          return [];
        }
        if (items.length > 10) {
          return items.slice(0, 10);
        } else { return items; }
      }),
      tap(() => this.searching = false)
    )

  goTo(route) {
     this.routes.navigate([route]);
  }

  selectedItem($event) {
    this.searchQuery = $event.item;
    this.routes.navigate(['/', this.withHyphen($event.item)]);
  }

    /**
     * User log out
     */
    doLogOut() {
        this.globalService.loggedInFlag.next(false);
        this.globalService.isLoggedIn = false;
        localStorage.removeItem('deviceID');
    }
}
