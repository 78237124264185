import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatsService } from '../stats.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { LeftService } from '../../common/left/left.service';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['../admin-stats.component.css', './graph.component.css']
})
export class GraphComponent implements OnInit {
  graphInfo = [];
  newUsers = [];
  totalUsers = [];
  dates = [];
  barChartData: ChartDataSets[] = [];
  isLoading = true;
  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      labels : {
        fontColor : '#ffffff'
      },
      display: true
    },
    scales: {
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
          fontColor: 'white'
        },
      }],
      xAxes: [{
        stacked: true,
        ticks: {
          fontColor: 'white'
        },
      }]
    }
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'transparent',
    },
    {
      borderColor: 'grey',
      backgroundColor: 'transparent',
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  type: string;

  constructor(private datePipe: DatePipe, private activatedRoute: ActivatedRoute,
              private statsService: StatsService, private sanitizer: DomSanitizer,
              private router: Router) {
    // this.prepareData();
  }

  ngOnInit() {
    this.activatedRoute.params.forEach(params => {
      this.type = params.type.toUpperCase();
      if (this.type  === 'TRAFFIC' || this.type === 'MONEY') {
       this.getGraphInfo();
      } else {
        this.getFunInfo();
      }
    });
  }

  goBack() {
    this.router.navigate(['stats']);
  }

  getGraphInfo() {
    this.statsService.getGraphStat({type: this.type}).subscribe((res: Array<object>) => {
      this.graphInfo = res;
      this.isLoading = false;
      console.log(res);
    });
  }

  getFunInfo() {
    this.statsService.getFunStats({type: this.type}).subscribe((res: Array<object>) => {
      this.graphInfo = res;
      this.isLoading = false;
      console.log(res);
    });
  }

  sanitizeHtml(dom) {
    return this.sanitizer.bypassSecurityTrustHtml(dom);
  }

  barChartOptionsNoLegend() {
    this.barChartOptions.legend.display = false;
    return this.barChartOptions;
  }

  // prepareData() {
  //
  //   let from = new Date(2020, 4, 1);
  //   let to = new Date(2020, 4, 30);
  //   let tot = 0;
  //
  //   for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {
  //     let users = Math.floor(Math.random() * day.getDate()) + 1
  //     tot += users;
  //     let date = this.datePipe.transform(new Date(day), 'dd');
  //     this.dates.push(date);
  //     this.newUsers.push(users);
  //     this.totalUsers.push(tot);
  //   }
  // }
  //
  // drawBarData() {
  //   this.barChartLabels = this.dates;
  //   this.barChartData = [
  //     { data: this.totalUsers, label: 'Total Users' },
  //     { data: this.newUsers, label: 'New Users' }
  //   ];
  // }

  // drawLineData() {
  //   this.lineChartLabels = this.dates;
  //   this.lineChartData = [
  //     { data: this.totalUsers, label: 'Total Users' },
  //     { data: this.newUsers, label: 'New Users' }
  //   ]
  // }


}
