import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { httpOptions } from '../../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  serverUrl = `${environment.server_url}bar/barRate`;

  constructor(private http: HttpClient) { }

  saveRatings(ratingData) {
    return this.http.post(this.serverUrl, ratingData, httpOptions);
  }
}
