import { Component, ViewChild, ElementRef, AfterViewInit, Input, OnInit } from '@angular/core';
import {BarAddService} from '../bar-add.service';

@Component({
  selector: 'app-add-bar-map',
  templateUrl: './bar-add-map.component.html',
  styleUrls: ['./bar-add-map.component.css'],
})
export class BarAddMapComponent implements AfterViewInit, OnInit{
  // Constructor will get called very first when the page loads

  constructor(private addBarService: BarAddService) {
    // set bar areas when the page loads for the first time
    // this.barAreas = addBarService.getAreas();
  }


  // access the DOM element(mapContainer div)  when the map will be plugged
  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;
  // @ts-ignore
  map: google.maps.Map;
  // initial center position for the map
  lat = 12.931906;
  lng = 100.879656;

  @Input() position;
  // initializes default values
  @Input() barAreas: [];
  title: string;
  coordinates = new google.maps.LatLng(this.lat, this.lng);

  // initializes marker instance
  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
    draggable: false,
  });

  // @ts-ignore set map options
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 16,
  };

  // populates the map with polygons
  populateMap() {
    return this.barAreas.forEach(area => {
      const pol = new google.maps.Polygon({
        // @ts-ignore
        paths: area.coords,
        strokeColor: '#4E744E',
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: '#7ADC74',
        fillOpacity: 0.35
      });
      pol.setMap(this.map);
    });
  }

  // sets the barArea from the polygon
  setTitle = (currentPosition, polygonData) => {
    let barArea = 'Pattaya';
    polygonData.forEach( area => {
      const currentPolygon = new google.maps.Polygon({
        paths: area.coords,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      });

      // check whether the location picked from the map is within the polygon
      const isWithinArea = google.maps.geometry.poly.containsLocation(currentPosition, currentPolygon);
      if (isWithinArea) {
        barArea = area._id;
        return;
      }
    });
    this.title = barArea;
    return;
}

// initializes the map on the DOM
  mapInitializer() {
    if (this.position && this.position.coords) {
      const currentPosition = new google.maps.LatLng(this.position.coords.latitude, this.position.coords.longitude);
      this.marker.setPosition(currentPosition);
      this.map.setCenter(currentPosition);
    }
    this.populateMap();

    // adds  marker on the map
    this.marker.setMap(this.map);

    // listens for click event and moves the marker on the center of the map
    this.map.addListener('click', () => {
      this.map.setZoom(16);
      this.marker.setPosition({lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng()});
    });

// listen for drag event and moves marker to the center of the map
    this.map.addListener('drag', (event) => {
      const coordinates = {
        latitude: 12.931906,
        longitude: 100.879656
      };
      coordinates.latitude = this.marker.getPosition().lat();
      coordinates.longitude = this.marker.getPosition().lng();

      // moves the marker to the center of the map
      this.marker.setPosition({lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng()});
      this.setTitle(this.marker.getPosition(), this.barAreas);

      //  updates bar location
      this.addBarService.setLocationCoords(coordinates, this.title);
    });
  }

  // this lifecycle hook is called after component is fully initialized.
  ngAfterViewInit() {
   this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
   this.marker.setMap(this.map);
   const position = new google.maps.LatLng(this.lat || 12.931906, this.lng || 100.879656);
   this.map.setCenter(position);
   this.mapInitializer();
  }
  ngOnInit(): void {
    const coords = this.addBarService.getLocationCoords();
    if (coords) {
      // @ts-ignore
      this.lat =  coords.latitude;
      // @ts-ignore
      this.lng = coords.longitude;
      this.marker.setPosition({lat: this.lat || 12.931906, lng: this.lng || 100.879656});
      const position = new google.maps.LatLng(this.lat, this.lng);
      // tslint:disable-next-line:no-unused-expression
      this.map && this.map.setCenter(position);
    }
  }
}
