import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { CreateAdvancedBarComponent } from './create-advanced-bar/create-advanced-bar.component';
import { DragDropDirective } from './drag-drop.directive';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { LightboxModule } from 'ngx-lightbox';
import { AgmCoreModule } from '@agm/core';
import { DeviceDetectorModule } from 'ngx-device-detector';
import {ScrollingModule, ScrollDispatcher} from '@angular/cdk/scrolling';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { BarComponent } from './bar/bar.component';

import { CommentItemComponent } from './bar/components/comment-item/comment-item.component';
import { CommentSectionComponent } from './bar/components/comment-section/comment-section.component';
import { RatingsItemsComponent } from './bar/components/ratings-items/ratings-items.component';
import { PriceModalComponent } from './controls/price-modal/price-modal.component';
import { RatingComponent } from './bar/rating/rating.component';
import { BarAddComponent } from './baradd/bar-add.component';
import { BarAddMapComponent } from './baradd/bar-add-map/bar-add-map.component';
import { ModalContentComponent } from './baradd/modal';
import { LeftComponent } from './common/left/left.component';
import { RightComponent } from './common/right/right.component';
import { ModalPopupComponent } from './common/modal-popup/modal-popup.component';
import { BarNearMeComponent } from './bar-near-me/bar-near-me.component';
import { BarNearMeMapComponent } from './bar-near-me/bar-near-me-map/bar-near-me-map.component';
import { ToplistComponent } from './toplist/toplist.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { HeaderComponent } from './layout/header/header.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { MapComponent } from './common/map/map.component';
import { SupportComponent } from './support/support.component';
import { BarDetailsComponent } from './common/bar-details/bar-details.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { AdminFormComponent } from './common/admin-form/admin-form.component';
import { ModalSuccessComponent } from './common/admin-form/modal';
import { InputComponent } from './common/input/input.component';
import { SelectComponent } from './common/select/select.component';
import { CustomBtnComponent } from './common/custom-btn/custom-btn.component';
import { AdminComponent } from './admin/admin.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ListAdminComponent } from './list-admin/list-admin.component';
import { ListTableComponent } from './common/list-table/list-table.component';
import { LoaderComponent } from './common/loader/loader.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AuthGuard } from './auth/auth.guard';

import { ListBarsComponent } from './list-bars/list-bars.component';
import { SimpleMapComponent } from './bar/components/simple-map/simple-map.component';
import { ConfirmBarAdditionComponent } from './create-advanced-bar/confirm-bar-addition/confirm-bar-addition.component';


//import { MessagingService } from './shared/messaging.service';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { PushNotificationService } from './shared/push-notification.service';
import { ReportOffenseFormComponent } from './bar/report-offense-form/report-offense-form.component';
import { ContactComponent } from './contact/contact.component';
import { FeedbackComponent } from './feeback/feedback.component';
import { FeedbackDetailsComponent } from './feeback/feeback-details/feedback-details.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { LogoutComponent } from './admin/logout/logout.component';
import { environment } from 'src/environments/environment';
import { CreateOrderComponent } from './ad-system/create-order/create-order.component';
import { ListOrdersComponent } from './ad-system/list-orders/list-orders.component';
import { EditAdComponent } from './ad-system/edit-ad/edit-ad.component';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { AdminStatsComponent } from './admin-stats/admin-stats.component';
import { ChartsModule } from 'ng2-charts';
import { OrderStatusComponent } from './ad-system/order-status/order-status.component';
import { GraphComponent } from './admin-stats/graph/graph.component';
import { DurationComponent } from './admin-stats/duration/duration.component';
import { SignUpComponent } from './rank-system/sign-up/sign-up.component';
import { UserRankComponent } from './rank-system/user-rank/user-rank.component';
import { AboutComponent } from './rank-system/about/about.component';
import { ListComponent } from './rank-system/list/list.component';
import { TranslatedPipe } from './pipes/translated.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import {DataInterceptor} from './core/interceptors/data.interceptor';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        CreateAdvancedBarComponent,
        DragDropDirective,
        UploadFileComponent,
        BarComponent,
        CommentItemComponent,
        CommentSectionComponent,
        RatingsItemsComponent,
        PriceModalComponent,
        BarAddComponent,
        BarAddMapComponent,
        ModalContentComponent,
        LeftComponent,
        RightComponent,
        ModalPopupComponent,
        ToplistComponent,
        PriceModalComponent,
        RatingComponent,
        ModalPopupComponent,
        BarNearMeComponent,
        BarNearMeMapComponent,
        SearchResultsComponent,
        HeaderComponent,
        SearchBarComponent,
        MapComponent,
        SupportComponent,
        BarDetailsComponent,
        PrivacyComponent,
        AddAdminComponent,
        AdminFormComponent,
        ModalSuccessComponent,
        InputComponent,
        SelectComponent,
        CustomBtnComponent,
        AdminComponent,
        CustomBtnComponent,
        ListAdminComponent,
        ListTableComponent,
        LoaderComponent,
        AdminDashboardComponent,
        ListBarsComponent,
        SimpleMapComponent,
        ConfirmBarAdditionComponent,
        ReportOffenseFormComponent,
        ContactComponent,
        FeedbackComponent,
        FeedbackDetailsComponent,
        TermsAndConditionsComponent,
        LogoutComponent,
        CreateOrderComponent,
        ListOrdersComponent,
        EditAdComponent,
        AdminStatsComponent,
        OrderStatusComponent,
        GraphComponent,
        DurationComponent,
        SignUpComponent,
        UserRankComponent,
        AboutComponent,
        ListComponent,
        TranslatedPipe,

    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'pattayaNightLifeWeb'}),
        AppRoutingModule,
        NgbModule,
        CommonModule,

        BrowserAnimationsModule,
        // tslint:disable-next-line:indent
        ToastrModule.forRoot(),
        DeviceDetectorModule.forRoot(),
        HttpClientModule,
        ScrollingModule,
        // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
        // and returns simulated server responses.
        // Remove it when a real server is ready to receive requests.
        // HttpClientInMemoryWebApiModule.forRoot(
        //   InMemoryDataService, { dataEncapsulation: false }
        // ),
        CommonModule,
        AngularFileUploaderModule,
        LightboxModule,
        FontAwesomeModule,
        AgmCoreModule.forRoot({
            // please get your own API key here:
            // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
            apiKey: environment.apiKey,
        }),
        FormsModule,
        AngularFireModule.initializeApp({
            apiKey: "AIzaSyDz9ZMUZxHG0HMcWalNKyGWvDZmbPyOQBU",
            authDomain: "pattayanightlifeguide-364b0.firebaseapp.com",
            databaseURL: "https://pattayanightlifeguide-364b0.firebaseio.com",
            projectId: "pattayanightlifeguide-364b0",
            storageBucket: "pattayanightlifeguide-364b0.appspot.com",
            messagingSenderId: "978244665919",
            appId: "1:978244665919:web:dbfa25dd47d6a2db3dae8a",
            measurementId: "G-M0TTLEPSG5"
        }),
        AngularFireMessagingModule,
        ChartsModule,
        TranslateModule.forRoot(),
        NgxSpinnerModule
    ],
    providers: [AuthGuard, PushNotificationService, ToastrService, DatePipe, ScrollDispatcher,
      { provide: HTTP_INTERCEPTORS, useClass: DataInterceptor, multi: true }],
    bootstrap: [AppComponent],
    entryComponents: [ModalContentComponent, ModalPopupComponent, ModalSuccessComponent],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
