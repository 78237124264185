import { Component, OnInit, ViewChild } from '@angular/core';
import { ListAdminService} from './list-admin.service';
import { NavigationEnd, Router } from '@angular/router';
import { AddAdminServiceService} from '../add-admin/add-admin-service.service';
import { ModalSuccessComponent } from '../common/admin-form/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-admin',
  templateUrl: './list-admin.component.html',
  styleUrls: ['./list-admin.component.css']
})
export class ListAdminComponent implements OnInit {
  tableHead = ['Admin', 'Bar', 'Job'];
  tableData: any = [];
  editMode = false;
  loading = false;
  currentEditUserId: string;
  @ViewChild('search', {static: false}) search;
  iconPath = '../assets/images/icons/frontpage/bar.png';
  constructor(private listAdminService: ListAdminService, private router: Router,
              private adminService: AddAdminServiceService, private modalService: NgbModal) {
    this.loading = true;
    this.listAdminService.getList('ADMIN').subscribe(admins => {
      this.tableData = admins;
      this.loading = false;
    });
  }

  ngOnInit() {
    // @ts-ignore
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  editModeFn = (user) => {
    this.currentEditUserId = user._id;
    this.editMode = true;
  }
  // add admin handler
  editAdmin = async (email: string, password: string, name: string, phone: string, job: string, id: string) => {
    this.editMode = true;
    this.loading = true;
    await this.adminService.addAdmin(email, password, name, phone, job, id).subscribe((res: object) => {
        this.listAdminService.getList('ADMIN').subscribe(admins => {
          this.tableData = admins;
        });
        this.editMode = false;
        this.loading = false;
        this.onAdminCreation(name);
      },
      (error) => {
        console.warn('error', error);
      });
  }
  // deletes selected admin
  deleteAdmin = (id, name) => {
    window.scrollTo(0, 0);
    this.editMode = false;
    this.loading = true;
    this.adminService.deleteAdmin(id).subscribe(() => {
      this.listAdminService.getList('ADMIN').subscribe(admins => {
        this.tableData = admins;
      });
      this.loading = false;
      this.onAdminCreation(name.value, false);
    },
      (error) => {
      console.warn('error', error);
    });
  }
  // sends the user to add admin form
  redirect = ({save}) => {
    if (save) {
      this.router.navigate(['createAdmin']);
    }
  }
  // search the admin
  searchList = () => {
    this.loading = true;
    this.listAdminService.searchList(this.search.value, 'ADMIN').subscribe(admins => {
      this.tableData = admins;
      this.loading = false;
    },
      (error) => console.log(error));
  }
  // show success modal on admin creation
  onAdminCreation(adminName, editMode= true) {
    const modalRef = this.modalService.open(ModalSuccessComponent, {centered: true});
    modalRef.componentInstance.adminName = adminName;
    modalRef.componentInstance.editMode = editMode;
    modalRef.result.then(() => (console.log('modal closed')
      ),
      () => this.router.navigate(['listAdmins']));
  }

}
