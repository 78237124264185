import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { httpOptions } from '../../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class CreateOrderService {
  url = environment.server_url;
  userID = localStorage.getItem('userID');

  constructor(private http: HttpClient) { }

  getAds(data) {
    return this.http.post(this.url + 'order/getAd', {userID: this.userID, ...data}, httpOptions);
  }
  generateAds(data) {
    return this.http.post(this.url + 'order/package', {userID: this.userID, ...data}, httpOptions);
  }
}
