import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocationService } from '../common/service/location.service';
import { GlobalService } from '../common/service/global.service';
import getDistanceFromLatLonInKm from '../utils/calculateDistance';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class SearchServiceService {
  url = `${environment.server_url}search/bar`;
  deviceId = localStorage.getItem('deviceID');
  mylocation = {latitude: 12.931906, longitude: 100.879656};
  constructor(private http: HttpClient, private locationService: LocationService, private globalService: GlobalService) {
  }
  // gets user current location
  getCurrentLocation() {
    this.locationService.getLocation().subscribe(position => {
      const distance = getDistanceFromLatLonInKm(
        position.coords.latitude, position.coords.longitude,
        this.mylocation.latitude, this.mylocation.longitude);
      if (distance > 25) {
        return;
      } else {
        this.mylocation = {
          ...this.mylocation,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude};
      }
    });
  }
  searchBar = ({
                 bar = '',
                 mylocation = this.mylocation,
                 barFlags = [],
                 area = 'ALL',
                 opentime = 'ALL',
                 deviceID = this.deviceId,
                 distance = 'ALL',
                 map = false,
                 type = 'ALL'} = {}) => {
    const body = {
      bar, deviceID, distance, area, mylocation, barFlags, opentime, type, map
    };
    return this.http.post(this.url, body, httpOptions);
}
}
