import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  constructor(private http: HttpClient) { }
  userID = localStorage.getItem('userID');
  url = environment.server_url;

  getStats(params = {}) {
    return this.http.post(this.url + 'admin/getStat', {...params, userID: this.userID });
  }

  getFunStats(params = {}) {
    return this.http.post(this.url + 'admin/getFunStat', {...params, userID: this.userID });
  }

  getGraphStat(params = {}) {
    return this.http.post(this.url + 'admin/getGraphStat', {...params, userID: this.userID });
  }
}
