import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from '../common/modal-popup/modal-popup.component';
import { Observable } from 'rxjs';

class BarFormUtils {
  constructor(private  modalService: NgbModal) {}
  // validates form data
  getKeyValPair = (item) => {
    const [key] = Object.keys(item);
    const [value] = Object.values(item);
    const formatedObject = {objectKey: key, objectValue: value};
    return formatedObject;
  }

  validBarForm = (data) => {
    const nullItem = data.find(item => this.getKeyValPair(item).objectValue == null ||
      // tslint:disable-next-line:triple-equals
      this.getKeyValPair(item).objectValue == undefined  ||
      // tslint:disable-next-line:triple-equals
      this.getKeyValPair(item).objectValue == '');
    if (nullItem) {
      const [key] = Object.keys(nullItem);
      return this.showModal(`${key} is invalid`, 'Validation  Error');
      }
    return true;
  }

  permissionsStatus() {
    return new Promise((resolve) => {
      navigator.permissions.query({name: 'geolocation'}).then(geoPermissionStatus => {
        navigator.permissions.query({name: 'notifications'}).then(notificationPermissionStatus => {
          resolve ({
            location: geoPermissionStatus.state,
            notification: notificationPermissionStatus.state
          });
        });
      });
    });
  }

  // opens the modal to show errors
  showModal(body, title) {
    const modalRef = this.modalService.open(ModalPopupComponent, {centered: true});
    modalRef.componentInstance.my_modal_title = `<h5>${title}</h5>`;
    modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <p class="text-center mb-0">${body}</p>
        </div>`;
    modalRef.componentInstance.my_modal_button1 = 'OK';
  }

  // search for match in array of strings
  filterBar(bars = [], param) {
    return Observable.create((observer) => {
      const matches = bars.filter((items: string) => items.toLowerCase().includes(param.toLowerCase()));
      observer.next(matches);
      observer.complete();
    })
  }
}

export default BarFormUtils;
