import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { UploadFileService } from './upload-file.service';
import { GlobalService } from '../common/service/global.service';
import { ModalPopupComponent } from '../common/modal-popup/modal-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {
    @Input() barName;
    @Input() label;
    @Input() adType;
    @Input() slot;
    @Output() photoEvent = new EventEmitter<string>();
    @Input() isMultiple: boolean;
    files: any = [];
    fileName = '';
    choosePhoto: Boolean = false; // choose photo area
    @Output() photoSelected = new EventEmitter<string>();
    @Output() shortPhoto = new EventEmitter<string>();
    @Output() longPhoto = new EventEmitter<string>();

    @ViewChild('fileInput', {static: false, read: ElementRef})
    fileInput: ElementRef;
    showDragDrop = true;
    isDesktopDevice: boolean;
    isAdmin: boolean;
    faTimes = faTimes; // cancel icon
    dimensionError: string;
    @ViewChild('fileInputRef', {static: false}) fileInputRef;

    constructor(private uploadFileService: UploadFileService, private globalService: GlobalService, private modalService: NgbModal,
                private deviceService: DeviceDetectorService) {

        // Listen when parent component tell us that save photo
        this.globalService.savePhoto.subscribe((data) => {
            if (data) {
              this.prepareUploadPhoto();
            }
        });

        this.isDesktopDevice = this.deviceService.isDesktop(); // returns if the app is running on a Desktop browser.
        if (!this.isDesktopDevice) {
          this.showDragDrop = false;
        }
        this.globalService.loggedInFlag.subscribe((loggedIn) => {
          if (loggedIn) {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
        }, () => {
          this.isAdmin = false;
        });
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.isMultiple) {
          if (this.fileInput) {
            this.fileInput.nativeElement.setAttribute('multiple', true);
          }
        }
    }

    //
    // choosePhotos
    //
    choosePhotos(fileInputRef: Element, fileInputRefMultiple: Element) {
      if (!this.isDesktopDevice) {
          if (this.isMultiple) {
            // @ts-ignore
            fileInputRefMultiple.click();
          } else {
            // @ts-ignore
            fileInputRef.click();
          }
      } else if (this.isMultiple && this.isDesktopDevice) {
          this.choosePhoto = true;
        } else {
          // @ts-ignore
          fileInputRef.click();
        }
    }

    //
    // openCamera
    //
    openCamera(evt) {
        console.log('camera');
        evt.preventDefault();
        evt.stopPropagation();
    }

    //
    // On file drop
    //
    onFileDrop(files) {

        // If this is multiple files
        if (this.isMultiple) {
            Object.keys(files).forEach((item, index) => {
                this.files.push(files[index]);
            });
        } else {
            // If this is single file
            this.files.length = 0;
            this.files.push(files[0]);
        }
        // Preview files
        this.previewFiles();
    }
    //
    // Change even listener of input type file
    //
    uploadFile(event, currentEvent?) {

        // If this is single file mode then reset it to back
        if (!this.isMultiple) {
            this.files.length = 0;
        }
        const newNumOfFiles = this.files.length + currentEvent.target.files.length;
        if (this.files.length > 0 && newNumOfFiles <= 10) { // if we have other selected photos
        Object.keys(currentEvent.target.files).forEach((item, index) => {
          this.files.push(currentEvent.target.files[index]);
        });
      } else if (this.files.length === 0 && currentEvent.target.files.length <= 10 ) { // if we have nothing selected
          Object.keys(currentEvent.target.files).forEach((item, index) => {
          this.files.push(currentEvent.target.files[index]);
        });
      } else if (this.files.length === 10) { // if we already have 10 files selected
          return this.showErrorModal('Validation Error', 'You cannot choose more than 10 photos');
        } else if (currentEvent.target.files.length > 10) { // if user selects more than 10 photos the first time
          return this.showErrorModal('Validation Error', 'You cannot choose more than 10 photos');
        }
        // Preview files
        this.previewFiles();
    }

  showErrorModal = (title, body) => {
    // opens the modal to show errors
    const modalRef = this.modalService.open(ModalPopupComponent, {centered: true});
    modalRef.componentInstance.my_modal_title = `<h5>${title}</h5>`;
    modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <p class="text-center mb-0">${body}</p>
        </div>`;
    modalRef.componentInstance.my_modal_button1 = 'OK';
  }

  validateShortLongImage(image, type= 'long') {
      return new Promise((resolve, reject) => {
        if (type === 'long') {
          if (image.width === 750 && image.height === 300) {
            resolve(true);
          } else {
            reject('image dimensions must be 750 X 300')
          }
        }
        if (type === 'short') {
          if (image.width === 340 && image.width === 340) {
            resolve(true);
          } else {
            reject('image dimensions must be 340 X 340')
          }
        }
      })
  }

    //
    // Preview files
    //
    previewFiles() {


        const _this = this;
        function readAndPreview(file) {
          if (_this.adType) {
            const img = new Image();
            img.src = window.URL.createObjectURL( file );
            img.onload = () => {
              window.URL.revokeObjectURL( img.src );
              console.log('width: ', img.width);
              console.log('Height: ', img.height);
              // validate image
              _this.validateShortLongImage(img, _this.adType).then(() => {
                _this.dimensionError = null;
                // image dimensions are correct
              }).catch(err => {
                // image dimensions are wrong
                _this.dimensionError = `This Photo is wrong size ${img.width} X ${img.height}`
              })
            };
          }

            // Make sure `file.name` matches our extensions criteria
          if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
                const reader = new FileReader();

                reader.addEventListener('load', () => {
                    const image = new Image();
                    image.height = 100;
                    image.title = file.name;
                    image.src = reader.result as string;
                    // preview.appendChild( image );
                    file.imagePath = reader.result as string;
                    file.constructor.prototype.nameInDB = _this.barName ? _this.barName.replace(' ', '_').toLowerCase() + Number(Math.floor(Math.random() * 2000)) + '-' + Number(Math.floor(Math.random() * 2000)) : Number(Math.floor(Math.random() * 2000)) + '-' + Number(Math.floor(Math.random() * 2000));

                    // tell consumer about file name
                    if (_this.adType === 'long') {
                      _this.longPhoto.emit(file);
                    }
                    if (_this.adType === 'short') {
                      _this.shortPhoto.emit(file);
                    } else {
                      _this.photoSelected.emit(file);
                    }
                }, false);

                reader.readAsDataURL(file);
            }

        }

        if (this.files && this.files) {
            [].forEach.call(this.files, readAndPreview);
        }
    }



    //
    // Remove file from list
    //
    removeFile(index) {
        this.files.splice(index, 1);
        if (this.adType === 'long') {
          this.dimensionError = null;
          this.longPhoto.emit(null);
          this.fileInputRef.nativeElement.value = '';
        }
        if (this.adType === 'short') {
          this.dimensionError = null;
          this.shortPhoto.emit(null);
          this.fileInputRef.nativeElement.value = '';
        }
    }

    //
    // Prepare image object based on the selected photos
    //
    createFormData = (photo, body) => {

        const formData: FormData = new FormData();

        // If image don't come under these extenstions then this is not image
        if (['jpg', 'png', 'jpeg'].indexOf(photo.type.replace(/^.*[\.\\/]/, '')) == -1) {
            return false;
        }
        const photoType = photo.type;
        // if (!photo.type) {
        //   photoType = ('image/' + photo.uri.substr(photo.uri.lastIndexOf(".") + 1))
        // }
        const photoName = photo.name;

        formData.set('avatar', photo, photo.name);


        Object.keys(body).forEach(key => {
            formData.append(key, body[key]);
        });

        return formData;
    }

    prepareUploadPhoto() {

        const deviceID = localStorage.getItem('deviceID');
        this.files.forEach((File, index) => {
            index = index + 1;
          // tslint:disable-next-line:max-line-length
            const fileName = this.isMultiple ? this.barName.replace(' ', '_').toLowerCase() + Number(Math.floor(Math.random() * 2000)) + '-' + Number(Math.floor(Math.random() * 2000)) :  File.constructor.prototype.nameInDB; ;
            this.fileName = fileName;
          // tslint:disable-next-line:max-line-length
          let slot = this.slot || 'BARPAGE';
            const formData = this.createFormData(File, {barName: this.barName, fileName, deviceID, slot: slot, adType: this.adType});
            // Upload image to node server
            this.UploadPhoto(formData);
        });
    }

    //
    // Upload photo to server
    // URL: 'photo/uploadPhoto'
    //
    UploadPhoto(formData) {
        this.uploadFileService.UploadImage(formData)
            .subscribe(result => {
                console.log(result);
                // if photo was successfully uploaded
                console.log('image uploaded successfully');
                this.files = [];
            },
                (err) => {
                    console.log(err);
                }
            );
    }

    // send photo to parent component
    sendPhoto(fileName) {
        this.photoEvent.emit(fileName);
    }

}
