import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

    // Login Page GET Data URL
    loginURL = `${environment.server_url}admin/login`;
  constructor(private http: HttpClient) { }

  isAuthenticated(inputData: Object) {

    // now returns an Observable of Config
    return this.http.post(this.loginURL, inputData, httpOptions);
}
}
