import { Component, Input, OnInit } from '@angular/core';
import { BarService } from '../bar.service';

@Component({
  selector: 'app-report-offense-form',
  templateUrl: './report-offense-form.component.html',
  styleUrls: ['./report-offense-form.component.css']
})
export class ReportOffenseFormComponent implements OnInit {

  @Input() inputData: any;
  @Input() value;
  @Input() name: string;
  @Input() id: string;
  constructor(private barService: BarService) { }

  ngOnInit() {}

  storeReport() {
    this.barService.storeReport(this.value);
  }

}
