/*
 *
 * @param: priceFactor
 * @return: <Object> with price color and number of icons to display
 *
*/

const setPriceWithColors = priceFactor => {
  const coloredPrice = [
    {veryCheap: '#009933'},
    {cheap: '#00cc44'},
    {normal: '#cccc00'},
    { expensive: '#ff9900'},
    {veryExpensive: '#cc0000'},
  ];
  if (priceFactor) {
    const icons = Array(Math.floor(priceFactor)).fill(1);
    const priceValue = coloredPrice[icons.length - 1];
    const priceIconColor = Object.values(priceValue);
    return {
      color: priceIconColor,
      icons,
    };
  } else  {
    return  {color: '', icons: []}
  }
};

export default setPriceWithColors;
