import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from '../common/modal-popup/modal-popup.component';
import getDistanceFromLatLonInKm from './calculateDistance';
import { GlobalService } from '../common/service/global.service';
import { LocationService } from '../common/service/location.service';

class BarUtils {
  constructor(private  modalService: NgbModal, private globalService: GlobalService,
              private locationService: LocationService) {}

  /*
   * Check for permissions
   */
  checkPermissionAccess(name) {
    return new Promise(resolve => {
      navigator.permissions.query({name}).then(permission => {
        if (name === 'geolocation' && permission.state === 'prompt') {
          resolve(true);
          // this.locationInfoModal().then(res => {
          //   this.modalService.dismissAll(ModalPopupComponent);
          //   resolve(true);
          // })
        } else if (name === 'notifications' && permission.state === 'prompt') {
          this.modalService.dismissAll(ModalPopupComponent);
          this.showNotificationInfoModal().then(res => {
            resolve(true)
          })
        } else {
          resolve({name, state: permission.state})
        }
      });
    })
  }

  // opens the modal to show information about sending notifications
  showNotificationInfoModal() {
    return new Promise(resolve => {
      const modalRef = this.modalService.open(ModalPopupComponent, {centered: true});
      modalRef.componentInstance.my_modal_title = `<h5>Happy hour and cheap drinks!!</h5>`;
      modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <p>
            The Guide would like to send you notifications when your favorite bars have Happy Hour, Special Party or Cheap Drinks.
          </p>
          <div class="d-flex justify-content-around">
            <img
              class="w-150 img-fluid float-left"
              src="../../assets/images/icons/frontpage/service_censored.png" alt="bar service image"
              >
            <img class="w-150 img-fluid float-right" src="../../assets/images/icons/frontpage/event.png" alt="bar event image">
           </div>
            </div>`;
      modalRef.componentInstance.my_modal_button2 = 'OK';
      modalRef.result.then(() => resolve(true), () => { console.log('Backdrop click') })
    })
  }

  //
// Teleported alert
//
  showTeleportedModal() {
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.my_modal_title = '<h5>Teleported Alert</h5>';
    modalRef.componentInstance.my_modal_content = `<div class="text-center">
                <img alt="teleported map" class="w-150 img-fluid" src="./../assets/images/icons/frontpage/map.png">
                <p class="text-center mb-0 font20"> You are too far from Pattaya to show <br> any of the <b>Bars Near Me</b><br><br></p>
                <p class="text-center font-weight-bold mb-10"> We have teleported you to Beach Road, <br>right in front of Central Festival,
                <br> have fun :)</p>
            </div>`;
    modalRef.componentInstance.my_modal_button2 = 'OK';
    modalRef.result.then(() => { console.log('When user closes'); }, () => { console.log('Backdrop click') })
  }

  locationInfoModal() {
    return new Promise(resolve => {
      const modalRef = this.modalService.open(ModalPopupComponent);
      modalRef.componentInstance.my_modal_title = '<h3>Which bars are near to you?</h3>';
      modalRef.componentInstance.my_modal_content = `<div class="text-center">
                  <p class="text-center mb-0 font20">The guide needs to know your location to calculate which bars are closest to you.</p>
                  <img class="w-150 img-fluid" src="./../assets/images/icons/frontpage/near.png" alt="near me bar's image">
              </div>`;
      modalRef.componentInstance.my_modal_button2 = 'OK';
      modalRef.result.then(() => resolve(true), () => { console.log('Backdrop click') })
    })
  }

  checkBarType(text, barname?, area?) {
    let newText = text;
    if (newText.includes('#BARTYPE#')) {
      newText = newText.replace(/#BARTYPE#/g, 'bar');
    }
    if (newText.includes('#BARTYPES#')) {
      newText = newText.replace(/#BARTYPES#/g, 'bars');
    }
    if (newText.includes('#BARNAME#')) {
      newText = newText.replace(/#BARNAME#/g, barname || '#BARNAME#');
    }
    if (newText.includes('#AREA#')) {
      newText = newText.replace(/#AREA#/g, (area || 'Pattaya'));
    }
    return newText;
  }

  /*
  Capitalize first letter of each word
   */
  titleCase(str) {
    return str.replace(/\w\S*/g, (txt) =>
      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

}

export default BarUtils;
