import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { ListOrdersService } from '../list-orders/list-orders.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import parseString from '../../utils/parseString';
import { SearchServiceService } from '../../search-bar/search-service.service';
import { CreateOrderService } from './create-order.service';
import { dateDiff, formatRuntime, getDatePct, stdUnixDate, updateStatus } from '../../utils/adsUtils';
import { GlobalService } from '../../common/service/global.service';
import BarFormUtils from '../../utils/BarFormUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.css']
})
export class CreateOrderComponent implements OnInit {
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  id: string;
  @Input() editMode = false;
  @Input() filename;
  @Input() text;
  barName: string;
  order: {
    package: string;
    createDate: string;
    modifyDate: string;
    status: string;
    bar: string;
    barContact: string;
    price: number;
    discount: number;
    _id: string;
    click: number;
    view: number;
    activeDate: number;
    viewCnt: number;
    viewPct: number;
    clickCnt: number;
    clickPct: number;
    endDatet: number;
    highPct: number;
    datePCt: number;
    viewPCt: number;
    clickPCt: number;
    datePct: number;
  };
  creator: string;
  modifier: string;
  searchFailed = false;
  searching = false;
  loadingComplete = false;
  ads: Array<object>;
  @Input() searchQuery: string;
  packages: Array<object>;
  packageList: Array<object>;


  currentOrderActionText: {
    positive: string;
    negative: string;
  };

  runtime: {
    years: string;
    months: string;
    days: string;
    hours: string;
  };

  isOrderSaved = false;
  showPackageForm = false;

  price: number;

  @ViewChild('priceRef', {static: false}) priceRef;
  @ViewChild('discount', {static: false}) discount;
  @ViewChild('package', {static: false}) package;
  @ViewChild('bar', {static: false}) bar;
  @ViewChild('barContact', {static: false}) barContact;

  @ViewChild('dateFromRef', {static: false}) dateFromRef;
  @ViewChild('dateToRef', {static: false}) dateToRef;

  @ViewChild('headline', {static: false}) headline;
  @ViewChild('link', {static: false}) link;
  orderStatus: string;

  selectedPhoto: File = null;
  selectedLongPhoto: File = null;
  selectedShortPhoto: File = null;
  dateTimePct: { leftPct: string; absRunPct: number; runPct: string };

  photoLong: string;
  photoShort: string;
  orderId: string;
  selectedPackage: string;
  BarFormUtils = new BarFormUtils(this.modalService);
  formatStatus = updateStatus;
  hasPackage: boolean;
  constructor(private route: ActivatedRoute, private orderService: ListOrdersService,
              private searchServiceService: SearchServiceService, private router: Router,
              private createOrderService: CreateOrderService, private globalService: GlobalService,
              private modalService: NgbModal, private location: Location,
              private appService: AppService) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    // this.getOrder();
    this.route.params.forEach(params => {
      if (!this.id || !this.ads) {
        this.orderService.getPackages({package: 'list'}).subscribe((res: Array<object>) => {
          this.packageList = res;
          this.packages = res.map((item: any) => {
            return { title: item._id, value: item._id }
          })
          // populate price field
          // @ts-ignore
          // this.priceRef.value = this.getPriceFromPackage().price;

          this.updateRunningTime();
        })
      }
      if (params) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.getOrder();
      }
    });
  }

  onDateChange() {
    const fromDate = stdUnixDate(this.dateFromRef.value);
    const to = stdUnixDate(this.dateToRef.value);
    const runningTime = dateDiff(new Date(fromDate * 1000), new Date(to * 1000));
    this.runtime = formatRuntime(runningTime);
  }

  updateRunningTime() {
    const today = stdUnixDate(this.today());
    const nextMonth = stdUnixDate(this.nextMonth());
    const runningTime = dateDiff(new Date(today * 1000), new Date(nextMonth * 1000));
    this.runtime = formatRuntime(runningTime);
  }

  isPhotoSelected(photo) {
    this.selectedPhoto = photo;
  }

  // get photo
  getPhoto($event) {
    this.filename = $event;
  }

  getLongPhoto($event) {
    this.selectedLongPhoto = $event;
  }

  getShortPhoto($event) {
    this.selectedShortPhoto = $event;
  }

  getOrder() {
    if (this.id) {
      this.editMode = true;
      return new Promise(resolve => {
        this.orderService.getOrder({_id: Number(this.id)}).subscribe((res: any) => {
          this.order = res.order;
          this.hasPackage = !!res.order.package; // will return the boolean of the value
          this.orderService.storeAdForm(res.adForm);
          // @ts-ignore
          this.orderStatus = updateStatus(this.order.status);
          // @ts-ignore
          this.positiveStatus(res.order.status);
          // @ts-ignore
          this.searchQuery = this.order.bar;
          this.creator = res.create;
          this.modifier = res.modify;
          resolve(res);
        });
      }).then(() => {
        this.createOrderService.getAds({order: this.id}).subscribe(({ad}: any) => {
          this.ads = ad;
          this.loadingComplete = true;
          this.orderService.storeOrderStatus({
            order: this.order,
            ads: this.ads
          })
        });
      })
    } else {
      this.editMode = false;
      this.loadingComplete = true;
    }
  }

  calculatePcts(ad) {
    const viewPct  = Math.round( (ad.viewCnt / ad.view) * 100 );
    const clickPct  = Math.round( (ad.clickCnt / ad.click) * 100 );
    const dateFrom = new Date(ad.from * 1000);
    const dateTo = new Date(ad.to * 1000);
    const today = new Date(Date.now());
    // @ts-ignore
    this.dateTimePct = getDatePct(dateTo, dateFrom, today);
    return  Math.max(this.dateTimePct.absRunPct, viewPct, clickPct);
  }

  // determine save button visibility
  isButtonVisible() {
    if (this.order) {
      return (this.order.status === 'WAITING' || this.order.status === 'PAID' || this.order.status === 'PAUSED');
    }
  }

  goTo(link, params = {}) {
    // @ts-ignore
    if (!params.price) delete params.price;
    this.router.navigate([link, {...params}]);
  }

  goToNewTab(link, params = {}) {
    const url = `${link};hasFullView=true;id=${this.id}`;
    return window.open(url);
  }

  getPriceFromPackage() {
    if (this.package && this.package.option.value) {
      return this.packageList.find((item: { _id: string }) => item._id === this.package.option.value);
    }
  }

  packageChange() {
    const selectedPackage = this.getPriceFromPackage();
    if (this.discount.value) {
      this.discount.value = 0;
    }
    // @ts-ignore
    this.priceRef.value = selectedPackage.price;
  }

  positiveStatus(status) {
    if (status === 'WAITING') {
      this.currentOrderActionText = { positive: 'PAID', negative: 'ABORT' };
    }
    if (status === 'RUNNING') {
      this.currentOrderActionText = {positive: 'PAUSE', negative: 'ABORT'};
    }
    if (status === 'PAID') {
      this.currentOrderActionText = {positive: 'RUN', negative: 'ABORT'};
    }
    if (status === 'ACTIVATED') {
      this.currentOrderActionText = {positive: 'PAUSE', negative: 'ABORT'};
    }
    if (status === 'PAUSED') {
      this.currentOrderActionText = {positive: 'RUN', negative: 'ABORT'};
    }
    if (status === 'ABORTED') {
      this.currentOrderActionText = {positive: 'RUN', negative: 'END'};
    }
    // if (status === 'STOPPED') {
    //   this.currentOrderActionText = {positive: 'ACTIVATE', negative: 'END'};
    // }
  }

  updateStatus(status, shouldRedirect= true) {
    this.orderStatus = updateStatus(status);
    if (status === 'ACTIVATE') {
      this.orderStatus = 'RUN';
    }
    // check whether the order has ads if user clicked paid
    if (this.editMode) {
      if (!this.ads) {
        // prompt user to add ads
        this.BarFormUtils.showModal('Please add ads', 'Error order save');
      } else {
        this.saveOrder(shouldRedirect);
        this.positiveStatus(status);
      }
    } else {
      this.saveOrder(shouldRedirect);
      this.positiveStatus(status);
    }
  }

  deleteOrder() {
    this.orderService.deleteOrder({ _id: this.id || this.orderId  }).subscribe(res => {
      this.goTo('listOrder');
    }, ({ error }) => {
      const err = error.errMsg.substring(error.errMsg.indexOf(':') + 1);
      this.BarFormUtils.showModal(err, 'Error order delete');
    });
  }

  cancelOrder() {
    this.goBack();
  }
  cancelAfterOrder() {
    if (this.isOrderSaved && !this.editMode) { // if order is saved, delete the order
      this.deleteOrder();
      this.isOrderSaved = false;
    }
  }

  goBack() {
    if (this.isOrderSaved && !this.editMode) { // if order is saved, delete the order
      this.deleteOrder();
    }
    this.router.navigate(['listOrder'])
  }

  goHome() {
    this.router.navigate(['home']);
  }

  formatDate(date) {
    const newDate = new Date(date * 1000).toDateString();
    return newDate.slice(4, newDate.length)
  }

  today() {
    return new Date().toISOString().substr(0, 10)
  }

  nextMonth() {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    return date.toISOString().substr(0, 10);
  }

  saveOrder(shouldRedirect = false) {
    this.barName = this.bar.value || this.searchQuery;
    const data = {
      _id: Number(this.id),
      bar: this.bar.value || this.searchQuery,
      barContact: this.barContact.value,
      package: this.package && this.package.option.value || null,
      discount: this.discount.value,
      price: this.priceRef.value,
      status: this.orderStatus
    };
    if (!this.editMode) { // remove id if editing the order
      delete data._id;
    }
    if (this.editMode){ // don't send the package while editing
      delete data.package;
    }
    this.orderService.persistOrder(data).subscribe((res: any) => {
      this.orderId = res._id;
      this.selectedPackage = res.package;
      if (this.editMode) {
        if (!shouldRedirect) {
          this.positiveStatus(res.status);
          this.getOrder()
          // this.order = {...this.order, ...res};
        } else {
          this.goTo('listOrder');
        }
      }
      if (!this.editMode && !this.package.option.value) {
        this.goTo('createOrder', {id: this.orderId});
      } else {
        this.isOrderSaved = true;
      }
    }, ({ error }) => {
      const err = error.errMsg.substring(error.errMsg.indexOf(':') + 1);
      this.BarFormUtils.showModal(err, 'Error order save');
    });
  }

  afterOrderSave() {
    // to generate ads

    if (!this.selectedLongPhoto || !this.selectedShortPhoto) {
      this.BarFormUtils.showModal('Please select the two photos', 'Error');
      return;
    }
    this.globalService.savePhoto.next(true);
    this.photoLong = this.selectedLongPhoto ? this.selectedLongPhoto.constructor.prototype.nameInDB : null;
    this.photoShort = this.selectedShortPhoto ? this.selectedShortPhoto.constructor.prototype.nameInDB : null;
    const data = {
      photoLong: this.photoLong,
      photoShort: this.photoShort,
      order: this.orderId,
      package: this.selectedPackage,
      headline: this.headline.value,
      text: this.text,
      from: stdUnixDate(this.dateFromRef.value),
      to: stdUnixDate(this.dateToRef.value),
      link: this.link.value
    }
    this.createOrderService.generateAds(data).subscribe(res => {
      this.isOrderSaved = false;
      this.goTo('createOrder', {id: this.orderId});
    })
  }

  // determine edibility
  isEditable() {
    if (this.order && this.editMode) {
      return (this.order.status === 'WAITING' || this.order.status === 'PAID' || this.order.status === 'PAUSED');
    } else {
      return true;
    }
  }

  // search
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => {
        if (term) {
          return this.BarFormUtils.filterBar(this.appService.getBarsList(), term).pipe(
            tap(() => this.searchFailed = false),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }));
        } else {
          return  of([]);
        }
      }),
      map((res: any) => {
        const items = [];
        res.forEach(item => {
          items.push(item);
        });
        if (items.length === 0) {
          return [];
        }
        if (items.length > 10) {
          return items.slice(0, 10);
        } else { return items; }
      }),
      tap(() => this.searching = false)
    )

}
