import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class BarService {

  url = `${environment.server_url}`;
  bar: object;
  private subject = new Subject<any>();
  private reportSubject = new Subject<any>();

  constructor(private http: HttpClient) { }

  getBarInfo(barName, adID = '') {
    const body = {
      bar : barName,
      _id : barName,
      adID,
      deviceID : localStorage.getItem('deviceID'),
      censored: false
    };
    if (!adID) {
      delete body.adID;
    }
    return this.http.post(this.url + 'bar/barPage', body, httpOptions);
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      window.navigator.geolocation.watchPosition(resp => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        err => {
        // for location denial teleport user to default location
        resolve({ lat: 12.931906, lng: 100.879656 });
        });
    });
  }

  // mark bar inactive or edit
  editBar(bar) {
    return this.http.post(`${this.url}bar/barEdit`, bar, httpOptions);
  }

  reactToComment(id, positive, negative) {
    const body = {
      _id : id,
      votesUp: positive,
      votesDown: negative,
      deviceID : localStorage.getItem('deviceID'),
    };
    return this.http.post(this.url + 'bar/barVote', body, httpOptions);
  }

  getComments(): Observable<any> {
    return this.subject.asObservable();
  }
  sendComments(comments: Array<object>) {
    return this.subject.next(comments);
  }
  storeReport(reason) {
    this.reportSubject.next(reason);
  }
  getReportReason(): Observable<any> {
    return this.reportSubject.asObservable();
  }
  reportMedia(data, url) {
    const body = {
      ...data
    };
    return this.http.post(this.url + url, body, httpOptions);
  }

}
