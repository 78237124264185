/*
 * Calculate distance using Haversine formula
 * See Haversine formula: https://www.movable-type.co.uk/scripts/latlong.html
 */

const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const Radius = 6371; // Radius of the earth in km
  const LatDifference = degreeToRadian(lat2 - lat1);  // degreeToRadian below
  const LonDifference = degreeToRadian(lon2 - lon1);
  const haversine =
    Math.sin(LatDifference / 2) * Math.sin(LatDifference / 2) +
    Math.cos(degreeToRadian(lat1)) * Math.cos(degreeToRadian(lat2)) *
    Math.sin(LonDifference / 2) * Math.sin(LonDifference / 2)
  ;
  const angularDistanceInRadians = 2 * Math.atan2(Math.sqrt(haversine), Math.sqrt(1 - haversine));
  const distance = Radius * angularDistanceInRadians; // Distance in km
  // if distance is < 1Km return with three decimal places
  if (distance < 1) {
    return parseFloat(distance.toFixed(3));
  }
  // if distance is > 1Km return with one decimal place
  return parseFloat(distance.toFixed(1));
};

function degreeToRadian(degree) {
  return degree * (Math.PI / 180);
}

export default getDistanceFromLatLonInKm;
