import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UUID } from 'angular2-uuid';

@Injectable()
export class DataInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const deviceID: string = localStorage.getItem('deviceID');
    if (!deviceID) {
      // If user's device id is not there in local storage then just find unique user id and assign to local storage
      localStorage.setItem('deviceID', UUID.UUID());
    }
    if (req.body) {
      req =  req.clone({
        body: {...req.body, ...{deviceID: localStorage.getItem('deviceID'), language: localStorage.getItem('locale')}}
      });
    }
    return next.handle(req);
  }
}
