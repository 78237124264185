import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['../admin-stats.component.css', './duration.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationComponent implements OnInit {

  @Input() data;
  activeTab = 'daily';
  html = '<table class="table table-borderless table-responsive">';

  constructor( private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.populate(this.data.daily);
  }

  populate(data) {
    for (const stat of data) {
      if (stat.type === 'TABLE-SECTION') {
        const tableSection = `
          <thead  style="background-color: black" class="text-left headers-text table-section">
            <th class="font-weight-bold smart-text" style="color: ${stat.color}">${stat.text}</th>
          </thead>`;
        this.html += tableSection;
      }

      if (stat.type === 'TABLE-HEAD-SMALL') {
        let tHead = '<thead>';
        let tRow = '<tr>';
        for (const [index, tableRow] of stat.text.entries()) {
          const tData = `
                        <th
                        style="color: ${stat.color}; textOverflow: 'unset'"
                        class="text-${stat.align[index]}">${tableRow}</th>
                     `;
          tRow += tData;
        }
        tHead += tRow;
        this.html += tHead;
      }
      // TABLE HEAD
      if (stat.type === 'HEAD') {
        this.html += `
              <thead style="background-color: black" class="text-left headers-text normal-head">
                <tr>
                    <th style="color: ${stat.color}">${stat.text}</th>
                </tr>
              </thead>
             `;
      }
      // TABLE LINE
      if (stat.type === 'TABLE-LINE') {
        let tBody = '<tbody>';
        let tRow = '<tr>';
        for (const [index, tableRow] of stat.text.entries()) {
          const tData = `
                        <td
                        style="color: stat.color, textOverflow: 'unset'"
                        class="smart-text text-${stat.align[index]}">${tableRow}</td>
                     `;
          tRow += tData;
        }
        tBody += tRow;
        this.html += tBody;
      }

      // TABLE SPACE
      if (stat.type === 'TABLE-SPACER') {
        this.html += `
        <thead>
        <tr>
        <td>
            <br>
        </td>
        </tr>
        </thead>
        `;
      }
    }
  }

  sanitizeHtml(dom) {
    return this.sanitizer.bypassSecurityTrustHtml(dom);
  }

  updateTab(tab) {
    this.activeTab = tab;
    this.html = '<table>';
    this.populate(this.data[this.activeTab]);
  }

}
