import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from '../../common/modal-popup/modal-popup.component';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { BarAddService } from '../../baradd/bar-add.service';
import { BarService } from '../../bar/bar.service';
import { GlobalService } from '../../common/service/global.service';

@Component({
  selector: 'app-confirm-bar-addition',
  templateUrl: './confirm-bar-addition.component.html',
  styleUrls: ['./confirm-bar-addition.component.css']
})
export class ConfirmBarAdditionComponent implements OnInit {
  thumbsUp = faThumbsUp;
  thumbsDown = faThumbsDown;
  barName: string;
  termsThai: Array<object>;
  termsEnglish: Array<object>;
  currentLang: Array<object>;
  topline: { topline1: string, topline2: string, topline3: string };
  constructor(private router: ActivatedRoute, private route: Router,
              private globalService: GlobalService, private modalService: NgbModal, private advanceBarService: BarAddService,
              private barService: BarService) {
    this.router.paramMap.subscribe((params: ParamMap) => {
      this.barName = params.get('barName');
      // @ts-ignore
      const {termsThai, termsEnglish} = globalService.GetGlobalConstants();
      this.termsThai = termsThai;
      this.termsEnglish = termsEnglish;
      const userLang = localStorage.getItem('userLang');
      if (localStorage.getItem('userLang')) {
        if (userLang === 'gb') {
          this.changeLanguage(this.termsEnglish, 'gb');
        }
        if (userLang === 'th') {
          this.changeLanguage(this.termsEnglish, 'th');
        }
      } else {
        this.changeLanguage(this.termsEnglish, 'gb');
      }
    });
  }

  changeLanguage(lang, userLang) {
    localStorage.setItem('userLang', userLang);
    this.currentLang = lang;
    // @ts-ignore
    this.topline = this.currentLang.find(items => items.hasOwnProperty('topline1'));
  }

  ngOnInit() {
    this.route.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  positiveResponse() {
    this.showModal('positive');
    this.route.navigate(['listbar']);
  }

  negativeResponse() {
    // mark inactive
    // @ts-ignore
    const data  = {
      status: 'DEACTIVATE',
      userID: localStorage.getItem('userID'),
      bar: {
        _id: this.barName,
        active: false,
        activeText: 'Bar owner choose NOT to list his bar with us'
      }
    };
    this.barService.editBar(data).subscribe(res => {
      this.showModal('negative');
      this.route.navigate(['listbar']);
    });
  }

  showModal(mode) {
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.my_modal_title = '<h4 class="text-center font-weight-bold mb-0">' +
      '<strong>CONGRATULATIONS!</strong>' +
      '</h4>';
    modalRef.componentInstance.my_modal_content = mode === 'positive' ? `<div class="text-center">
            <p class="text-center mb-2"><strong>${this.barName}</strong>  has been added to</p>
            <img class="img-fluid w-75" src="../../../assets/images/icons/frontpage/top-logo.png" alt="pattaya nightlife guide">
        </div>` :
      `<div class="text-center">
            <p class="text-center mb-0"><strong>${this.barName}</strong> has been removed</p>
        </div>`;
    modalRef.componentInstance.my_modal_button1 = 'OK';

    modalRef.result.then(() => { console.log('When user closes'); }, () => { console.log('Backdrop click'); });
  }

}
