import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class AddAdminServiceService {
  // default params
  url = `${environment.server_url}admin/save`;
  deviceID = localStorage.getItem('deviceID');
  userID = localStorage.getItem('userID');
  constructor(private http: HttpClient) { }
  // add admin observer
  addAdmin = (email, password, name, phone, job, id= '', simple?) => {
    let body;
    if (id === '') {
      body = {deviceID: this.deviceID, userID: this.userID, method: 'SAVE', email, password, name, phone, job};
    } else {
      body = {deviceID: this.deviceID, userID: this.userID, method: 'SAVE', email, password, name, phone, job, _id: id};
    }
    if (simple) {
      body = {deviceID: this.deviceID, userID: this.userID, method: 'ADDSTAFF', email, name, phone};
    }
    return this.http.post(this.url, body, httpOptions);
  }
  deleteAdmin = (id: string) => {
      const body = {deviceID: this.deviceID, userID: this.userID, method: 'DELETE', _id: id};
      return this.http.post(this.url, body, httpOptions);
  }
}
