import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import AdminUtils from '../../utils/adminUtils';
import { ListAdminService } from '../../list-admin/list-admin.service';
import { timeAgo} from '../../utils/checkLastLogin';

@Component({
  selector: 'app-admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.css']
})
export class AdminFormComponent implements OnInit {
  // default form param
  iconPath = '../assets/images/icons/frontpage/bar.png';
  // @ViewChild is used to access dom elements
  @ViewChild('email', {static: false}) email;
  @ViewChild('password', {static: false}) password;
  @ViewChild('name', {static: false}) name;
  @ViewChild('phone', {static: false}) phone;
  @ViewChild('job', {static: false}) job;
  @ViewChild('save', {static: false}) save;
  emailVal: string;
  nameVal: string;
  passwordVal: string;
  selectVal = 'ADMIN';
  phoneVal: string;
  loading = false;
  isValueNull: boolean;
  isCurrentUser: boolean;
  // variable that will be set from outside of form component
  @Input() created: string;
  @Input() lastLogin: string;
  @Input() addFun: any;
  @Input() deleteFun: any;
  @Input() id: string;
  // default job select option
  options = [
    {title: 'Administrator', value: 'ADMIN'},
    {title: 'Manager', value: 'MANAGER'},
    {title: 'Bar Owner', value: 'OWNER'},
    {title: 'Mamasan', value: 'MAMASAN'},
    {title: 'Other Job', value: 'OTHER'},
  ];
  // admin class with admin utils
  adminUtils = new AdminUtils(this.modalService);
  constructor(private modalService: NgbModal, private listAdminService: ListAdminService) { }
  ngOnInit() {
    // if the the form is in editMode we populate the form with current details
    if (this.id) {
      this.loading = true;
      this.listAdminService.getSingleItem(this.id).subscribe((res: any) => {
        const {createDate, email, job, name, password, phone } = res;
        // tslint:disable-next-line:radix
        const lastLogin =  new Date(parseInt(createDate) * 1000).toLocaleDateString() + ' - ' + timeAgo(parseInt(createDate)) + ' ago';
        this.emailVal = email;
        // tslint:disable-next-line:radix
        this.created = lastLogin;
        this.selectVal = job;
        this.phoneVal = phone;
        this.nameVal = name;
        this.passwordVal = password;
        // tslint:disable-next-line:radix
        this.lastLogin = new Date(parseInt(createDate) * 1000).toLocaleDateString();
        this.loading = false;
      });
    }
  }
  // click handler that is passed to custom button component to handle adding admin
  addAdmin = ({save}) => {
    if (!this.name.value) {
      this.isValueNull = true;
      setTimeout(() => this.isValueNull = false, 3000);
      return;
    }
    const isFormValid = this.adminUtils.validateStaff(this.email.value) && this.adminUtils.validateName(this.name.value);
    if (save && isFormValid) {
      this.addFun(this.email.value, this.password.value, this.name.value, this.phone.value, this.job.option.value, this.id);
    }
  }
  // click handler that is passed to custom button component to handle deletion of admin
  deleteAdmin = () => {
    this.deleteFun(this.id, this.name);
  }
}
