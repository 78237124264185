import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from '../common/modal-popup/modal-popup.component';
import { Subject } from 'rxjs';
import { GlobalService } from '../common/service/global.service';
import BarFormUtils from '../utils/BarFormUtils';
import { CreateAdvacedBarService } from './create-advaced-bar.service';
import transformTypes from '../utils/transform-types';
import MapUtils from '../utils/mapUtils';
import { LocationService } from '../common/service/location.service';
import { BarAddService } from '../baradd/bar-add.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import parseString from '../utils/parseString';
import { BarService } from '../bar/bar.service';
import getDistanceFromLatLonInKm from '../utils/calculateDistance';
import { environment } from '../../environments/environment';
import { AddAdminServiceService } from '../add-admin/add-admin-service.service';
import { ListAdminService } from '../list-admin/list-admin.service';
import AdminUtils from '../utils/adminUtils';
import { LeftService } from '../common/left/left.service';

@Component({
  selector: 'app-create-advanced-bar',
  templateUrl: './create-advanced-bar.component.html',
  styleUrls: ['./create-advanced-bar.component.css']
})
export class CreateAdvancedBarComponent implements OnInit {
  faTimes = faTimes; // cancel icon
  faCheck = faCheck; // check icon
  validate = new BarFormUtils(this.modalService);
  staffValidation = new AdminUtils(this.modalService);
  MapUtils  = new MapUtils(this.locationService);
  selectedPhotos: File = null;
  photos = [];
  deletedPhotos = [];
  loading = false;
  editMode = false;
  staffToEdit: string;
  // server url
  serverUrl = environment.IMAGE_URL;
  IMAGE_URL: string = environment.IMAGE_URL;
  // bar staffs options
  options = [
    {title: 'OWNER', value: 'OWNER'},
    {title: 'MANAGER', value: 'MANAGER'},
    {title: 'MAMASAN', value: 'MAMASAN'},
    {title: 'OTHER', value: 'OTHER'},
  ];
  barActiveStatusOptions = [
    {title: 'This bar is active', value: true},
    {title: 'This bar is inactive', value: false}
  ];
  barFlags;
  barTypes;
  areas; // areas to be populated on the map
  mapExpanded = false;
  locationSet = false;
  selectedBarFlags = [];
  title: string; // location title
  isValNull = false; // if user submits empty form mark this as true
  originalBar: string;

  @ViewChild('activeText', {static: false}) activeText;
  @ViewChild('barName', {static: false}) barName;
  @ViewChild('barType', {static: false}) barType;

  // bar staff details
  @ViewChild('email', {static: false}) email;
  @ViewChild('name', {static: false}) name;
  @ViewChild('phone', {static: false}) phone;
  @ViewChild('job', {static: false}) job;

  // opening and closing time
  @ViewChild('openTime', {static: false}) openTime;
  @ViewChild('closeTime', {static: false}) closeTime;

  // worker in the bar
  @ViewChild('minWorkers', {static: false}) minWorkers;
  @ViewChild('maxWorkers', {static: false}) maxWorkers;

  // lady drink
  @ViewChild('normalDrink', {static: false}) normalDrink;
  @ViewChild('largeDrink', {static: false}) largeDrink;
  @ViewChild('shotDrink', {static: false}) shotDrink;

  // bar fine
  @ViewChild('barFineLadyEarly', {static: false}) barFineLadyEarly;
  @ViewChild('barFineLadyLate', {static: false}) barFineLadyLate;
  @ViewChild('barFineModelEarly', {static: false}) barFineModelEarly;
  @ViewChild('barFineModelLate', {static: false}) barFineModelLate;
  @ViewChild('specialRules', {static: false}) specialRules;

  // happyHour
  @ViewChild('happyHourFrom', {static: false}) happyHourFrom;
  @ViewChild('happyHourTo', {static: false}) happyHourTo;
  @ViewChild('happyHourText', {static: false}) happyHourText;

  // drink prices
  @ViewChild('soda', {static: false}) soda;
  @ViewChild('localBeer', {static: false}) localBeer;
  @ViewChild('importedBeer', {static: false}) importedBeer;
  @ViewChild('localDrink', {static: false}) localDrink;
  @ViewChild('importDrink', {static: false}) importDrink;
  @ViewChild('drinkPriceShot', {static: false}) drinkPriceShot;

  // social media
  @ViewChild('webPage', {static: false}) webPage;
  @ViewChild('facebookPage', {static: false}) facebookPage;
  @ViewChild('instagram', {static: false}) instagram;
  @ViewChild('barActiveStatus', {static: false}) barActiveStatus;

  iconPath = '../assets/images/icons/frontpage/bar.png';

  @Input() lastLogin: string;
  @Input() created: string;

  staffArray = []; // array of bar staffs
  newBar: string; // a newly added bar

    closeResult: string;

    // EDIT MODE:
    location = {
      coords: undefined
    }; // if editing this will be populated

  // tslint:disable-next-line:max-line-length
    constructor(private router: Router, private modalService: NgbModal, private globalService: GlobalService, private createAdvacedBarService: CreateAdvacedBarService, private locationService: LocationService,
                private addBarService: BarAddService, private route: ActivatedRoute,
                private barService: BarService, private addAdminService: AddAdminServiceService,
                private listAdmins: ListAdminService, private leftSideService: LeftService,
                ) {
      this.getLeftSide();
      const data = this.globalService.GetGlobalConstants();
      this.barFlags = data.barFlags.map(flag => flag.id);
      this.barTypes = transformTypes(data.type, 'bar');
      this.areas = this.MapUtils.refactorCoords(data.areas);
      this.globalService.savePhoto.next(false);
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.barName = params.get('id');
        // get bar info
        if (params.get('id')) {
          this.editMode = true;
          this.loading = true;
          this.getBarInfo();
          this.loading = false;
        }
      });
     }

    ngOnInit() {
    }
    getLeftSide() {
      const {adminMenuLeft} = this.globalService.GetGlobalConstants();
      // this.leftSideService.getLeftSide({admin: true});
      // this.leftSideService.sendLeftSide();
    }
    viewNewBar(barName) {
      this.router.navigate(['bar', barName, { newBar: true }]);
  }

  formatTime = (value) => {
      if (value) {
      const stringfiedTime = String(value);
      if (stringfiedTime.length === 3) {
        return '0' + stringfiedTime.replace(':', '');
      } else { return stringfiedTime.replace(':', ''); }
    }
  }

  toTimeFormat = (time) => {
    const currentTime = Number(time);
    if (currentTime === 0) {
      return '00:00';
    }
    if (currentTime > 24 && currentTime < 100) {
      return;
    }
    if (currentTime < 24 && currentTime >= 10) {
      return currentTime + ':00';
    }
    if (currentTime < 10 && currentTime > 0) {
      return '0' + currentTime + ':00';
    }
    if (currentTime < 100 || currentTime > 2359 || isNaN(Number(time))) {
        return;
      }
    if (currentTime) {
      const stringfiedTime = String(time);
      if (stringfiedTime.length === 3) {
        return '0' + stringfiedTime.slice(0, 1) + ':' + stringfiedTime.slice(1);
      } else { return stringfiedTime.slice(0, 2) + ':' + stringfiedTime.slice(2); }
    }
  }
    onFocusOut(field) {
      const key = Object.keys(field)[0];
      const value = Object.values(field)[0];
      if (key === 'closeTime') {
        this.closeTime.value = this.toTimeFormat(value);
      }
      if (key === 'openTime') {
        this.openTime.value = this.toTimeFormat(value);
      }
      if (key === 'happyHourFrom') {
        this.happyHourFrom.value = this.toTimeFormat(value);
      }
      if (key === 'happyHourTo') {
        this.happyHourTo.value = this.toTimeFormat(value);
      }
    }

  onFocus(field) {
    const key = Object.keys(field)[0];
    const value = Object.values(field)[0];
    if (key === 'closeTime') {
      this.closeTime.value = this.formatTime(value);
    }
    if (key === 'openTime') {
      this.openTime.value = this.formatTime(value);
    }
    if (key === 'happyHourFrom') {
      this.happyHourFrom.value = this.formatTime(value);
    }
    if (key === 'happyHourTo') {
      this.happyHourTo.value = this.formatTime(value);
    }
  }
  getBarInfo(): void {
    this.barService.getBarInfo(this.barName).subscribe((barInfo: any) => {
      const formatTime = (time) => {
        if (time === 0) {
          return '0000';
        }
        if (time) {
          const stringfiedTime = String(time);
          if (stringfiedTime.length === 3) {
            return '0' + stringfiedTime.slice(0, 1) + stringfiedTime.slice(1);
          } else { return stringfiedTime.slice(0, 2) + stringfiedTime.slice(2); }
        }
      };
      const populateDrinks = (drinksLadies) => {
        drinksLadies.find((drink) => {
          if (drink.drink === 'Local Beer') {
            this.localBeer.value = drink.price;
          }
          if (drink.drink === 'Soda') {
            this.soda.value = drink.price;
          }
          if (drink.drink === 'Import Beer') {
            this.importedBeer.value = drink.price;
          }
          if (drink.drink === 'Local Drink') {
            this.localDrink.value = drink.price;
          }
          if (drink.drink === 'Import Drink') {
            this.importDrink.value = drink.price;
          }
          if (drink.drink === 'Shot') {
            this.drinkPriceShot.value = drink.price;
          }
        });
      };

      const populateBarfine = (barfineGirls) => {
        barfineGirls.find((lady) => {
          if (lady.lady === 'Normal' && lady.time === 'Before midnight' && lady.price) {
            this.barFineLadyEarly.value = lady.price;
          }
          if (lady.lady === 'Normal' && lady.time === 'After midnight' && lady.price) {
            this.barFineLadyLate.value = lady.price;
          }
          if (lady.lady === 'Model' && lady.time === 'Before midnight' && lady.price) {
            this.barFineModelEarly.value = lady.price;
          }
          if (lady.lady === 'Model' && lady.time === 'After midnight' && lady.price) {
            this.barFineModelLate.value = lady.price;
          }
          if (lady.special) {
            this.specialRules.value = lady.special;
          }
        });
      };

      const populateLadyDrinks = (ladyDrink) => {
        ladyDrink.find(drink => {
          if (drink.drink === 'Normal') {
            this.normalDrink.value = drink.price;
          }
          if (drink.drink === 'Large') {
            this.largeDrink.value = drink.price;
          }
          if (drink.drink === 'Shot') {
            this.shotDrink.value = drink.price;
          }
        });
      };

      const populateStaffs = (staffs) => {
        const staffIds = [];
        staffs.forEach((staff) => {
          if (staff && staff.id) {
            staffIds.push(staff.id);
          }
        });
        this.listAdmins.getStaffs(staffIds).subscribe((res: any) => {
          this.staffArray = res;
        });
      };
      const bar = barInfo.bar;
      this.photos = barInfo.photos;
      populateStaffs(bar.staffArray);
      const drinks = bar.drinks;
      populateDrinks(drinks);
      const barfine = bar.barfine;
      populateBarfine(barfine);
      const ladyDrinks = bar.ladyDrinks;
      populateLadyDrinks(ladyDrinks);
      this.barName.value = bar._id;
      this.originalBar = bar._id;
      this.activeText.value = bar.activeText;
      this.selectedBarFlags = bar.barFlags.map(flag => flag.id);
      this.minWorkers.value = bar.fromGirls;
      this.maxWorkers.value = bar.toGirls;
      this.happyHourFrom.value = bar.happyHour && formatTime(bar.happyHour.from);
      this.happyHourTo.value = bar.happyHour && formatTime(bar.happyHour.to);
      this.happyHourText.value = bar.happyHour && bar.happyHour.text;
      this.barType.option.value = bar.type;

      // open and close time
      this.openTime.value = this.toTimeFormat(bar.openTime);
      this.closeTime.value = this.toTimeFormat(bar.closeTime);

      // location
      if (bar.area) {
        this.locationSet = true;
        this.title = bar.area;
        this.location = barInfo.location;
        this.addBarService.setLocationCoords(this.location.coords, this.title);
      }
      // social media
      this.webPage.value = bar.webpage;
      this.facebookPage.value = bar.facebook;
      this.instagram.value = bar.instagram;
      this.barActiveStatus.option.value = bar.active;
      this.loading = false;
    }, (error) => {
      const modalRef = this.modalService.open(ModalPopupComponent);
      modalRef.componentInstance.my_modal_title = '<h4 class="text-center">Bar Not Found</h4>';
      modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <p class="text-center font-weight-bold mb-0">This bar does not exist:</p>
            <p>${this.barName.value}</p>
        </div>`;
      modalRef.componentInstance.my_modal_button1 = 'OK';
      modalRef.result.then(() => this.router.navigate(['home']), () => this.router.navigate(['home']) );
    });
  }

    //
    // File select event
    //
    onFileSelect(event) {
      console.log(event.target.files);
      if (event.target.files.length > 0) {
            const file = event.target.files[0];

            const formData = new FormData();
            // this.uploadForm.get('profile').setValue(file);
        }
    }

    //
    // Check if user has selected the photo from file explore or drag and drop
    //
    isPhotoSelected(photo) {
      this.selectedPhotos = photo;
    }

    //
    // Open popup
    //

  goToList() {
      this.router.navigate(['listbar']);
   }
    open() {
        const modalRef = this.modalService.open(ModalPopupComponent);
        modalRef.componentInstance.my_modal_title = '<h5>Ratings</h5>';
        modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <p class="text-center mb-0">Your rating of</p>
            <p class="text-center font-weight-bold mb-0">James and Torben</p>
            <p class="text-center mb-10">has been saved</p>
            <h6 class="text-green">You gained 10 Nightlife Points</h6>
        </div>`;
        modalRef.componentInstance.my_modal_button1 = 'OK';
        modalRef.componentInstance.my_modal_button2 = 'Cancel';

        modalRef.result.then(() => { console.log('When user closes'); }, () => { console.log('Backdrop click'); });
      }

  // click handler that is passed to custom button component to handle adding admin
  addBar = () => {
      const openTime =  Number(this.formatTime(this.openTime.value));
      const closeTime = Number(this.formatTime(this.closeTime.value));
      const happyHourFrom = this.happyHourFrom.value;
      const happyHourTo = this.happyHourTo.value;
      const drinksObject = (drink, price) => ({drink, price: Number(price) || null});
      const barFineObject = (lady, time, price) => ({lady, time, price: Number(price) || null});
      const staffsArray = (staffs) => {
        const availableStaffs = [];
        staffs.map(staff => {
          availableStaffs.push(staff._id);
        });
        return availableStaffs;
      };
      const drinks = [
        drinksObject('Soda', this.soda.value), drinksObject('Local Beer', this.localBeer.value),
        drinksObject('Import Beer', this.importedBeer.value), drinksObject('Local Drink', this.localDrink.value),
        drinksObject('Import Drink', this.importDrink.value), drinksObject('Shot', this.drinkPriceShot.value),
        ];
      const ladyDrinks = [
        drinksObject('Normal', this.normalDrink.value),
        drinksObject('Large', this.largeDrink.value),
        drinksObject('Shot', this.shotDrink.value),
      ];
      const barfine = [
        barFineObject('Normal', 'Before midnight', this.barFineLadyEarly.value),
        barFineObject('Normal', 'After midnight', this.barFineLadyLate.value),
        barFineObject('Model', 'Before midnight', this.barFineModelEarly.value),
        barFineObject('Model', 'After midnight', this.barFineModelLate.value),
        { special: this.specialRules.value || 'No rules' }
      ];
      const location = {
        _id: this.barName.value && parseString(this.barName.value),
        type: this.barType.option.value,
        area: this.title,
        color: '',
        coords: this.addBarService.getLocationCoords()
      };
      const admin = {};
      const bar = {
        barFlags: this.selectedBarFlags,
        activeText: this.activeText.value,
        _id: this.barName.value,
        originalBar: this.editMode ? this.originalBar : '',
        type: this.barType.option.value,
        area: this.title,
        openTime,
        closeTime,
        shortText: this.happyHourText.value || '',
        active: String(this.barActiveStatus.option.value),
        webpage: this.webPage.value || '',
        facebook: this.facebookPage.value || '',
        instagram: this.instagram.value || '',
        fromGirls: Number(this.minWorkers.value), // required
        toGirls: Number(this.maxWorkers.value) || Number(this.minWorkers.value),
        drinks, // required
        ladyDrinks,
        barfine,
        staffArray: staffsArray(this.staffArray),
        happyHour: {
          from: happyHourFrom,
          to: happyHourTo,
          text: this.happyHourText.value || ''
        },
      };
      // check invalid bar name
      if (!this.barName.value || this.barName.value.length <= 2) {
        this.isValNull = true;
        this.validationModal(`Validation Error`, 'Bar name should have more than two characters');
        // scroll to the top of the page
        window.scrollTo(0, 0);
        return;
      }
      const isFormValid = this.validate.validBarForm([
        {'Bar name': this.barName.value},
        {'Open time': this.openTime.value},
        {'Close time': this.closeTime.value},
        {Location: this.title},
        {'Minimum number of Girls': this.minWorkers.value},
        {Soda: this.soda.value},
        {'local Beer': this.localBeer.value},
        {'Imported Beer': this.importedBeer.value},
        {'local Drink': this.localDrink.value},
        {'Imported Drink': this.importDrink.value},
        {Shot: this.drinkPriceShot.value},
      ]);
      // mark required fields red if empty
      if (!isFormValid) {
        this.isValNull = true;
      }
      if (isFormValid) {
        // check drinks validity
        if (drinks.length) {
          let isDrinksValid = true;
          drinks.forEach(drink => {
            if (drink.price < 10 || drink.price >= 1000) {
              isDrinksValid = false;
              return this.validationModal('Validation Error', 'Drinks must range from 10-999');
            }
          });
          if (!isDrinksValid) {
            return;
          }
        }

        if (ladyDrinks.length) {
          let isDrinksValid = true;
          ladyDrinks.forEach(drink => {
            if ((drink.price > 0 && drink.price < 10) || drink.price >= 1000) {
              isDrinksValid = false;
              return this.validationModal('Validation Error', 'Drinks must range from 10-999');
            }
          });
          if (!isDrinksValid) {
            return;
          }
        }

        if (barfine.length) {
          let barfineValid = true;
          barfine.forEach((lady: any) => {
            if ((lady.price > 0 && lady.price < 100) || lady.price >= 10000) {
              barfineValid = false;
              return this.validationModal('Validation Error', 'Drinks must range from 100-9999');
            }
          });
          if (!barfineValid) {
            return;
          }
        }

        this.globalService.savePhoto.next(true);
        this.addBarService.saveBar(bar, this.location || location, admin, 'ADVANCED', this.deletedPhotos).subscribe((res: any) => {
        if (res) {
          this.openModal({
            header: this.editMode ? 'Bar Edited' : 'Bar Created',
            message: `${this.barName.value}  was ${this.editMode ? 'edited' : 'created'}`,
            successMessage: `successfully!`,
            user: res.user});
        }
        }, ({error}) => {
          const { errMsg } = error;
          const err = error.errMsg.substring(errMsg.indexOf(':') + 1);
          this.validationModal('Error barEdit', `${err}`);
        });
      }
  }

  validationModal = (title, body) => {
    // opens the modal to show errors
      const modalRef = this.modalService.open(ModalPopupComponent, {centered: true});
      modalRef.componentInstance.my_modal_title = `<h5>${title}</h5>`;
      modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <p class="text-center mb-0">${body}</p>
        </div>`;
      modalRef.componentInstance.my_modal_button1 = 'OK';
    }

  storeBarStaff = () => {
      const resetFields = () =>  {
        this.email.value = '';
        this.phone.value = '';
        this.name.value = '';
        this.job.option.value = 'OWNER';
    };
      const barStaff = {
        email: this.email.value,
        phone: this.phone.value,
        name: this.name.value,
        job: this.job.option.value,
      };
      // if editing
      const id = this.staffToEdit;
      // validate staff
      const isFormValid = this.staffValidation.validateStaff(this.email.value);
      if (!isFormValid) {
        return;
      }
      // check for duplication
      const isEditingIndex = this.staffArray.findIndex(staff => staff._id === id);
      if (!this.name.value) {
        this.openModal({ error: 'Please input the staff\'s name' });
      } else if (isEditingIndex !== -1) {
        // update details
        this.addAdminService.addAdmin(barStaff.email, '', barStaff.name, barStaff.phone, barStaff.job, id).subscribe(res => {
          this.staffArray[isEditingIndex] = res;
          this.staffToEdit = '';
          resetFields();
        });
        // this.openModal({ error: 'You already added a user with that email' });
      } else {
        // persist staff
        this.addAdminService.addAdmin(barStaff.email, '', barStaff.name, barStaff.phone, barStaff.job).subscribe(res => {
          this.staffArray.push(res);
          resetFields();
        });
      }
  }

  removeSelectedImage = (photo, index) => {
      this.deletedPhotos.push(photo);
      this.photos.splice(index, 1);
  }

  removeStoredStaff(index, id) {
    this.staffArray.splice(index, 1);
    // delete persisted data
    this.addAdminService.deleteAdmin(id).subscribe(res => {
    });
  }

  resetStaffForm = () => {
      this.email.value = '';
      this.phone.value = '';
      this.name.value = '';
      this.job.option.value = 'OWNER';
  }

  populateStaff = (id) => {
      this.staffToEdit = id;
      const staffToEdit = this.staffArray.find(staff => staff._id === id);
      this.email.value = staffToEdit.email;
      this.phone.value = staffToEdit.phone;
      this.job.option.value = staffToEdit.job;
      this.name.value = staffToEdit.name;
  }

  openMap() {
      this.mapExpanded = true;
  }

  // modal
  openModal({header= '', message = '', error = '', successMessage= '', user = { rankUp: false }}) {
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.my_modal_title = `${header}`;
    modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <h5>${(error && `<h3 class="text-danger"><strong>ERROR</strong></h3>`)}</h5>
            <p class="text-center font-weight-bold mb-0">${message} <span class="text-success">${successMessage}</span></p>
            <p class="text-center mb-10">${error}</p>
        </div>`;
    if (!error) {
      modalRef.componentInstance.my_modal_button1 = 'SHOW BAR';
    }
    modalRef.componentInstance.my_modal_button2 = 'OK';
    modalRef.result.then(() => { if (!error) {
      this.router.navigate(['listbar']);
      if (user.rankUp) {
        this.router.navigate(['rank']);
      }
    } }, () => {
      if (!error) {
        if (!this.editMode) {
          this.gotToNewBar();
          return;
        }
        this.router.navigate(['bar', this.barName.value]);
      }
    });
  }

  gotToNewBar() {
      this.viewNewBar(this.barName.value);
  }

  // set location by selected area coordinates
  setLocation() {
    this.mapExpanded = !this.mapExpanded;
    // if in edit mode reset loaded location
    this.location = null;
    const isCoordsSet = Object.entries(this.addBarService.getLocationCoords()).length;
    if (isCoordsSet === 0) {
      this.addBarService.setLocationCoords({
        longitude: 100.879656,
        latitude: 12.931906
      }, 'Pattaya');
    }
    this.locationSet = true;
    this.title = this.addBarService.getBarArea();
  }

  updateBarFlags(flag, index) {
    // if deselecting
    const flagIndex = this.selectedBarFlags.indexOf(flag);
    if (flagIndex !== -1) {
    //  remove flag from selected items
      this.selectedBarFlags.splice(flagIndex, 1);
    } else {
      this.selectedBarFlags.push(flag);
    }
  }

      //
      // Sumbmit photo
      //
      submitPhoto() {
        this.globalService.savePhoto.next(true);
      }

  resetStats() {
      const data = {
        status: 'RESET',
        userID: localStorage.getItem('userID'),
        deviceID: localStorage.getItem('deviceID'),
        bar: {
          _id: this.barName.value
        }
      };
      this.createAdvacedBarService.resetStats(data).subscribe(res => {
        this.router.navigate(['listbar']);
      })
  }

}
