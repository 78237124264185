import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bar-details',
  templateUrl: './bar-details.component.html',
  styleUrls: ['./bar-details.component.css']
})
export class BarDetailsComponent implements OnInit {
  @Input() clickedBar: any;
  @Input() openBarDetails: boolean;
  @Output() closeDetailsEvent = new EventEmitter();
  constructor(private router: Router) { }

  ngOnInit() {
  }
  redirect = (bar) => this.router.navigate(['./bar', bar]);
  closeDetails = () => this.closeDetailsEvent.emit();
}
