import { Component, Input, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import * as $ from 'jquery';
import { Bar } from '../models/Bar';
import { BarService } from './bar.service';
import { environment } from '../../environments/environment';
import { Lightbox, LIGHTBOX_EVENT, LightboxEvent } from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { faArrowAltCircleLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { Location } from '@angular/common';
import getDistanceFromLatLonInKm from '../utils/calculateDistance';
import setPriceWithColors from '../utils/setBarPrice';
import getIconImage from '../utils/getIconImage';
import { ModalPopupComponent } from '../common/modal-popup/modal-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app.service';
import { LocationService } from '../common/service/location.service';
import { PushNotificationService } from '../shared/push-notification.service';
import BarUtils from '../utils/barUtils';
import { GlobalService } from '../common/service/global.service';
import FetchGlobalConstants from '../utils/global';
import { RightService } from '../common/right/right.service';
import { LeftService } from '../common/left/left.service';
import { RankService } from '../rank-system/rank.service';
import { TranslateService } from '@ngx-translate/core';
import changeAllWordsCase from '../utils/changeAllWordsCase';
import {userRankUp} from '../utils/rankUtils';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.css'],
  providers: [BarService, NgbModal]
})
export class BarComponent implements OnInit, AfterViewInit {
  barUtils = new BarUtils(this.modalService, this.globalService, this.locationService);
  getIconImage = getIconImage;
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  @Input() index: number; // gallery index
  bar: Bar;
  barName: string;
  photos: Array<string>;
  serverURL: string = environment.server_url;
  IMAGE_URL: string = environment.IMAGE_URL;
  distanceFromBar: string;
  barLocation: any;
  event: Bar;
  private album: { src: string; thumb: string }[] = [];
  drinks: Array<object>;
  isGalleryModalOpen = false;

  // keep track of response after reporting
  reportResponse: string;

  // font-awesome icon
  alert = faExclamationTriangle;
  // Prices
  beer = {
    import: undefined,
    local: undefined
  };
  drinkPrices = {
    local: undefined,
    import: undefined
  };
  shots = {
    shot: undefined,
    soda: undefined,
    water: undefined,
  };

  // Ladies
  barfine: Array<object>;
  barfineNormal = {
    bm: undefined,
    am: undefined,
  };
  barfineModel = {
    bm: undefined,
    am: undefined,
  };
  barfineSpecial = null;
  // lady drink
  lady = {
    Normal: '',
    Shot: '',
    Large: false
  };
  ladyDrinks: Array<object>;

  // ratings
  ratings: any;
  showRatings = false;
  // comments
  comments: Array<object>;

  // priceFator
  priceFactor: number;

  // price with icons and colors
  price: {
    icons
  };

  // rateIcon
  rateIcon = '';
  icon = '';
  toplist = '';

  // will be true if admin has just created the bar
  newBar = 'false';

  // bar info
  barFlagTxt: Array<object>;

  // bar position
  position = {
    resultPos: undefined,
    resultTot: undefined,
    numtext: undefined,
    tip: '',
  };
  // keep track of the current gallery index
  currentGalleryIndex = 0;
  reason: string; // this is the reason why user is reporting image
  adID: string;

  hasShow: boolean;

  private gallerySubscription: Subscription;
  private subscription: Subscription;
  globalUtil = new FetchGlobalConstants(this.globalService, this.appService,
    this.leftSideService, this.rankService, this.translate, this.modalService
  );
  constructor(
    private barService: BarService,
    private lightbox: Lightbox,
    private router: ActivatedRoute,
    private location: Location,
    private route: Router,
    private modalService: NgbModal,
    private appService: AppService,
    private locationService: LocationService,
    private lightboxEvent: LightboxEvent,
    private cdr: ChangeDetectorRef,
    private pushNotification: PushNotificationService,
    private globalService: GlobalService,
    private leftSideService: LeftService,
    private rankService: RankService,
    private translate: TranslateService,
  ) {
    this.gallerySubscription = this.lightboxEvent.lightboxEvent$.subscribe(event => this._onReceivedEvent(event));
    this.subscription = this.barService.getReportReason().subscribe(res => {
      this.reason = res;
    });
    this.barName = this.withoutHyphen(this.router.snapshot.paramMap.get('barName'));
    this.router.paramMap.subscribe((params: ParamMap) => {
      this.rateIcon = params.get('rateIcon');
      this.toplist = params.get('toplist');
      this.newBar = params.get('newBar');
      this.adID = this.appService.getCurrentAdId();
    });
  }

  ngOnInit() {
    this.router.params.forEach(params => {
      if (params) {
        this.barName = this.withoutHyphen(this.router.snapshot.paramMap.get('barName'));
        this.router.queryParams.subscribe((parameters) => {
          this.rateIcon = parameters.rateIcon;
          this.toplist = parameters.toplist;
        });
        this.getBarInfo();
        // scroll to top of page
        this.route.events.subscribe((event: any) => {
          if (!this.appService.shouldScrollToComments()) {
            window.scrollTo(0, 0);
            if (!(event instanceof NavigationEnd)) {
              return;
            }
            window.scrollTo(0, 0);
          }
        });
      }
    });
    // get barFlagTxt for Bar info section from global service
    // @ts-ignore
    const { barFlags } = this.globalService.GetGlobalConstants();
    this.barFlagTxt = barFlags;
  }

  toString(value) {
    return String(value);
  }

  requestPermissions(): Promise<any> {
    return new Promise((resolve) => {
      this.barUtils.checkPermissionAccess('notifications').then(res => {
        this.pushNotification.receiveMessage()
          .subscribe((message) => {
            resolve(message);
          });
        this.pushNotification.requestPermission()
          .subscribe((requestPermission) => {
            resolve(requestPermission);
          });
      });
    });
  }

  ngAfterViewInit(): void {
    if (this.appService.shouldScrollToComments()) {
      setTimeout(() =>  {
        this.appService.shouldNotScroll();
        window.scrollTo(0, 3200);
      }, 2000);
    } else {
      this.route.events.subscribe((event: any) => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
    }
  }

  withoutHyphen(str) {
    return str.replace(/-/g, ' ');
  }

  private _onReceivedEvent(event: any): void {
    // remember to unsubscribe the event when lightbox is closed
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      // event CLOSED is fired
      // this.subscription.unsubscribe();
      this.isGalleryModalOpen = false;
    }

    if (event.id === LIGHTBOX_EVENT.OPEN) {
      this.isGalleryModalOpen = true;
      // event OPEN is fired
    }

    if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
      this.currentGalleryIndex = event.data;
      // event change page is fired
    }
  }
  // Take user to where they came from
  goBack = () => {
    if (this.newBar === 'true') {
      // if admin has just added a new bar
      this.route.navigate(['confirm-bar', this.barName]);
      return;
    }

    this.location.back();
  }

  /*
   * Go to the map
   */
  showSimpleMap() {
    const location = this.barLocation.coords;
    const bar = this.barName;
    this.route.navigate(['map', bar, {latitude: location.latitude, longitude: location.longitude}]);
  }

  /**
   * Set Drinks Prices
   */
  getDrinksPrices() {
    // @ts-ignore
    this.drinks.forEach(({drink, price}) => {
      switch (drink) {
        case 'Local Beer':
          this.beer.local = price;
          break;
        case 'Import Beer':
          this.beer.import = price;
          break;
        case 'Local Drink':
          this.drinkPrices.local = price;
          break;
        case 'Import Drink':
          this.drinkPrices.import = price;
          break;
        case 'Shot':
          this.shots.shot = price;
          break;
        case 'Soda':
          this.shots.soda = price;
          break;
        case 'Water':
          this.shots.water = price;
          break;
        default:
          break;
      }
    });

  }

/*
 * Set Ladies Drinks
 */
getLadies() {
  this.barfine.forEach(({lady, time, price, special}: any) => {
    if (special !== undefined) {
      this.barfineSpecial = special;
    } else if (lady === 'Normal') {
      if (time === 'Before midnight') {
        this.barfineNormal.bm = price;
      } else {
        this.barfineNormal.am = price;
      }
    } else if (lady === 'Model') {
      if (time === 'Before midnight') {
        this.barfineModel.bm = price;
      } else {
        this.barfineModel.am = price;
      }
    }
  });
  // Lady Drinks
  this.ladyDrinks.forEach(({drink, price}: any) => {
    this.lady[drink] = price;
  });
}

  // Evaluate the price for the bar
  setPrice() {
    this.price = setPriceWithColors(this.priceFactor);
  }

  getBarRank(positions, fromToplist) {
  return positions.find(position => position.field === fromToplist);
}

shouldShowRatings(ratings) {
  for (const rating of ratings) {
    if (rating.star) {
      this.showRatings = true;
    }
  }
}

  getBarInfo(): void {
    this.barService.getBarInfo(this.barName, this.adID).subscribe((barInfo: any) => {
      this.bar = barInfo.bar;
      this.hasShow = !!this.bar.barFlags.find((flag: any) => flag.id === 'show');
      const { user } = barInfo;
      if (userRankUp(user)) {
        this.route.navigate(['/rank']);
      }
      if (this.bar.positions.length) {
        this.icon = this.bar.positions[0].icon;
      }
      this.barLocation = barInfo.location;
      this.photos = barInfo.photos;
      this.event = barInfo.event;
      this.comments = barInfo.comment;
      if (this.comments) {
        this.barService.sendComments(this.comments);
      }
      this.drinks = this.bar.drinks;
      this.barfine = this.bar.barfine;
      this.ladyDrinks = this.bar.ladyDrinks;
      this.priceFactor = this.bar.priceFactor;
      this.getDrinksPrices();
      this.getLadies();
      this.setPrice();
      if (this.bar.positions) {
        if (this.rateIcon) {
          this.position = this.getBarRank(this.bar.positions, this.toplist);
        } else {
          this.position = this.bar.positions[0];
        }
      }
      if (!this.hasShow) {
        this.ratings = this.bar.ratings.filter((rating: {rating: string}) => rating.rating !== 'bestshow');
      } else {
        this.ratings = this.bar.ratings;
      }
      // if we have any ratings show ratings section
      if (this.ratings) {
        this.shouldShowRatings(this.ratings);
      }
      // Add photos to lightbox album
      if (this.album.length > 0) {
        this.album = [];
      }
      for (const photo of this.photos) {
        const source = this.serverURL + 'uploads/long/' + photo + '.jpg';
        this.album.push({ src: source, thumb: source });
      }

      this.locationService.getLocation().subscribe(position => {
          // distance from the user
          this.calculateDistance(position);
          // check whether user had allowed location earlier

        // disable notifications permissions requests
        // this.requestPermissions().then((res) => {
          // });
      });
    }, (error) => {
      // if the route does not include string 'bar' user tried to access a bar directly could from a direct link,
      // so take the home
      if (this.route.url.search('bar') === -1) {
        return this.route.navigate(['home']);
      }
      const modalRef = this.modalService.open(ModalPopupComponent);
      modalRef.componentInstance.my_modal_title = '<h4 class="text-center">Bar Not Found</h4>';
      modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <p class="text-center font-weight-bold mb-0">This bar does not exist:</p>
            <p>${this.barName}</p>
        </div>`;
      modalRef.componentInstance.my_modal_button1 = 'OK';
      modalRef.result.then(() => this.route.navigate(['home']), () => this.route.navigate(['home']) );
    });
  }

  calculateDistance(position) {
    let distance = getDistanceFromLatLonInKm(
      this.barLocation.coords.latitude, this.barLocation.coords.longitude,
      position.coords.latitude, position.coords.longitude
    );
    // if distance is more than 25Km from default point
    if (distance > 25) {
      distance = getDistanceFromLatLonInKm(
        this.barLocation.coords.latitude, this.barLocation.coords.longitude,
        12.931906, 100.879656,
      );
      // if distance is less than a Kilometre convert to metres
      if (distance < 1) {
        this.distanceFromBar = distance * 1000 + 'm';
      } else {
        this.distanceFromBar = distance + 'Km'; // return distance in Km
      }
    } else if (distance < 1) { // if distance is less than 1km and user is around pattaya
      this.distanceFromBar = distance * 1000 + 'm';
    } else {
      this.distanceFromBar = distance + 'Km';
    } // if distance is >= 1km and user is around pattaya
  }

  //  open Gallery modal
  openGalleryModal(index: number): void {
    this.currentGalleryIndex = index;
    this.lightbox.open(this.album, index, {
      centerVertically: true,
      wrapAround: true,
      fitImageInViewPort: true,
      disableScrolling: true
    });
  }

  /*
  * Open modal with an input to report offensive content
   */
  openReportForm() {
    this.lightbox.close();
    const id = this.photos[this.currentGalleryIndex];
    event.stopPropagation();
    event.preventDefault();
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.my_modal_title = `<h4 class="text-left">Report photo </h4>`;
    modalRef.componentInstance.form = true;
    modalRef.componentInstance.my_modal_button2 = 'Send Report';
    modalRef.result.then(() => this.sendReport(id), () => {});
  }

  /*
  * displayed when the report is successfully sent
  */
  openSuccessModal() {
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.my_modal_content = `
    <div class="text-center">
        <p>${this.reportResponse}</p>
    </div>
    `;
    modalRef.componentInstance.my_modal_button2 = 'Ok';
    modalRef.result.then(() => {}, () => {});
  }

  /*
   * persist the report
   */
  sendReport(id) {
    const data = {
      _id: id,
      bar: this.barName,
      deviceID: localStorage.getItem('deviceID'),
      reason: this.reason
    };
    this.barService.reportMedia(data, 'bar/reportPhoto').subscribe(res => {
      if (res) {
        const index = this.photos.indexOf(id);
        this.photos.splice(index, 1);
        this.reportResponse = 'Your Report has been sent to our Administrators';
        this.openSuccessModal();
      }
      // display success modal
    }, ({error}) => {
      this.reportResponse = error.errMsg;
      this.openSuccessModal();
    });
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  // change time to 12hours format
  convertToTwelveHours(time) {
    time = time.split(':'); // here the time is like "00:00" format
    return time[0] >= 12 && (time[0] - 12 || 12) + 'pm' || (Number(time[0]) || 12) + 'am';
  }

  goToRatingPage = () => this.route.navigate(['rate-bar', this.barName, {type: this.bar.type, show: this.hasShow}]);
  // show bar info details
  openRatingModal({itemName = ''}) {
    const description: any = this.barFlagTxt.find((barflag: any) => barflag.id === itemName);
    // tslint:disable-next-line: no-string-literal
    if (description.id === 'girls') {
      description.txt = `This bar has <span class="green-highlight">${this.bar.fromGirls}-${this.bar.toGirls}</span> GIRLS`;
    }
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <h2 class="text-center">${itemName.charAt(0).toUpperCase() + itemName.slice(1)}</h2>
            <img src="../../assets/images/icons/neonbartype/${itemName}.png" alt="Bar info image">
             <p class="text-center font-weight-bold mb-0">${this.barUtils.checkBarType(description.tip, this.barName, this.bar.area)}</p>
        </div>`;
    modalRef.componentInstance.my_modal_button1 = 'OK';
    modalRef.result.then(() => {}, () => {});
  }
  goToContactPage = (bar: string, reason: string) => {
    this.route.navigate(['contact', {bar, reason}]);
  }
}
