import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';
import getDistanceFromLatLonInKm from '../../utils/calculateDistance';
import { LocationService } from '../service/location.service';
import MapUtils from '../../utils/mapUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../service/global.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements AfterViewInit, OnInit {
// Default values
  @Input() bars: [];
  clickedBar = {
    priceColored: undefined,
    openHours: undefined, imgUrl: undefined, girls: undefined, distance: undefined,
    _id() {}
  };
  openBarDetails = false;
  serverURL: string = environment.server_url;
  // Constructor will get called very first when the page loads
  constructor(private appService: AppService, private router: Router, private locationService: LocationService,
              private modalService: NgbModal, private globalService: GlobalService) {
  }
  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;
  @ViewChild('barDetails', {static: false}) barDetails: ElementRef;
  // @ts-ignore
  map: google.maps.Map;
  mapUtils = new MapUtils(new LocationService(this.modalService, this.globalService, this.locationService));

  // initial center position for the map
  latitude = 12.931906;
  longitude = 100.879656;
// initializes default values
  coordinates = new google.maps.LatLng(this.latitude, this.longitude);
  // @ts-ignore set map options
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 16,
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  };
  // shows bar details
  showDetails = (bar, distance, imgUrl, priceColored) => {
    this.clickedBar = {...this.clickedBar, ...bar, distance, imgUrl, priceColored};
    this.openBarDetails = true;
  }
  closeDetails = () => this.openBarDetails = false;
// initializes the map on the DOM
  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.mapUtils.addBarMarkers(this.bars, this.serverURL, this.latitude, this.longitude, this.map, this.showDetails, () => {});
  }
  // this lifecycle hook is called after component is fully initialized.
  ngAfterViewInit() {
    this.mapInitializer();
  }
  // gets user current location
  getCurrentLocation() {
    this.locationService.getLocation().subscribe(position => {
      const distance = getDistanceFromLatLonInKm(position.lat, position.lng, this.latitude, this.longitude);
      if (distance > 25) {
        return;
      } else {
        this.latitude = position.lat;
        this.longitude = position.lng;
      }
    });
  }
// lifecycle hook that is called first
  ngOnInit() {
    this.getCurrentLocation();
    const data: any = this.globalService.GetGlobalConstants();
    this.mapUtils.populateMap(this.mapUtils.refactorCoords(data.areas), this.map);
  }
}


