import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  url = `${environment.server_url}bar/contact`;
  deviceID = localStorage.getItem('deviceID');
  constructor(private http: HttpClient) { }
  // call contact controller
  contact = (body) => {
    return this.http.post(this.url, {deviceID: this.deviceID, ...body}, httpOptions);
  }
}
