import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-bar',
  templateUrl: './modal.html',
  styleUrls: ['./admin-form.component.css'],
})
export class ModalSuccessComponent {
  @Input() name;
  barName: string;
  adminName: string;
  editMode: boolean;
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) {}
}
