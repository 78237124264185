import { Component, Input, OnInit } from '@angular/core';
import { Bar } from '../../../models/Bar';

@Component({
  selector: 'app-ratings-items',
  templateUrl: './ratings-items.component.html',
  styleUrls: ['./ratings-items.component.css']
})
export class RatingsItemsComponent implements OnInit {
  @Input() ratings: Array<object>;
  @Input() bar;
  ratingWithNumOfICons: any;
  ratingsPositions: Array<object>;
  ratingsIconsUrl = './../../assets/images/icons/rating';

  constructor() { }

  ngOnInit() {
    if (this.bar && this.bar.type === 'GAY') {
      this.ratings = this.ratings.filter(((rating: {rating: string}) => rating.rating !== 'hotgirls'));
    }
    this.setRatingItems(this.ratings);
  }

  checkMissingIcons(rating) {
    switch (rating) {
      case 'hotgirls':
        return 'fire';
      case 'bestvalue':
        return 'thumb';
      case 'funfactor':
        return 'yellow-smile';
      case 'service':
        return 'heart';
      case 'bestshow':
        return 'star';
      case 'cheapestprices':
        return 'baht';
      default: return rating;
    }
  }

  setRatingItems(ratings) {
    this.ratingWithNumOfICons = ratings.map((rating) => {
      const items = Array(Math.floor(rating.star)).fill(1);

      // calculate remaining icons of the total 5
      const remainingIcons = Array(Math.floor(5 - rating.star)).fill(1);
      const ratingStars = rating.ragingStars && rating.ragingStars.toFixed(2);
      return {
        rating: this.checkMissingIcons(rating.rating),
        rateIcon: rating.rating,
        star: rating.star,
        tip: rating.tip,
        ratingStars,
        numOfRatingIcons: items,
        numOfRemainingIcons: remainingIcons,
      };
    });

    // set ratings positions
    if (this.bar) {
      const positions = this.bar.positions.filter((barPositions) => {
        return this.bar.ratings.some((ratingsPositions: any) => {
          return barPositions.field === ratingsPositions.rating;
        });
      });
      this.ratingsPositions = positions;
    }
  }

}
