import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-bar',
  templateUrl: './modal.html',
  styleUrls: ['./bar-add.component.css'],
})
export class ModalContentComponent {
  @Input() name;
  barName: string;
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) {}
}
