import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LeftService } from '../../common/left/left.service';
import { User } from '../../models/user';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ordinal } from '../../utils/rankUtils';
import { RankService } from '../rank.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  subscription: Subscription;
  rankName: string;
  rank: number;
  total: number;
  ordinal = ordinal;

  constructor(private location: Location, private router: Router, private rankService: RankService) {
    this.aboutRank();
  }

  ngOnInit() {
  }

  aboutRank() {
    this.rankService.aboutRank().subscribe((res: { rank: string, position: number, of: number }) => {
      console.log(res)
      this.rankName = res.rank;
      this.rank = res.position;
      this.total = res.of;
      console.log(res)
    });
  }

  goTo(link) {
    this.router.navigate([link]);
  }

  goBack() {
    this.location.back();
  }

}
