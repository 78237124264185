import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  NavigationEnd
} from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from '../common/service/global.service';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private globalService: GlobalService, private _router: Router, private toastr: ToastrService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      this._router.events
        .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
        .subscribe(event => {
          if (
            event.id === 2 &&
            event.url === 'admin/login'
          ) {
            // at this point the window has been refreshed
            // redirect the user to dashboard
            this._router.navigate(['dashboard']);
          }
        })

        if (this.globalService.isLoggedIn) {
            this.globalService.loggedInFlag.next(true);
            return true;
        } else {
            this.globalService.loggedInFlag.next(false);
          //  this.toastr.error("Please login first!");
            this._router.navigate(['admin/login']);
            return false;
        }




    }

}
