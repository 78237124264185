import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { AdminDashboardService } from '../../admin-dashboard/admin-dashboard.service';
import { GlobalService } from '../../common/service/global.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  deviceID = localStorage.getItem('deviceID');
  userID = localStorage.getItem('userID');

  constructor(private router: Router, private dashboardService: AdminDashboardService, private globalService: GlobalService) {
    // clear localstorage
    // incase user got here by mistake
    if (!localStorage.getItem('userID')) {
      router.navigate(['admin/login']);
    }
    localStorage.removeItem('userID');
    this.logout();
    this.router.navigate(['home']);
  }

  logout() {
    localStorage.removeItem('userID');
    this.dashboardService.logoutAdmin(this.deviceID, this.userID).subscribe(res => {
      this.globalService.isLoggedIn = false;
      this.globalService.loggedInFlag.next(false);
    });
  }

  ngOnInit() {
  }

}
