import { Component, OnInit } from '@angular/core';
import { ListOrdersService } from './list-orders.service';
import { NavigationEnd, Router } from '@angular/router';
import parseString from '../../utils/parseString';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html',
  styleUrls: ['./list-orders.component.css']
})
export class ListOrdersComponent implements OnInit {
  orders: Array<object>;
  searchFailed = false;
  searchQuery: string;
  searching = false;
  itemsMarchingQuery: Array<object>;

  constructor(private ordersService: ListOrdersService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.ordersService.listOrders({search: ''}).subscribe((res: any) => {
      this.orders = res.order;
    })
  }

  /*
* When user press the enter key - search for bar
*/
  keyDown(event) {
    if (event.keyCode === 13) {
      this.searchBar()
    }
  }

  filerOrder(order) {
    return this.itemsMarchingQuery.find((res: any) => res.bar === order);
  }

  selectedItem($event) {
    console.log($event);
    const order = this.filerOrder($event.item);
    this.searchQuery = $event.item;
    // @ts-ignore
    this.router.navigate(['createOrder', {id: order._id}]);
  }

  goTo(link, params = {}) {
    this.router.navigate([link, {...params}])
  }

  goBack() {
    this.router.navigate(['dashboard']);
  }

  // search bar
  searchBar() {
    this.ordersService.listOrders({search: this.searchQuery}).subscribe((bars: any) => {
      console.log(bars)
      this.orders = bars.order;
    });
  }

  // search
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => {
        if (term) {
          return this.ordersService.listOrders({search: parseString(this.searchQuery)}).pipe(
            tap(() => this.searchFailed = false),
            catchError(() => {
              this.searchFailed = true;
              return of({ order: [] });
            }));
        } else {
          return  of({ order: [] });
        }
      }),
      map((res: any) => {
        console.log(res)
        this.itemsMarchingQuery = res.order;
        const items = [];
        res.order.forEach(item => {
          items.push(item.bar);
        });
        if (items.length === 0) {
          return [];
        }
        if (items.length > 10) {
          return items.slice(0, 10);
        } else { return items; }
      }),
      tap(() => this.searching = false)
    )

}
