import { Component, Input, OnInit } from '@angular/core';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() Header;
  @Input() iconPath;
  @Input() params?: string[];
  @Input() optionalParams?;
  @Input() extras;
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  @Input() customGoBack;

  constructor(private location: Location, private router: Router, private appService: AppService) { }

  ngOnInit() {
  }

  // back to user previous location
  goBack() {
    // if from rating form/bar form go home
    console.log(this.appService.getRouteHistory())
    if (this.appService.getRouteHistory()) {
      this.shouldGoHome(this.appService.getRouteHistory()).then(shouldGoHome => {
        if (shouldGoHome) {
          this.router.navigate(['home']);
        } else {
          this.location.back();
        }
      });
    } else {
      this.location.back();
    }
  }
  goHome() {
    this.router.navigate(['home']);
  }
  redirect = () => {
    if (this.params) {
      return this.router.navigate([...this.params, {...this.optionalParams}])
    }
  }

  /*
  Determine whether user should be redirected to home
   */
  shouldGoHome(urls) {
    return new Promise(resolve => {
      if ((urls.current.search('/bar/') !== -1) && (urls.previous.search('/addbar') !== -1)) {
        // if user used back button go home
        resolve(true);
      }
      if ((urls.current.search('/bar/') !== -1) && (urls.previous.search('/rate-bar/') !== -1)) {
        // if user used back button go home
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
}
