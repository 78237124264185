
//  transforms an array with object into
// a new array with object with value and title
// [{
// value: object's key
// title: objects's value
// }]
const transformTypes = (array, type?) => {
  const transformedTypes = [];
  array.reduce((curr, acc) => {
    transformedTypes.push({
      title: type === 'bar' ? acc.txt : Object.values(acc)[0],
      value: type === 'bar' ? acc.id : Object.keys(acc)[0]
    });
    return transformedTypes;

  }, []);
  return transformedTypes;
}
export default transformTypes;

export const getArea = (data) => {
  const selectData = [];
  data.map(area => {
    selectData.push({
      title: area._id,
      value: area._id
    });
  });
  return selectData;
}
export const getBarFlags = data => {
  const barFags = [];
  data.map(barFag => {
    barFags.push({
      title: barFag,
      value: false
    });
  });
  return barFags;
}
