import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { httpOptions } from '../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private http: HttpClient) { }
  userID = localStorage.getItem('userID');
  url = `${environment.server_url}admin/feedlist`;
  getFeedUrl = `${environment.server_url}admin/feedget`;
  fixFeedUrl = `${environment.server_url}admin/feedfix`;

  // calls feedback controller
  getFeedList = () => {
    return this.http.post(this.url, {userID: this.userID, fixed: false}, httpOptions);
  }
  // gets single feedback detail
  getSingleFeed = (id) => {
    return this.http.post(this.getFeedUrl, {userID: this.userID, _id: id}, httpOptions);
  }
  fixFeed = (id) => {
    return this.http.post(this.fixFeedUrl, {userID: this.userID, _id: id, fixed: true}, httpOptions);
  }
}
