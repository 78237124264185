import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private http: HttpClient) { }

      // Search GET Data URL
    url = environment.translate_server_url;
    languageURL = `${environment.translate_server_url}translation/getActiveLang?id=`;

    getActiveLang(language: string='gb') {

        // now returns an Observable of Config
        return this.http.get(this.languageURL + language);
    }
}
