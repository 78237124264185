import { Component, Input} from '@angular/core';
import AdminUtils from '../../utils/adminUtils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent {
  // props that  input takes
  @Input() inputWidth = 8;
  @Input() labelWidth = 4;
  @Input() showLabel = true;
  @Input() value;
  @Input() isValidPassword: boolean;
  @Input() checkEmail: boolean;
  @Input() labelName: string;
  @Input() type: string;
  @Input() id: string;
  @Input() name: string;
  @Input() style: any;
  @Input() placeholder = '';
  @Input() isValueNull: boolean;
  @Input() isRequired: boolean;
  @Input() isDisabled = false;
  @Input() min = 0;
  @Input() max: number;
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() validateLength: boolean;
  isEmail = true;
  adminUtils = new AdminUtils(this.modalService);
  constructor(private modalService: NgbModal) {}
  change(newValue) {
    this.isEmail = this.adminUtils.isEmail(newValue);
  }
}
