import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import transformDate from '../../utils/formatDate';
import { FeedDetail } from '../types';
import { Router } from '@angular/router';
import { FeedbackService } from '../feedback.service';
@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.css']
})
export class FeedbackDetailsComponent implements OnInit {
  // variables vi @Input are params passed this component
  @Input() feedDetail: FeedDetail;
  @Input() loading: boolean;
  @Output() cancel = new EventEmitter();
  @Output() fix = new EventEmitter();
  date: string;
  constructor(private router: Router, private feedbackService: FeedbackService) { }

  ngOnInit() {
    this.date = transformDate(this.feedDetail._id, true);
  }
// navigates the user to bar page
  goToBar = (barName: string) => {
    this.router.navigate(['bar', barName])
  }
  // navigates the user to advanced bar form
  goToAdvancedBar = (barName) => {
    this.router.navigate(['createAdvancedBar', {id: barName}]);
  }
  // emit event to parent component
  onCancel = () => this.cancel.emit({showDetail: false});
  // emit event to parent component
  onFixed = () => this.fix.emit({runFix: true, id: this.feedDetail._id})
}
