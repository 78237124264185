import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FrontPageService } from './front-page.service';
import { frontPageData } from './frontPageModel';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { LeftService } from '../common/left/left.service';
import { RightService } from '../common/right/right.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from '../common/modal-popup/modal-popup.component';
import checkDevice from '../utils/checkDevice';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { RankService } from '../rank-system/rank.service';
import BarFormUtils from '../utils/BarFormUtils';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../common/service/global.service';
import BarUtils from '../utils/BarFormUtils';
import FetchGlobalConstants from '../utils/global';
import {Rank} from '../models/Rank';
import {UUID} from "angular2-uuid";

@Component ({
  selector:     'app-home',
  templateUrl:  './home.component.html',
  styleUrls:    ['./home.component.css'],
  providers: [
    NgbActiveModal
  ]
})

export class HomeComponent implements OnInit {
    BarFormUtils = new BarFormUtils(this.modalService);
    @Input() password;
    @Input() nickName;
    deviceType = new checkDevice(this.deviceService);
    rightSideContent = [];
    imageButtons = [];
    appImgUrl = '../../assets/images/icons/app/';

    frontPageData: frontPageData[] = [];
    serverURL: string             = environment.server_url;
    IMAGE_URL: string = environment.IMAGE_URL;
    barDaySlot: any               = {};
    barDayImg             = ''; // bar day image path
    barCount: number;
    barName: string;
	appVersion: string = ''; 			// app version
  inAppAd: {
    bar: string;
    link: string;
    _id: string;
    parm: string;
    headline: string;
    text: string;
    photoShort: string;
  };
  isDesktop = true;
  closeResult = '';
  @ViewChild('content', {static: false}) content;
  @ViewChild('loginModal', {static: false}) loginModal;
  subscription: Subscription;
  frontPageSubscription: Subscription;
  user: {
    nickName: string,
    rankName: { title: string },
    rank: string,
  };
  front = [];
  menuLeft = [];
  comments = [];
  avatarURL = '../../assets/images/avatar';
  globalUtil = new FetchGlobalConstants(this.globalService, this.appService,
    this.leftSideService, this.rankService, this.translate, this.modalService
  );

    // Constructor will get called very first when the page loads
    constructor(
      private frontPageService: FrontPageService,
      private appService: AppService,
      private globalService: GlobalService,
      private router: Router,
      private leftSideService: LeftService,
      private modalService: NgbModal,
      private rightSideService: RightService,
      private rankService: RankService,
      private translate: TranslateService,
      private deviceService: DeviceDetectorService,
      ) {
      const globalData = this.globalService.GetGlobalConstants();
      if (globalData) {
        // @ts-ignore
        const {menuLeft, front, user} = globalData;
        this.user = user;
        this.leftSideService.sendLeftSide({content: menuLeft});
        this.updateFrontPage(globalData);
        this.getAds(front);
      }
      this.rightSideService.sendShowStatus({show: true});
      this.getRightSide();

  }

  logOut($event) {
    this.globalUtil.logOut($event);
    this.globalUtil.fetchGlobalConstants().then((globalData: any) => {
      const { menuLeft, user } = globalData;
      this.user = user;
      this.leftSideService.sendLeftSide({content: menuLeft});
    });
  }

  getAds(front) {
      this.frontPageService.getAds(front).subscribe((res: any) => {
        this.frontPageData = res.content;
        this.comments = res.comments;
    });
  }

  formatDate(date) {
      const dateTime = new Date(date * 1000);
      const monthDateYear = dateTime.toDateString().slice(4);
      return this.formatToDayMonthYear(monthDateYear);
  }

  formatToDayMonthYear(date) {
      const lastSpaceIndex = date.lastIndexOf(' ');
      const monthDate = date.slice(0, lastSpaceIndex).split(' ');
      const year =  date.slice(lastSpaceIndex, date.length);
      return monthDate[1] + ' ' + monthDate[0] + ' ' + year;
  }

    updateFrontPage(data) {
      const {front, menuLeft} = data;
      this.appVersion    = front.version;
      this.barCount      = front.barCount;
      this.inAppAd = front.inApp;
      this.menuLeft = menuLeft;

      if (this.inAppAd) {
        // show the modal
        this.showInAppAd();
      }

      // Filter data whose slot is "BARDAY"
      const barDaySlot = this.frontPageData.filter(item => item.slot === 'BARDAY');
      if (barDaySlot && barDaySlot.length) {
        this.barDaySlot = barDaySlot[0];
        // Torben you could do this by this way too, below both lines are same. with this way `string${javascript variable}string`
        this.barDayImg = this.serverURL + 'uploads/long/' + this.barDaySlot.img + '.jpg';
        // this.barDayImg = `${this.serverURL}uploads/long/${this.barDaySlot.img}.jpg`;
      }
    }

  getRightSide() {
    this.rightSideService.getRightSide().subscribe(({content}: any) => {
      this.rightSideService.storeContent(content);
      content.map(data => {
        if (data.type === 'HEADLINE') {
          this.rightSideContent.push(data);
        }
      })
      content.map(data => {
        if (data.type === 'IMAGEBUTTON') {
          this.rightSideContent.push(data);
          this.imageButtons.push(data);
        }
      })
    });
  }

    //
    // When the page starts or the component loads, its like old angularjs application have vm.init method
    //
    ngOnInit() {
        // Get Front Page data
      this.router.events.subscribe((res: any) => {
        if (res.url === '/facebook') {
          window.location.href = 'https://www.facebook.com/PattayaNightlifeGuide/';
        }
      });
    }

    // showAdModal(ad) {
    //   const modalRef = this.modalService.open(ModalPopupComponent);
    //   modalRef.componentInstance.my_modal_title = `<h4 class="text-center">${ad.bar}</h4>`;
    //   modalRef.componentInstance.my_modal_content = `
    //         <a class="standard-anchor" href="${ad.link || ad.parm}" target="${this.isDesktop ? '_blank' : '_self'}">
    //           <div class="text-center">
    //             <p class="text-center font-weight-bold mb-2">${ad.headline}</p>
    //               <img class="img-fluid ${ad.text && 'w-50'}" src="${this.IMAGE_URL + 'uploads/short/' + ad.photoShort}.jpg" alt="">
    //             <p class="mt-2">${ad.text}</p>
    //           </div>
    //         </a>`;
    //   modalRef.componentInstance.my_modal_button2 = 'Visit';
    //   modalRef.componentInstance.my_modal_button1 = 'Cancel';
    //   modalRef.result.then(() => this.goToAd(ad.link, ad.bar, ad._id), () => {});
    // }

  showInAppAd() {
    this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed`;
    });
  }

    goToAd(link= '', parm= '', adID= '') {
      this.appService.trackAdClick({_id: adID, deviceID: localStorage.getItem('deviceID')}).subscribe(res => {});
      this.modalService.dismissAll();
      if (link) {
        if (this.deviceType.isDesktop()) {
          window.open(link, '_blank');
        } else {
          window.location.href = link;
        }
      } else if (parm) {
        this.router.navigate(['bar', parm])
      }
    }

    goToBar(barName, adID?:string) {
      this.appService.setCurrentAdId(adID);
      // track click
      // update left side active state
      this.appService.updateSideLinksState(false);
      // go to bar
      this.router.navigate(['/bar', barName]);
    }

  iconClick() {
  }

  /*
* When user press the enter key
*/
  keyDown(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }

    // Navigate to a page with or without params
    goTo(link, params?) {
      // update left side active state
      this.appService.updateSideLinksState(false);
      // if we are going to a toplist
      if (link === 'list') {
        const parameters = Object.values(params);
        this.router.navigate([link, ...parameters]);
      } else { // this is just a normal link without params
        this.router.navigate([link]);
      }
    }

    dismissModal() {
      this.modalService.dismissAll('Cross click');
    }



  openLoginModal() {
    this.modalService.open(this.loginModal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  login() {
      const data = {
        nickName: this.nickName,
        password: this.password,
        language: localStorage.getItem('locale') || 'gb'
      };
      this.rankService.loginUser(data).subscribe(res => {
        console.log(res);
        this.globalUtil.fetchGlobalConstants().then((globalData: any) => {
          const { menuLeft, user } = globalData;
          this.user = user;
          this.leftSideService.sendLeftSide({content: menuLeft});
        });
        this.dismissModal();
      }, ({error}) => {
        console.log(error);
        this.BarFormUtils.showModal(error.errMsg, '');
      });
  }

}
