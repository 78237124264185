import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { httpOptions } from './utils/config/api';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    nearByBars = [];
    private subject =  new Subject<any>();
    sideLinksActive = true;
    scrollToComments = false;
    adID = '';
    searchParams: object;
    bars = [];
    routesHistory: object;

    // Search GET Data URL
    url = environment.server_url;
    dataGetURL = `${environment.server_url}data/get`;
    topSectionUrl = `${environment.server_url}front/getTop`;
    constructor(private http: HttpClient) { }

    //
    // Get global constants/data by http post call
    // URL: 'data/get'
    //
    GetGlobalConstants(deviceID: string, permissions = {}, rest?) {
        // now returns an Observable of Config
        const inputData = { deviceID, permissions, ...rest };
        return this.http.post(this.dataGetURL, inputData, httpOptions);
    }

    rankCheat(params = {}) {
      return this.http.post(this.url + 'user/rankCheat', {...params}, httpOptions);
    }

    storeBarData(bars) {
      this.bars = bars;
    }

    getBarsList() {
      return this.bars;
    }

    trackAdClick(data) {
      return this.http.post(this.url + 'order/clickAd', {...data}, httpOptions);
    }

    // set bars
    setBars = bars => this.nearByBars = bars;

    // send bars
    sendBars = bars => this.subject.next(bars);
    // get bars
    getNearByBars = () => this.nearByBars;

    // store search params
    storeSearchParams = (params) => this.searchParams = params;

    // return search params
    getSearchParams = () => this.searchParams;

    // get newly searched bars
    getNewlySearched(): Observable<any> {
      return this.subject.asObservable();
    }

    setCurrentAdId = (adID) => this.adID = adID;

    getCurrentAdId = () => this.adID;

    // update left side active links
    updateSideLinksState = (value) => this.sideLinksActive = value;

    updateRouteHistory = (routes) => this.routesHistory = routes;

    getRouteHistory = () => this.routesHistory;

    getLeftSideLinksState(): Observable<any> {
      return Observable.create(observer => {
            observer.next(this.sideLinksActive);
            observer.complete();
      });
    }
    shouldScrollToComments = () => this.scrollToComments;
    scrollToCommentsArea = () => this.scrollToComments =  true;
    shouldNotScroll = () => this.scrollToComments = false;
}
