import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { AdService } from '../ad.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  formatDate,
  positiveStatus,
  dateDiff,
  formatRuntime,
  formatEditableDate,
  slots,
  stdUnixDate,
  isFieldVisible, unitDetails, getDatePct
} from '../../utils/adsUtils';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../common/service/global.service';
import { ListOrdersService } from '../list-orders/list-orders.service';
import BarFormUtils from '../../utils/BarFormUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-ad',
  templateUrl: './edit-ad.component.html',
  styleUrls: ['../create-order/create-order.component.css', './edit-ad.component.css']
})
export class EditAdComponent implements OnInit {
  IMAGE_URL = environment.IMAGE_URL;
  ad: {
    type: string;
    slot: string;
    _id: string;
    clickCnt: number;
    viewCnt: number;
    viewPct: number;
    clickPct: number;
    photoShort: string;
    photoLong: string;
    link: string;
    text: string;
    headline: string;
    click: number;
    view: number;
    modifyDate: string;
    createDate: string;
    units: number;
    status: string;
    price: number;
  };
  creator: string;
  modifier: string;
  loadingComplete: boolean;
  editMode = false;
  currentOrderActionText: object;
  adID: string;
  barName: string;
  order: string;
  runtime: {
    years: string;
    months: string;
    days: string;
    hours: string;
  };
  timeRan: {
    years: string;
    months: string;
    days: string;
    hours: string;
  };
  selectedPhoto: File = null;
  selectedLongPhoto: File = null;
  selectedShortPhoto: File = null;
  viewPct: number;
  clickPct: number;
  datePct: number;
  highPct: any;
  price: string;
  dateTimePct: { leftPct: string; absRunPct: number; runPct: string };
  @Input() filename;
  @ViewChild('slot', {static: false}) slot;
  @ViewChild('typeRef', {static: false}) typeRef;
  @ViewChild('toDate', {static: false}) toDate;
  @ViewChild('fromDate', {static: false}) fromDate;
  @ViewChild('dateFromRef', {static: false}) dateFromRef;
  @ViewChild('dateToRef', {static: false}) dateToRef;

  @ViewChild('views', {static: false}) view;
  @ViewChild('editableView', {static: false}) editableView;
  @ViewChild('editableClicks', {static: false}) editableClicks;
  @ViewChild('headline', {static: false}) headline;
  @Input() text;
  @ViewChild('link', {static: false}) link;

  @ViewChild('units', {static: false}) units;
  @ViewChild('totalPrice', {static: false}) totalPrice;

  photoLong: string;
  photoShort: string;

  dateFrom: any;
  dateTo: any;
  adForm: Array<object>;
  status: string;
  unitDetails: {
    unitClick: number,
    unitView: number,
    unitPrice: number
  };
  viewCnt: number;
  clickCnt: number;

  formatDate = formatDate;
  formatEditableDate = formatEditableDate;
  isFieldVisible = isFieldVisible;
  slots: object;
  slotOptions;

  types = [
    {title: 'LIST', value: 'LIST'},
    {title: 'EVENT', value: 'EVENT'},
    {title: 'BANNER', value: 'BANNER'},
  ];
  hasPackage: string;

  BarFormUtils = new BarFormUtils(this.modalService);

  constructor(private adService: AdService, private router: ActivatedRoute, private globalService: GlobalService,
              private location: Location, private orderService: ListOrdersService, private modalService: NgbModal) {
    this.globalService.savePhoto.next(false);
    this.router.paramMap.subscribe((params: ParamMap) => {
      this.adID = params.get('id');
      this.barName = params.get('barName');
      this.order = params.get('order');
      this.price = params.get('price');
      this.hasPackage = params.get('hasPackage');
      this.adForm = this.orderService.getAdForm();
      if (!this.adForm) {
        return this.goBack();
      }
      if (this.adID) {
        this.editMode = true;
        this.getAdInfo();
      } else {
        this.loadingComplete = true;
      }
    });
    }

  ngOnInit() {
    setTimeout(() => {
      if (!this.adID && this.slot && this.typeRef) {
        this.unitDetails = unitDetails(this.adForm, this.slot.option.value, this.typeRef.option.value)
        this.slots = slots(this.adForm);
        this.slotOptions = this.getSlot(this.typeRef.option.value);
      }
    }, 200)
  }

  getAdInfo() {
    this.adService.geAd({_id: this.adID}).subscribe((res: any) => {
      this.loadingComplete = true;
      this.creator = res.create;
      this.modifier = res.modify;
      this.ad = res.ad;
      this.currentOrderActionText = positiveStatus(res.ad.status);
      this.slot = slots(this.adForm);
      setTimeout(() => {
        this.slots = slots(this.adForm);
        this.slotOptions = this.getSlot(res.ad.type);
        console.log(this.slotOptions)
        this.slot.option.value = res.ad.slot;
        this.typeRef.option.value = res.ad.type;
        this.photoLong = res.ad.photoLong;
        this.photoShort = res.ad.photoShort;
        this.viewPct = res.ad.viewPct;
        this.viewCnt = res.ad.viewCnt;
        this.clickCnt = res.ad.clickCnt;
        this.clickPct = res.ad.clickPct;
        this.datePct = res.ad.datePct;
        this.calculatePcts();
        this.fromDate.value = formatDate(res.ad.from);
        this.toDate.value = formatDate(res.ad.to);
        this.dateFrom = res.ad.from;
        this.dateTo = res.ad.to;
        this.text = res.ad.text;
        this.status = this.updateStatus(res.ad.status);
        this.unitDetails = unitDetails(this.adForm, this.slot.option.value, this.typeRef.option.value)
      }, 200);
      const dateFrom = new Date(res.ad.from * 1000);
      const dateTo = new Date(res.ad.to * 1000);
      const today = new Date(Date.now());
      // @ts-ignore
      this.dateTimePct = getDatePct(dateTo, dateFrom, today);
      const timeLeft = dateDiff(today, dateTo);
      const ranTime = dateDiff(dateFrom, today);
      this.timeRan = formatRuntime(ranTime);
      this.runtime = formatRuntime(timeLeft);
    })
  }

  calculatePcts() {
    this.viewPct  = Math.round( (this.viewCnt / this.ad.view) * 100 );
    this.clickPct  = Math.round( (this.clickCnt / this.ad.click) * 100 );
    this.highPct = Math.max(this.dateTimePct.absRunPct, this.viewPct, this.clickPct);
  }


  saveAd(shouldRedirect = true) {
    const data = {
      order : this.order,
      bar: this.barName,
      // price: Number(this.price),
      type: this.typeRef.option.value,
      slot: this.slot.option.value,
      from: this.dateFromRef && stdUnixDate(this.dateFromRef.value),
      to: this.dateToRef && stdUnixDate(this.dateToRef.value),
      view: this.editableView && this.editableView.value || this.ad && this.ad.view || null,
      viewCnt: 0,
      click: this.editableClicks && this.editableClicks.value || this.ad && this.ad.click || null,
      clickCnt: 0,
      headline: this.headline && this.headline.value || null,
      text: this.text,
      link: this.link && this.link.value,
      photoLong: this.photoLong,
      photoShort: this.photoShort,
      status: this.status,
      _id: this.adID || '',
      units: this.units && this.units.value || this.ad && this.ad.units || null,
      price: this.totalPrice && this.totalPrice.value || this.ad && this.ad.price || null,
    }
    // tslint:disable-next-line:no-unused-expression
    if (this.validateData(data)) {
      this.validatePhoto(data).then(() => {
        // upload the image
        if (this.sendPhoto()) {
          if (this.selectedLongPhoto) {
            data.photoLong = this.photoLong;
          }
          if (this.selectedShortPhoto) {
            data.photoShort = this.photoShort;
          }
        }
        this.adService.saveAd(data).subscribe((res) => {
          this.loadingComplete = true;
          if (shouldRedirect) {
            this.goBack();
          } else {
            this.loadingComplete = false;
            this.getAdInfo();
          }
        }, ({error}) => {
          const err = error.errMsg.substring(error.errMsg.indexOf(':') + 1);
          this.BarFormUtils.showModal(err, 'Error ad save');
        });
      }).catch(err => {
        this.BarFormUtils.showModal(err, 'Error ad save');
      });
    }
  }

  validatePhoto(data) {
    return new Promise((resolve, reject) => {
      // if the ad is for banner
      if (this.typeRef.option.value === 'BANNER') {
        if (data.photoLong || this.selectedLongPhoto) {
          data.photoLong = this.photoLong;
          resolve(true);
        } else {
          reject('please choose a photo');
        }
      } else if (this.typeRef.option.value === 'EVENT') { // if the ad is for event
        if (data.photoShort || this.selectedShortPhoto) {
          data.photoShort = this.photoLong;
          resolve(true);
        } else {
          reject('please choose a photo');
        }
      } else {
        resolve(true);
      }
    })
  }

  sendPhoto() {
    if (!this.adID || this.selectedLongPhoto || this.selectedShortPhoto) {
      this.globalService.savePhoto.next(true);
      if (this.selectedLongPhoto) {
        this.photoLong = this.selectedLongPhoto ? this.selectedLongPhoto.constructor.prototype.nameInDB : null;
        return true;
      }
      if (this.selectedShortPhoto) {
        this.photoShort = this.selectedShortPhoto ? this.selectedShortPhoto.constructor.prototype.nameInDB : null;
        return true;
      }
    }
  }

  isFieldCurrentlyVisible(data) {
    if (data && this.slot && this.slot.option) {
      return  isFieldVisible({
        adForm: this.adForm,
        ...data,
        slot: this.slot && this.slot.option && this.slot.option.value || this.ad.slot,
        type: this.slot && this.slot.option && this.typeRef.option.value || this.ad.type
      });
    }
  }

  validateData(data) {
    if (this.units && this.units.value) { // check for negative values
      if (this.units.value < 1) {
        this.loadingComplete = true;
        this.BarFormUtils.showModal('please input valid units', 'Error ad save');
        return false;
      } else {
        return true;
      }
    }
    if (this.typeRef.option.value === 'LIST') {
      if (!data.units && !this.editMode || !data.units && this.editMode && !data.units && !data.view && !data.click) {
        this.loadingComplete = true;
        this.BarFormUtils.showModal('please input units', 'Error ad save');
        return false;
      } else {
        return true;
      }
    }
    if (this.typeRef.option.value === 'BANNER') {
      if (!data.units && !this.editMode
        || !data.units && this.editMode && !data.view && !data.click
        || this.editableClicks.value && this.editableClicks && !this.units.value && this.editMode) {
        this.loadingComplete = true;
        this.BarFormUtils.showModal('please input units', 'Error ad save');
        return false;
      } else {
        return true;
      }
    }

    if (this.typeRef.option.value === 'EVENT') {
      if (!data.units && !this.editMode || (this.editMode && !data.units && !data.view && !data.click)) {
        console.log('here')
        this.loadingComplete = true;
        this.BarFormUtils.showModal('please input units', 'Error ad save');
        return false;
      }
      if (!data.headline) {
        this.BarFormUtils.showModal('please input headline', 'Error ad save');
        this.loadingComplete = true;
        return false;
      }
      if (!data.text) {
        this.BarFormUtils.showModal('please input text', 'Error ad save');
        this.loadingComplete = true;
        return false;
      } else {
        return true;
      }
    }
  }

  onTypeSlotChange() {
    const type = this.typeRef.option.value;
    const slot = this.slot.option.value;
    this.slotOptions = this.getSlot(this.typeRef.option.value);
    this.unitDetails = unitDetails(this.adForm, slot, type)
    if ((this.unitDetails === undefined)) { // if the type changed, get the new unit details
      this.slotOptions = this.getSlot(type);
      this.slot.option.value = this.slotOptions[0].value;
      this.unitDetails = unitDetails(this.adForm, this.slot.option.value, type)
    }
  }


  updateStatus(status) {
    if (status) {
      if (status === 'WAITING') {
        return 'WAIT';
      }
      if (status === 'PAUSED') {
        return 'PAUSE';
      }
      if (status === 'RUNNING') {
        return 'RUN';
      }
      if (status === 'ABORTED') {
        return 'ABORT';
      }
      if (status === 'ENDED') {
        return 'ENDED';
      } else {
        return status;
      }
    }
  }

  displayStatus(status) {
    if (status) {
      if (status === 'WAIT') {
        return 'WAITING';
      }
      if (status === 'PAUSE') {
        return 'PAUSED';
      }
      if (status === 'RUN') {
        return 'RUNNING';
      }
      if (status === 'END') {
        return 'ENDED';
      }
      if (status === 'ABORT') {
        return 'ABORTED';
      } else {
        return status;
      }
    }
  }

  displayActionStatus(status) {
    if (status) {
      if (status === 'WAIT') {
        return 'RUN';
      }
      if (status === 'PAUSE') {
        return 'RUN';
      }
      if (status === 'RUN') {
        return 'PAUSE';
      }
      if (status === 'ABORT') {
        return 'WAIT';
      }
    }
  }

  displayNegativeAction(status) {
    if (status) {
      if (status === 'WAIT') {
        return 'ABORT';
      }
      if (status === 'PAUSE') {
        return 'ABORT';
      }
      if (status === 'RUN') {
        return 'ABORT';
      }
      if (status === 'ENDED') {
        return 'ABORT';
      }
      if (status === 'ABORT') {
        return 'END';
      }
    }
  }

  // determine save button visibility
  isVisible() {
    if (this.ad && this.editMode) {
      return (this.ad.status === 'WAITING' || this.ad.status === 'PAID' || this.ad.status === 'PAUSED');
    } else {
      return true;
    }
  }

  getSlot(type) {
    if (this.slots[type]) {
      return this.slots[type]
    }
  }

  today() {
    return new Date().toISOString().substr(0, 10)
  }

  nextMonth() {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    return date.toISOString().substr(0, 10);
  }

  // Determines whether Field is editable depending on status
  // [editable if WAITING or PAUSED]
  isFieldDisabled() {
    if (this.ad && this.status) {
      if (this.status === 'PAUSE') {
        return true
      }
      return this.status === 'WAIT';
    }
  }

  //
  // Check if user has selected the photo from file explore or drag and drop
  //
  isPhotoSelected(photo) {
    this.selectedPhoto = photo;
  }

  // get photo
  getPhoto($event) {
    this.filename = $event;
  }

  getLongPhoto($event) {
    this.selectedLongPhoto = $event;
  }

  getShortPhoto($event) {
    this.selectedShortPhoto = $event;
  }

  close() {
    console.log('close ad');
  }

  deleteAd() {
    this.loadingComplete = false;
    this.adService.deleteAd({_id: this.adID}).subscribe(res => {
      this.goBack();
    })
  }

  copyAd() {
    this.adService.copyAd({_id: this.adID}).subscribe(res => {
      this.goBack();
    })
  }

  modifyStatus(status) {
    // if running we need to pause
    this.loadingComplete = false;
    if (status === 'RUN') {
      this.status = 'PAUSE';
    }
    if (status === 'PAUSE') {
      this.status = 'RUN';
    }
    if (status === 'WAIT') {
      this.status = 'RUN';
    }
    if (status === 'ENDED') {
      this.status = 'ENDED';
    }
    if (status === 'ABORT') {
      this.status = 'WAIT';
    }
    this.saveAd(false);
  }

  negativeStatusAction(status) {
    console.log(status)
    this.loadingComplete = false;
    if (status === 'ENDED') {
      this.status = 'END';
    }
    if (status === 'ABORT') {
      this.status = 'END';
    } else {
      this.status = 'ABORT'
    }
    this.saveAd(false);
  }

  onDateChange() {
    const fromDate = stdUnixDate(this.dateFromRef.value);
    const to = stdUnixDate(this.dateToRef.value);
    const runningTime = dateDiff(new Date(fromDate * 1000), new Date(to * 1000));
    this.runtime = formatRuntime(runningTime);
    console.log(this.runtime)
  }

  cancel() {
    this.loadingComplete = false;
    this.status = 'ABORT';
    this.saveAd();
  }

  goBack() {
    this.location.back();
  }

}
