import { GlobalService } from '../common/service/global.service';
import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { LeftService } from '../common/left/left.service';
import { RankService } from '../rank-system/rank.service';
import { TranslateService } from '@ngx-translate/core';
import BarUtils from './BarFormUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';

export default class FetchGlobalConstants {
  user: object;
  barUtils = new BarUtils(this.modalService);
  constructor(private globalService: GlobalService, private appService: AppService,
              private leftSideService: LeftService, private rankService: RankService,
              private translate: TranslateService, private modalService: NgbModal ) {
  }

  //
  // Get initial global constants
  //
  fetchGlobalConstants() {
    return new Promise((resolve, reject) => {
      this.barUtils.permissionsStatus().then(permissions => {
        const deviceID = localStorage.getItem('deviceID');
        // @ts-ignore
        const language = localStorage.getItem('locale')  || navigator.language || navigator.userLanguage;
        this.appService.GetGlobalConstants(deviceID, permissions, {language})
          .subscribe((result: any) => {

              if (result && Object.keys(result).length) {
                // Store global constants
                this.globalService.setGlobalConstants(result);
                const {bars, menuLeft, user} = result;
                this.operateOnUserDetails(user);
                this.leftSideService.storeUser(this.user);
                this.leftSideService.sendLeftSide({content: menuLeft});
                this.appService.storeBarData(bars);
                resolve(result);
              }
            }, (err) => {
              reject(err);
              console.log(`error occured for ${environment.server_url}data/get`, err);
            }
          );
      });
    });
  }

  logOut($event) {
    $event.stopPropagation();
    $event.preventDefault();
    localStorage.removeItem('deviceID');
    localStorage.setItem('deviceID', UUID.UUID());
  }

  /*
Get user details
 */
  operateOnUserDetails(user) {
      this.leftSideService.storeUser(user);
      this.user = user;
      if (user.language) {
        // if user has not set language
        if (!localStorage.getItem('locale')) {
          localStorage.setItem('locale', user.language);
          this.translate.setDefaultLang(user.language);
        }
      }
  }

}


/*
 make number two digits
 1 => 01
 10 => 10
 */
export const pad = (digit) =>  {
  return (digit < 10) ? '0' + digit.toString() : digit.toString();
};
