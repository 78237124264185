import { Component, OnInit, ViewChild } from '@angular/core';
import Validator from '../utils/adminUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactService } from './contact.service';
import { ModalPopupComponent } from '../common/modal-popup/modal-popup.component';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import {Modal, Body} from './type'

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  // default params for contact component
  reason = '';
  text = '';
  bar = '';
  @ViewChild('email', {static: false}) email;
  loading = false;
  validate = new Validator(this.modalService);
  // injects modal, router and contact services
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private contactService: ContactService,
    private activeRoute: ActivatedRoute
  ) {}
// updates the text with the bar name
  ngOnInit() {
    // @ts-ignore
    const { bar, reason } = this.activeRoute.params._value;
    if (bar && reason) {
      this.bar = bar;
      this.text = `This was reported from ${bar} \n`;
      this.reason = reason;
    }
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  validateForm = () => {
    let body: Body = {bar: this.bar, reason: this.reason, text: this.text};
    if (this.email.value) {
      if (this.validate.isEmail(this.email.value)) {
        body = {...body, email: this.email.value};
      } else {
        this.loading = false;
        return this.modalPopup({body: 'Use a valid email', title: 'Validation Error' });
      }
    }
    if (!this.reason) {
      this.loading = false;
      return this.modalPopup({
        body: 'Select reason from the drop down', title: 'Validation Error'
      })
    }
    if (!this.text) {
      this.loading = false;
      return this.modalPopup({
        body: 'Tell us why you are reporting this bar', title: 'Validation Error'
      })
    }
    return body;
  }
  // calls contact controller in order to submit request
  onSubmit = () => {
    this.loading = true;
    const body = this.validateForm();
    if (this.loading) {
      this.contactService.contact(body).subscribe(() => {
        this.loading = false;
        this.modalPopup({
          body: 'Our administrators will look at your report',
          title: 'The information have been sent',
          navigate: true ,
          pathname: 'home'
        });
      },
        () => {
          this.loading = false;
          this.modalPopup({
            body: 'Please try again',
            title: 'Something went wrong',
          });
        });
    }
  }
  // pop up success model on successful request or Errors
  modalPopup({
               body,
               title,
               navigate = false,
               pathname = '',
               extras}
               : Modal) {
    const modalRef = this.modalService.open(ModalPopupComponent, {centered: true});
    modalRef.componentInstance.my_modal_title = `<h5>${title}</h5>`;
    modalRef.componentInstance.my_modal_content = `<div class="text-center">
            <p class="text-center mb-0">${body}</p>
        </div>`;
    modalRef.componentInstance.my_modal_button1 = 'OK';
    if (navigate) {
      modalRef.result.then(() => console.log('When user closes'),
        () => this.router.navigate([pathname], {...extras}));
    }
  }
}
