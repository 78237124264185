import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import setPriceWithColors from '../utils/setBarPrice';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import { Subscription } from 'rxjs';
import checkDevice from '../utils/checkDevice';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocationService } from '../common/service/location.service';
import getDistanceFromLatLonInKm from '../utils/calculateDistance';
import { BarsNearMeService } from '../bar-near-me/bars-near-me.service';
import changeAllWordsCase, { withHyphen } from '../utils/changeAllWordsCase';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit, OnDestroy  {
  iconPath = '../../../assets/images/bar.png';
  IMAGE_URL = environment.IMAGE_URL;
  showMap = false;
  bars = [];
  deviceType = new checkDevice(this.deviceService);
  userLocation: {
    latitude: number,
    longitude: number
  };
  distance: number;
  time: number;
  setPrice = setPriceWithColors;
  withHyphen = withHyphen;
  subscription: Subscription;
  constructor(private router: Router, private location: Location, private appService: AppService,
              private deviceService: DeviceDetectorService, private locationService: LocationService,
              private barService: BarsNearMeService) {
    // search again
    const searchParams = this.appService.getSearchParams();
    if (searchParams) {
      // @ts-ignore
      searchParams.map = false;
      // @ts-ignore
      this.barService.getBar(searchParams).subscribe(bars => {
        this.appService.sendBars(bars);
      })
    }
    this.subscription = this.appService.getNewlySearched().subscribe(res => {
      this.bars = res;
    });
  }

  ngOnInit() {
    this.bars = this.appService.getNearByBars();
    this.locationService.getPosition().then(res => {
      this.userLocation = res;
    })
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  goToAd($event, link= '', parm= '', adID = '') {
    $event.preventDefault();
    $event.stopPropagation();
    this.appService.trackAdClick({_id: adID, deviceID: localStorage.getItem('deviceID')}).subscribe(res => {});
    if (link) {
      if (this.deviceType.isDesktop()) {
        window.open(link, '_blank');
      } else {
        window.location.href = link;
      }
    } else if (parm) {
      this.router.navigate(['bar', parm])
    }
  }

  goToBar(barName, adID = '') {
    this.appService.setCurrentAdId(adID);
    this.router.navigate(['/', this.withHyphen(barName)]);
  }

  showOnMap() {
    this.showMap = !this.showMap;
  }
  goBack() {
    this.location.back();
  }

  setDistance(coords) {
    const distance = this.getDistance(coords);
    if (distance > 1) {
      return distance + 'Km';
    }
    if (distance < 1) {
      return distance * 1000 + 'm';
    }
  }

  getDistance(coords) {
    if (this.userLocation) {
      this.distance =  getDistanceFromLatLonInKm(
        this.userLocation.latitude, this.userLocation.longitude,
        coords.latitude, coords.longitude
      );
      this.time = Math.floor(this.distance * 1000 / 83);
      return this.distance;
    }
  }

}
