import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';
import getDistanceFromLatLonInKm from '../../utils/calculateDistance';
import BarUtils from '../../utils/barUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Pattaya from '../../common/data/pattayaArea';
import { google } from "google-maps";

declare var google : google;

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  distanceFromDefaultPoint: string;
  barUtils = new BarUtils(this.modalService, this.globalService, this.locationService);

  constructor(private modalService: NgbModal, private globalService: GlobalService, private locationService: LocationService) {}

  //
  // Observable of user's current location
  //
  getLocation(): Observable<any> {
    return Observable.create(observer => {
      this.barUtils.checkPermissionAccess('geolocation').then(res => {
        if(window.navigator && window.navigator.geolocation) {
          /*
          Uncomment the code to enable geolocation permissions request
           */
          // window.navigator.geolocation.getCurrentPosition(
          //   (position) => {
          //     this.handleDistance(position).then((response: any) => {
          //       observer.next(response);
          //       observer.complete();
          //     })
          //   },
          //   (error) => {
              // If user is not allowing then store pattaya default location.
              let data: Object = {};
              data['coords'] = {longitude: 12.931906, latitude: 100.879656}
              this.handleDistance(data).then(() => {
                observer.next(data);
                observer.complete();
              });
          //   }, {enableHighAccuracy: true, timeout: 5 * 1000, // 5 seconds
          //     maximumAge: 0}
          // );
        } else {
          observer.error('Unsupported Browser');
        }
      });
    });
}


handleDistance(position) {
  return new Promise(resolve => {
    this.globalService.setUserCurrentLocation(position);
    const distance = getDistanceFromLatLonInKm(12.931906, 100.879656, position.coords.latitude, position.coords.longitude);
    this.globalService.setUserDistance(distance);
    this.isInPattaya({ lat: position.coords.latitude, lng: position.coords.longitude }).then(res => {
      // if user not within the onclosed area and teleportation modal not shown
      if (res === false && !this.globalService.isTeleportedAlertShown) {
        /*
        uncomment below to show teleportation modal
         */
        // this.barUtils.showTeleportedModal();
        this.globalService.isTeleportedAlertShown = true; // set this flag as true as we don't want to show each time teleported alert
        this.globalService.setUserCurrentLocation({coords: { latitude: 12.931906, longitude: 100.879656 }});
        resolve({coords: { latitude: 12.931906, longitude: 100.879656 }})
      } else if (res === false && this.globalService.isTeleportedAlertShown) {
        // if user is not around enclosed area and teleportation modal show
        this.globalService.setUserCurrentLocation({coords: { latitude: 12.931906, longitude: 100.879656 }});
        resolve({coords: { latitude: 12.931906, longitude: 100.879656 }})
      } else if (res) {
        this.globalService.setUserCurrentLocation(position);
        resolve(position);
      }
    });
  })

}


/*
 * Get a one time position
 */
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          // distance from the user
          const distance = getDistanceFromLatLonInKm(
            resp.coords.latitude, resp.coords.longitude,
            12.931906, 100.879656,
          );
          // if distance is more than 25Km from default point
          if (distance > 25) {
            resolve({ latitude: 12.931906, longitude: 100.879656 });
          } else {
            resolve({ longitude: resp.coords.longitude, latitude: resp.coords.latitude });
          }
        },
        err => {
          // for location denial teleport user to default location
          resolve({ lng: 12.931906, lat: 100.879656 });
        });
    });
  }

  /*
   * return Boolean
   * check whether location is around Pattaya
   */
  isInPattaya(position) {
    return new Promise(resolve => {
      const currentPolygon = new google.maps.Polygon({
        paths: Pattaya,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      });
      const coordinate = new google.maps.LatLng(position.lat, position.lng);
      setTimeout(() => {
        const isInsidePattaya = google.maps.geometry.poly.containsLocation(coordinate, currentPolygon);
        resolve(isInsidePattaya);
      }, 1000);
    })
  }
}
