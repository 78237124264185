import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { frontPageData } from './frontPageModel';
import { httpOptions } from '../utils/config/api';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FrontPageService {
  private frontPage = new Subject();

    // main url
    url = `${environment.server_url}`;
    constructor(private http: HttpClient) {}

    getAds(params) {
      const deviceID = localStorage.getItem('deviceID');
      const inputData = {
        front: params,
        deviceID,
      };
      return this.http.post<frontPageData[]>(this.url + 'front/ads', inputData, httpOptions);
    }

    storeFrontPage(front){
      this.frontPage.next(front);
    }

}
