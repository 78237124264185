export default [
  'best-show',
  'best-live-music',
  'best-value-for-money',
  'cheapest-alcohol-prices',
  'cheapest-barfine-prices',
  'cheapest-lady-drink-prices',
  'cheapest-bar-prices',
  'cheapest-beer-prices',
  'cheapest-shot-prices',
  'most-comments',
  'bar-fun-factor',
  'hottest-girls',
  'most-girls',
  'most-reviews',
  'best-service',
  'cheapest-drink-prices',
  'most-visits',
  'highest-rated',
  'best-ladyboys',
  'best-freelancers',
  'best-topless-bars',
  'best-rooftop-bars',
  'best-russian-girl-bars',
  'best-short-time-bars',
];
