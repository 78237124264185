import { Component, OnInit } from '@angular/core';
import { RightService } from './right.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import checkDevice from '../../utils/checkDevice';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-right',
  templateUrl: './right.component.html',
  styleUrls: ['./right.component.css']
})
export class RightComponent implements OnInit {
  content: any;
  appImgUrl = '../../assets/images/icons/app/';
  IMAGE_URL = environment.IMAGE_URL;
  subscription = Subscription;
  showRightSide = false;
  deviceType = new checkDevice(this.deviceService);

  constructor(private rightSideService: RightService, private router: Router,
              private deviceService: DeviceDetectorService, private appService: AppService) {
  }

  ngOnInit() {
    // @ts-ignore
    this.subscription = this.rightSideService.getContent().subscribe(({content}) => {
      this.content = content;
      console.log(content)
    })
  }

  goToBar(parm: '', link: '', adID = '') {
    this.appService.trackAdClick({_id: adID, deviceID: localStorage.getItem('deviceID')}).subscribe(res => {});
    if (link) {
      if (this.deviceType.isDesktop()) {
        window.open(link, '_blank');
      } else {
        window.location.href = link;
      }
    } else if (parm) {
      this.router.navigate(['bar', parm])
    }
  }

}
