import { Component, OnInit } from '@angular/core';
import { RankService } from '../rank.service';
import { Subscription } from 'rxjs';
import { LeftService } from '../../common/left/left.service';
import { User } from '../../models/user';
import { ordinal } from '../../utils/rankUtils';
import {GlobalService} from '../../common/service/global.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  subscription: Subscription;
  user: User;
  rankList: {
    rank: Array<object>,
    ranked: number,
    totaluser: 416
  };
  ordinal = ordinal;

  constructor(private rankService: RankService, private leftSideService: LeftService,
              private globalService: GlobalService) {
    const {menuLeft} = this.globalService.GetGlobalConstants();
    this.leftSideService.sendLeftSide({content: menuLeft});
    this.getUserData();
    this.rankService.rankList({deviceID: localStorage.getItem('deviceID')}).subscribe((res: any) => {
      this.rankList = res;
    });
  }

  ngOnInit() {
  }

  getUserData() {
    this.rankService.getUserData().subscribe((data: any) => {
      this.user = data.user;
      console.log(this.user);
    });
  }

}
