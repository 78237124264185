
const beer = '../../assets/images/marker/beer.png';
const disco = '../../assets/images/marker/disco.png';
const live = '../../assets/images/marker/live.png';
const gay = '../../assets/images/marker/gay.png';
const gogo = '../../assets/images/marker/gogo.png';
const soapy = '../../assets/images/marker/soapy.png';
const star = '../../assets/images/marker/star.png';
// returns bars icon specific to a bar type
const barIcon = barType => {
  switch (barType) {
    case 'BEER':
      return beer;
    case 'Beer Bars':
      return beer;
    case 'AGOGO':
      return gogo;
    case 'agogo':
      return gogo;
    case 'SOAPY MASSAGE':
      return soapy;
    case 'GOGO':
      return gogo;
    case 'SOAPY':
      return soapy;
    case 'DISCO or CLUB':
      return disco;
    case 'DISCO':
      return disco;
    case 'disco':
      return disco;
    case 'LIVE':
      return live;
    case 'GAY':
      return gay;
    case 'STAR':
      return star;
    default:
      return '';
  }
}
export default barIcon;
