import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { httpOptions } from '../../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class RightService {
  rightSideUrl = `${environment.server_url}front/getRight`;
  private subject = new Subject<any>();
  private content = new Subject<any>();

  constructor(private http: HttpClient) { }

  // get right side
  getRightSide() {
    const body = {
      deviceID : localStorage.getItem('deviceID'),
      censored: false
    };
    return this.http.post(this.rightSideUrl, body, httpOptions);
  }

  getContent(): Observable<any> {
    return this.content.asObservable();
  }

  storeContent(content) {
    this.content.next({ content });
  }

  shouldShowRightSide(): Observable<any> {
    return this.subject.asObservable();
  }

  sendShowStatus({show = false}) {
    this.subject.next({ showRightSide: show });
  }
}
