import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { httpOptions } from '../utils/config/api';

@Injectable({
    providedIn: 'root'
})
export class BarsNearMeService {
    // initialization of default variables
    url = `${environment.server_url}search/bar`;
    barAreaUrl = `${environment.server_url}search/get`;
    deviceId = localStorage.getItem('deviceID');
    nearByBars = [];
    // Constructor will get called very first when the page loads
    constructor(private http: HttpClient) {}

    // fetches bars that the user searches
    getBar({ area = 'ALL', distance = 800, mylocation, limit = 500, type = 'ALL', map = false }) {
        const body = { deviceID: this.deviceId, area, distance, mylocation, limit, type, map };
        return this.http.post(this.url, body, httpOptions);
    }
    // updates bars after fetching them
    setBars = bars => this.nearByBars = bars;
    // fetches all bar areas
    fetchBarArea = () => {
        return this.http.post(this.barAreaUrl, { deviceID: this.deviceId }, httpOptions);
    }
}
