import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { httpOptions } from '../../utils/config/api';

@Injectable({
  providedIn: 'root'
})
export class ListOrdersService {

  url = `${environment.server_url}`;
  userID = localStorage.getItem('userID');
  adForm: Array<object>;
  orderInfo: {
    order: object,
    ads: Array<object>
  };

  constructor(private http: HttpClient) { }

  listOrders(data) {
    return this.http.post(this.url + 'order/list', { ...data, userID: this.userID }, httpOptions);
  }
  getOrder(data) {
    return this.http.post(this.url + 'order/get', {...data, userID: this.userID}, httpOptions)
  }
  getPackages(data = {}) {
    return this.http.post(this.url + 'order/package', {...data, userID: this.userID}, httpOptions)
  }
  persistOrder(data) {
    return this.http.post(this.url + 'order/save', {...data, userID: this.userID}, httpOptions)
  }
  storeAdForm(form) {
    this.adForm = form;
  }
  getAdForm() {
    return this.adForm;
  }
  deleteOrder(params = {}) {
    return this.http.post(this.url + 'order/delete', {...params, userID: this.userID}, httpOptions)
  }
  storeOrderStatus(data = {order: {}, ads: []}) {
    this.orderInfo = data;
  }
  getOrderInfo() {
    return this.orderInfo;
  }
}
