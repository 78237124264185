const transformDate = (unixTimestamp, fullDate = false) => {
  const dateObj = new Date(unixTimestamp * 1000);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const fullMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
    'November', 'December'];
  const month = months[dateObj.getMonth()];
  const fullMonth = fullMonths[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const time = dateObj.getHours() + ':' + dateObj.getMinutes();
  if (fullDate) {
    return `${day} ${fullMonth} ${year} - ${time}`;
  }
  return  day + ' ' + month;
};
export default transformDate
